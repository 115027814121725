import React, {useEffect, useState} from 'react';
import {ts} from "../../../../translations/helpers";
import {Button, Divider, Form} from "antd";
import {ApiProduct} from "../../../../api/endpoints/product";
import {Select} from "../../../components-base/Select/Select";
import {Input} from "../../../components-base/Input/Input";
import {INPUT_TYPES} from "../../../../interfaces/form";
import {IProductDiscount, IProductTax} from "../../../../interfaces/product";
import {ProductAddedItem} from "../ProductAddedItem";

interface IProps {
    product_id: string;
    addedDiscount: IProductDiscount;
    handlerUpdate: () => void;
}

export const ProductDiscount: React.FC<IProps> = ({ product_id, addedDiscount, handlerUpdate }) => {
    const [discount, setDiscount] = useState<number | null>(null);
    const [validateErrors, setValidateErrors] = useState<Record<string, any>>({} as Record<string, any>);

    const validateForm = () => {
        const errors: Record<string, any> = {};

        if (!discount) errors['discount'] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = () => {
        if (validateForm()) {
            ApiProduct.createDiscount(discount, product_id).then(() => {
                handlerUpdate();
                setDiscount(null);
            });
        }
    };

    const onDelete = () => {
        ApiProduct.deleteDiscount(product_id).then(() => {
            handlerUpdate();
        });
    };

    return (
        <>
            <Divider children={ts('Додати знижку на товар')} orientation="left" orientationMargin="0"/>

                {addedDiscount?.percent
                    ? <ProductAddedItem name={ts('Знижка %')}
                                        value={`
                                            ${String((addedDiscount?.percent * 100).toFixed(0))}% | ${addedDiscount?.price_discount}€
                                        `}
                                        handlerDelete={onDelete}
                      />
                    :  <Form layout={'vertical'} onFinish={onSubmit}>
                           <Input value={discount}
                                     placeholder={ts('Введіть нову ціну товару')}
                                     label={ts('Нова ціна')}
                                     handlerChange={setDiscount}
                                     type={INPUT_TYPES.NUMBER}
                                     errors={validateErrors?.['discount']}
                           />
                           <Button type={'primary'} htmlType={'submit'}>{ts('Зберегти')}</Button>
                       </Form>
                }

        </>
    );
}