import React, {ReactNode, useEffect, useState} from 'react';
import {Loader} from "../../../../components-base/Loader/Loader";
import {DirectoriesController} from "../../DirectoriesController";

interface IDirectoriesProps {
    children?: ReactNode;
    country?: boolean;
    tax?: boolean;
    manufacturer?: boolean;
}
export const Directories: React.FC<IDirectoriesProps> = ({ children, country, tax, manufacturer }) => {
    const [countryLoading, setCountryLoading] = useState<boolean>(true);
    const [taxLoading, setTaxLoading] = useState<boolean>(true);
    const [manufacturerLoading, setManufacturerLoading] = useState<boolean>(true);

    useEffect(() => {
        country ? DirectoriesController.loadCountries().then((res) => res && setCountryLoading(false)) : setCountryLoading(false);
        tax ? DirectoriesController.loadTaxes().then((res) => res && setTaxLoading(false)) : setTaxLoading(false);
        manufacturer ? DirectoriesController.loadManufacturers().then((res) => res && setManufacturerLoading(false)) : setManufacturerLoading(false);
    }, [children]);

    return countryLoading
        && taxLoading
        && manufacturerLoading
            ? <Loader/>
            : <>{children}</>;
}