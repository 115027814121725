import {MAIN_APP_ROUTES} from "../app-main/constants";
import {ICompanyForm, ICompanyInfoForm} from "../../interfaces/company";

export const getRoute = (route: string): string => `/${MAIN_APP_ROUTES.COMPANIES}/${route}`;

export enum COMPANIES_ROUTES {
    CREATE = 'create',
    EDIT = ':id',
    VIEW = 'view/:id',
}

export enum COMPANY_FORM_FIELDS {
    NAME = 'name',
    LOGO = 'logo',
    DESCRIPTION = 'description',
    EMAIL = 'email',
    ACTIVE = 'active',
    COUNTRY = 'country_id',
    IBAN = 'iban',
    BANK_INFO = 'bank_info',
    ADDRESS_REGISTER = 'address_register',
    ADDRESS_CORRESPONDENCE = 'address_correspondence',
    PHONE = 'phone',
    CURRENCY = 'currency',
    POSITION_PERSON = 'position_person',
    NAME_SIGNATURES = 'name_signatures',
    SURNAME_SIGNATURES = 'surname_signatures',
    COMPANY_NAME = 'company_name',
    USER_NAME = 'user_name',
    USER_SURNAME = 'user_surname',
    USER_MIDDLE_NAME = 'user_middle_name',
    USER_EMAIL = 'user_email',
    SIGNATORY = 'signatory',
}

export const INITIAL_COMPANY_FORM: ICompanyForm = {
    [COMPANY_FORM_FIELDS.NAME]: '',
    [COMPANY_FORM_FIELDS.EMAIL]: '',
    [COMPANY_FORM_FIELDS.DESCRIPTION]: '',
    [COMPANY_FORM_FIELDS.LOGO]: 1,
    [COMPANY_FORM_FIELDS.ACTIVE]: false,
    [COMPANY_FORM_FIELDS.COUNTRY]: '',
    [COMPANY_FORM_FIELDS.IBAN]: '',
    [COMPANY_FORM_FIELDS.BANK_INFO]: '',
    [COMPANY_FORM_FIELDS.ADDRESS_REGISTER]: '',
    [COMPANY_FORM_FIELDS.ADDRESS_CORRESPONDENCE]: '',
    [COMPANY_FORM_FIELDS.PHONE]: '',
    [COMPANY_FORM_FIELDS.CURRENCY]: '',
    [COMPANY_FORM_FIELDS.POSITION_PERSON]: '',
    [COMPANY_FORM_FIELDS.NAME_SIGNATURES]: '',
    [COMPANY_FORM_FIELDS.SURNAME_SIGNATURES]: '',
    [COMPANY_FORM_FIELDS.COMPANY_NAME]: '',
    [COMPANY_FORM_FIELDS.USER_NAME]: '',
    [COMPANY_FORM_FIELDS.USER_SURNAME]: '',
    [COMPANY_FORM_FIELDS.USER_MIDDLE_NAME]: '',
    [COMPANY_FORM_FIELDS.USER_EMAIL]: '',
    [COMPANY_FORM_FIELDS.SIGNATORY]: false,
};

export enum COMPANY_INFO_FORM_FIELDS {
    IBAN = 'iban',
    BANK_INFO = 'bank_info',
    ADDRESS_REGISTER = 'address_register',
    ADDRESS_CORRESPONDENCE = 'address_correspondence',
    PHONE = 'phone',
    POSITION_PERSON = 'position_person',
    NAME_SIGNATURES = 'name_signatures',
    SURNAME_SIGNATURES = 'surname_signatures',
    COMPANY_NAME = 'company_name',
}

export const INITIAL_COMPANY_INFO_FORM: ICompanyInfoForm = {
    [COMPANY_INFO_FORM_FIELDS.IBAN]: '',
    [COMPANY_INFO_FORM_FIELDS.BANK_INFO]: '',
    [COMPANY_INFO_FORM_FIELDS.ADDRESS_REGISTER]: '',
    [COMPANY_INFO_FORM_FIELDS.ADDRESS_CORRESPONDENCE]: '',
    [COMPANY_INFO_FORM_FIELDS.PHONE]: '',
    [COMPANY_INFO_FORM_FIELDS.POSITION_PERSON]: '',
    [COMPANY_INFO_FORM_FIELDS.NAME_SIGNATURES]: '',
    [COMPANY_INFO_FORM_FIELDS.SURNAME_SIGNATURES]: '',
    [COMPANY_INFO_FORM_FIELDS.COMPANY_NAME]: '',
};