import React, {useEffect, useState} from 'react';
import {useAppDispatch} from "../../../../store/store";
import {IOrderForm} from "../../../../interfaces/order";
import {ORDER_FORM_FIELDS} from "../../constants";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/order/reducer";
import {ApiOrder} from "../../../../api/endpoints/order";
import {Button, Col, Form, notification, Row} from "antd";
import {Input} from "../../../components-base/Input/Input";
import {add} from "husky/lib";

interface IOrdersFomAddressProps {
    id: string;
    address: string;
    city: string;
}

export const OrdersFomAddress: React.FC<IOrdersFomAddressProps> = ({ id, address, city }) => {
    const dispatch = useAppDispatch();
    const [form, setForm] = useState<IOrderForm>({
        [ORDER_FORM_FIELDS.ADDRESS]: address,
        [ORDER_FORM_FIELDS.CITY]: city,
    });
    const [validateErrors, setValidateErrors] = useState<Record<ORDER_FORM_FIELDS, any>>({} as Record<ORDER_FORM_FIELDS, any>);

    const changeForm = (key: keyof IOrderForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<ORDER_FORM_FIELDS, any> = {} as Record<ORDER_FORM_FIELDS, any>;

        if (!form[ORDER_FORM_FIELDS.ADDRESS]) errors[ORDER_FORM_FIELDS.ADDRESS] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[ORDER_FORM_FIELDS.CITY]) errors[ORDER_FORM_FIELDS.CITY] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            if (!id) return;
            dispatch(setLoading(true));
            const res = await ApiOrder.update(form, id);
            if (res) {
                notification.success({
                    message: ts('Зміни успішно збережені!'),
                });
            }
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        setForm({ [ORDER_FORM_FIELDS.ADDRESS]: address, [ORDER_FORM_FIELDS.CITY]: city });
    }, [address, city, id]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Row>
                <Col md={12}>
                    <Input value={form[ORDER_FORM_FIELDS.CITY]}
                           placeholder={ts('Місто доставки')}
                           label={ts('Місто')}
                           handlerChange={(value: any) => changeForm(ORDER_FORM_FIELDS.CITY, value)}
                           errors={validateErrors?.[ORDER_FORM_FIELDS.CITY]}
                           required
                    />
                </Col>
                <Col md={12}>
                    <Input value={form[ORDER_FORM_FIELDS.ADDRESS]}
                           placeholder={ts('Адреса доставки')}
                           label={ts('Адреса')}
                           handlerChange={(value: any) => changeForm(ORDER_FORM_FIELDS.ADDRESS, value)}
                           errors={validateErrors?.[ORDER_FORM_FIELDS.ADDRESS]}
                           required
                    />
                </Col>
            </Row>
            <Button type={'primary'} htmlType={'submit'}>{ts('Зберегти')}</Button>
        </Form>
    );
};
