import React from 'react';
import {MainApp} from "./components/app-main/MainApp";
import {Auth} from "./components/app-main/components/Wrappers/Auth/Auth";
import {Languages} from "./components/app-main/components/Wrappers/Languages/Languages";

function App() {
    return (
        <Auth>
            <Languages>
                <div className="main-container">
                    <MainApp/>
                </div>
            </Languages>
        </Auth>
    );
}

export default App;
