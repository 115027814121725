import {createAsyncThunk} from "@reduxjs/toolkit";
import {ACTION_TYPES} from "./actionTypes";
import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";
import {notification} from "antd";
import {ts} from "../../../translations/helpers";
import {ApiClient, IUpdateClientParams} from "../../../api/endpoints/client";
import {FILTER_TYPES, IFilter} from "../../../interfaces/main";

interface IEditFormParams {
    form: IUpdateClientParams;
    id: string;
}

export const loadClients = createAsyncThunk(
    ACTION_TYPES.GET_CLIENTS,
    async ({ ids = [] }: { ids?: number[] }, thunkApi) => {
        return thunkApi.dispatch(getClients({
            filters: [
                {type: FILTER_TYPES.LIKE, column: 'ids', value: ids}
            ]
        }));
    }
);

export const getClients = createAsyncThunk(
    ACTION_TYPES.GET_CLIENTS,
    async ({ filters = [] }: { filters?: IFilter[] }, thunkApi) => {
        const state = (thunkApi.getState() as RootState)[REDUCERS.CLIENT];
        const { page, pageSize, sort } = state;
        return await ApiClient.getAll({ filters, page, pageSize, sort });
    }
);

export const editClient = createAsyncThunk(
    ACTION_TYPES.EDIT_CLIENT,
    async ({ form, id }: IEditFormParams, thunkApi) => {
        const res = await ApiClient.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені'),
            });
            return res;
        }
    }
);

export const deleteClient = createAsyncThunk(
    ACTION_TYPES.DELETE_CLIENT,
    async (id: string, thunkApi) => {
        const res = await ApiClient.delete(id);
        if (res) {
            notification.success({
                message: ts('Категорія успішно видалена'),
            });
            return id;
        }
    }
);
