import { createAsyncThunk } from "@reduxjs/toolkit";
import {ACTION_TYPES} from "./actionTypes";
import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";
import {ApiStuff, IUpdateStuffParams} from "../../../api/endpoints/stuff";
import {notification} from "antd";
import {ts} from "../../../translations/helpers";
import {FILTER_TYPES} from "../../../interfaces/main";
import {getProducts} from "../product/actions";

interface IEditStuffParams {
    form: IUpdateStuffParams;
    id: string;
}

export const loadStuffs = createAsyncThunk(
    ACTION_TYPES.GET_STUFF,
    async ({ ids = [] }: { ids?: number[] }, thunkApi) => {
        return thunkApi.dispatch(getStuffs({
            filters: [
                {type: FILTER_TYPES.LIKE, column: 'ids', value: ids}
            ]
        }));
    }
);

export const getStuffs = createAsyncThunk(
    ACTION_TYPES.GET_STUFF,
    async ({}: {}, thunkApi) => {
        const state = (thunkApi.getState() as RootState)[REDUCERS.USER];
        const { page, pageSize, sort } = state;
        return await ApiStuff.getAll({ page, pageSize, sort });
    }
);

export const editStuff = createAsyncThunk(
    ACTION_TYPES.EDIT_STUFF,
    async ({ form, id }: IEditStuffParams, thunkApi) => {
        const res = await ApiStuff.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені'),
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            return res;
        }
    }
);

export const deleteStuffs = createAsyncThunk(
    ACTION_TYPES.DELETE_STUFF,
    async (id: string, thunkApi) => {
        const res = await ApiStuff.delete(id);
        if (res) {
            notification.success({
                message: ts('Користувач успішно видалений'),
            })
            return id;
        }
    }
);
