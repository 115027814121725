import {API_METHODS, ENDPOINTS} from "./constants";
import {API_RESPONSE_CODE, IApiDataPaginationResponse, IGetPaginatedDataParams} from "../interfaces/api";
import {apiRequest} from "./instance";
import {notification} from "antd";
import {ts} from "../translations/helpers";


export class ApiBase<UPDATE_FORM, CREATE_FORM, ITEM> {
    public endpoint: ENDPOINTS | undefined;

    public getEndpoint = () => String(this.endpoint);

    public mapUpdateForm = (form: UPDATE_FORM): UPDATE_FORM => form;

    public getAll = async (
        {
            filters,
            page,
            pageSize,
            sort,
        }: IGetPaginatedDataParams): Promise<IApiDataPaginationResponse<ITEM[]>> =>
    {
        const res = await apiRequest(
            `/${this.getEndpoint()}`,
            {
                filters,
                page,
                pageSize,
                sort,
            },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : false;
    };

    public getOne = async (id: string): Promise<ITEM> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}`,
            {},
            API_METHODS.GET
        );

        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : {} as ITEM;
    };

    public create = async (form: CREATE_FORM) => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/create`,
            form,
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? true
            : notification.error({
                message: ts('Помилка при створенні'),
                description: JSON.stringify(res.message),
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
    };

    public update = async (form: UPDATE_FORM, id: string): Promise<ITEM> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}`,
            this.mapUpdateForm(form),
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
    };

    public delete = async (id: string): Promise<boolean | void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}`,
            {},
            API_METHODS.DELETE
        );

        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? true
            : notification.error({
                message: ts('Помилка при видалені'),
                description: JSON.stringify(res.message),
            });
    };
}