import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoading} from "../../store/reducers/product/selectors";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {Route, Routes} from "react-router-dom";
import {PRODUCTS_ROUTES} from "./constants";
import {ProductTable} from "./components/ProductTable";
import {ProductCreate} from "./components/ProductCreate";
import {ProductUpdate} from "./components/ProductUpdate";

export const ProductApp: React.FC = () => {
    const isLoading = useSelector(selectIsLoading);

    return (
        <div className={'company-container'}>
            <Loading isLoading={isLoading}>
                <Routes>
                    <Route path={PRODUCTS_ROUTES.CREATE} element={<ProductCreate/>}/>
                    <Route path={PRODUCTS_ROUTES.EDIT} element={<ProductUpdate/>}/>
                    <Route path={'/'} element={<ProductTable/>}/>
                </Routes>
            </Loading>
        </div>
    );
}