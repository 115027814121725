import React, {useEffect, useState} from "react";
import {ts} from "../../../../translations/helpers";
import {Button, Form, Row} from "antd";
import {useNavigate} from "react-router-dom";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useSelector} from "react-redux";
import {getRoute} from "../../constants";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Table} from "../../../components-base/Table/Table";
import { PlusCircleFilled } from '@ant-design/icons'
import {WAREHOUSES_ROUTES} from "../../constants";
import {selectWarehouseProducts, selectWarehouseTotalData} from "../../../../store/reducers/warehouse/selectors";
import {IWarehouseLot} from "../../../../interfaces/warehouse";
import {ApiProduct} from "../../../../api/endpoints/product";
import {getAttributes} from "../../../../store/reducers/attribute/actions";
import {FILTER_TYPES, ICountry} from "../../../../interfaces/main";
import {useAppDispatch} from "../../../../store/store";
import {getProducts} from "../../../../store/reducers/product/actions";
import {selectProductsById} from "../../../../store/reducers/product/selectors";
import {IProduct} from "../../../../interfaces/product";
import {selectCompanies} from "../../../../store/reducers/company/selectors";
import {Select} from "../../../components-base/Select/Select";
import {CATEGORY_FORM_FIELDS} from "../../../app-category/constants";
import {ApiCategory} from "../../../../api/endpoints/category";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {ICompany} from "../../../../interfaces/company";
import {ApiCompany} from "../../../../api/endpoints/company";
import {getWarehouseProducts} from "../../../../store/reducers/warehouse/actions";
import authManager from "../../../app-main/components/AuthManager/AuthManager";
import {PERMISSIONS} from "../../../app-main/components/AuthManager/permissions";

const getColumns = (productsData:  Record<string, IProduct>) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Продукт'),
            dataIndex: 'product_id',
            key: 'product_id',
            sorter: true,
            render: (value: any) => {
                const product = productsData[value];
                return <span>{product?.name}</span>
            }
        },
        {
            title: ts('Кількість'),
            dataIndex: 'count',
            key: 'count',
            sorter: true,
        },
        {
            title: ts('Лот'),
            dataIndex: 'lot',
            key: 'lot',
            render: (value: any, item: any) => {
                return value.map((e: IWarehouseLot) => (
                    <div>
                        <small>
                            {`${e.lot} (${e.date_to}) - ${e.count}`},
                        </small>
                    </div>
                ));
            }
        },
    ];
};

export const WarehouseProducts: React.FC = () => {
    const dispatch = useAppDispatch();
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const [companyId, setCompanyId] = useState<number | null>(null)
    const products = useSelector(selectWarehouseProducts);
    const totalData = useSelector(selectWarehouseTotalData);
    const productsData = useSelector(selectProductsById);
    const columns = getColumns(productsData);

    useEffect(() => {
        if(products) {
            dispatch(getProducts({ filters: [{ column: 'ids', type: FILTER_TYPES.IN, value: products.map((product) => Number(product.product_id)) }] }));
        }
    }, [products]);

    useEffect(() => {
        dispatch(getWarehouseProducts({ companyId }));
    }, [companyId]);

    return (
        <div className={'user-table-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={ts('Склад')}
                subtitle={ts('Залишки товарів на вашому складі')}
                extra={[
                    <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(WAREHOUSES_ROUTES.RECEIVING))}>
                        <PlusCircleFilled />
                        {ts('Надходження')}
                    </Button>,
                    <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(WAREHOUSES_ROUTES.WRITE_OFF))} danger>
                        <PlusCircleFilled />
                        {ts('Списання')}
                    </Button>
                ]}
            />
            {authManager.getAccess(PERMISSIONS.CAN_SEE_OTHER_WAREHOUSES) && (
                <Form>
                    <Autocomplete name={'company'}
                                  label={ts('Обрати склад поставщика')}
                                  value={companyId}
                                  placeholder={ts('Оберіть поставщика')}
                                  optionsMapper={(data: ICompany) => ({ value: data.name, id: data.id })}
                                  getData={() => ApiCompany.getAll({ filters: [], page: 1, pageSize: 9999})}
                                  onChange={(value: any) => {
                                      setCompanyId(value);
                                  }}
                    />
                </Form>
            )}
            <Table columns={columns}
                   data={products}
                   total={totalData}
                   page={1}
                   pageSize={9999}
                   sort={null}
            />
        </div>
    );
};