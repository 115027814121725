import React from 'react';
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {UserForm} from "../UserForm";

export const UserCreate = () => {
    const { breadcrumbs } = useActiveRoute();

    return (
        <div className={'user-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Створити користувача')}/>
            <PageHeader
                title={ts('Створити користувача')}
            />
            <UserForm create/>
        </div>
    );
}