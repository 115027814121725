import { createAsyncThunk } from "@reduxjs/toolkit";
import {ACTION_TYPES} from "./actionTypes";
import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";
import {notification} from "antd";
import {ts} from "../../../translations/helpers";
import {ApiCompany, IUpdateCompanyParams} from "../../../api/endpoints/company";

interface IEditFormParams {
    form: IUpdateCompanyParams;
    id: string;
}

export const getCompanies = createAsyncThunk(
    ACTION_TYPES.GET_COMPANIES,
    async ({}: {}, thunkApi) => {
        const state = (thunkApi.getState() as RootState)[REDUCERS.COMPANY];
        const { page, pageSize, sort } = state;
        return await ApiCompany.getAll({ page, pageSize, sort });
    }
);

export const editCompany = createAsyncThunk(
    ACTION_TYPES.EDIT_COMPANY,
    async ({ form, id }: IEditFormParams, thunkApi) => {
        const res = await ApiCompany.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені'),
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            return res;
        }
    }
);

export const deleteCompany = createAsyncThunk(
    ACTION_TYPES.DELETE_COMPANY,
    async (id: string, thunkApi) => {
        const res = await ApiCompany.delete(id);
        if (res) {
            notification.success({
                message: ts('Компанія успішно видалена'),
            })
            return id;
        }
    }
);