import React, {useMemo} from 'react';
import i18n from '../../../../translations';
import { Select as AntSelect } from 'antd';
import {ISelectItem} from "../../../../interfaces/main";
import {useSelector} from "react-redux";
import {selectLang, selectLangAvailable} from "../../../../store/reducers/common/selectors";

export const LangSwitcher: React.FC = () => {
    const lang = useSelector(selectLang);
    const langAvailable = useSelector(selectLangAvailable);

    const options = useMemo<ISelectItem[]>(() => {
        return (langAvailable).map((lang) => ({ label: lang.code, value: lang.code}));
    }, [langAvailable]);

    const changeLang = (lang: string) => {
        i18n.changeLanguage(lang).then(() => {
            window.location.reload();
        })
    };

    return (
        <div className={'lang-switcher-container'}>
            <AntSelect
                defaultValue={'ua'}
                options={options}
                value={lang}
                onChange={changeLang}
                style={{
                    width: '63px',
                }}
            />
        </div>
    );
}