import {createSlice, PayloadAction, Slice} from '@reduxjs/toolkit'
import {DEFAULT_PAGE_SIZE, REDUCERS} from "../../../constants";
import {IPaginationData, ISort, IUser} from "../../../interfaces/main";
import {mapDataById} from "../../../helpers/data";
import {deleteStuffs, editStuff, getStuffs} from "./actions";

interface UserState {
    isLoading: boolean;
    ids: string[];
    idsData: Record<string, IUser>;
    dataCount: number;
    page: number;
    pageSize: number;
    sort: ISort | null;
}

const initialState: UserState = {
    isLoading: false,
    ids: [],
    idsData: {},
    dataCount: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: null,
};

export const userSlice: Slice<UserState> = createSlice({
    name: REDUCERS.USER,
    initialState,
    reducers: {
        setPagination: (state, action: PayloadAction<IPaginationData>) => {
            const { page, pageSize } = action.payload;
            state.page = page;
            state.pageSize = pageSize;
        },
        setSort: (state, action: PayloadAction<ISort>) => {
            state.sort = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStuffs.pending, (state, dispatch) => {
            state.isLoading = true;
        });
        builder.addCase(getStuffs.fulfilled,
            (state, { payload }) => {
                const { data, count, page, pageSize } = payload;
                state.ids = data.map((item) => String(item.id));
                state.idsData = { ...state.idsData, ...mapDataById(data)};
                state.dataCount = count;
                state.page = page;
                state.pageSize = pageSize;
                state.isLoading = false;
            });
        builder.addCase(editStuff.fulfilled,
            (state, { payload }) => {
                if(payload?.id) {
                    state.idsData[payload.id] = payload;
                }
            });
        builder.addCase(deleteStuffs.fulfilled,
            (state, { payload }) => {
                if(payload) {
                    state.idsData[payload] = { ...state.idsData[payload], enabled: false } ;
                }
            });
        // builder.addCase(fetchTest.rejected,
        //     (state, { payload }) => {
        //         if (payload) state.error = payload.message;
        //         state.status = "idle";
        //     });
    },
});

export const { setPagination, setSort, setLoading  } = userSlice.actions;

export default userSlice;