import React, {useState} from 'react';
import {ts} from "../../../../translations/helpers";
import {Switch} from "../../../components-base/Switch/Switch";
import {formatDate} from "../../../../helpers/formaters";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {Button, Space, Table} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import {DIRECTORIES, ICountry, IManufacturer, ITax} from "../../../../interfaces/main";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {DIRECTORIES_ROUTES, getRoute} from "../../constants";
import {DirectoriesApi} from "../../../../api/endpoints/directory";

interface IProps {
    directory: DIRECTORIES,
    data: ICountry[] | ITax[] | IManufacturer[],
}


const getColumns = (
    handlerDelete: (id: string) => void,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Назва'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => a?.name.localeCompare(b?.name),
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    <Button type={'default'} >
                        <Link to={`${item.id}`}><EditOutlined /></Link>
                    </Button>
                    {/*<Button><SelectOutlined /></Button>*/}
                    <Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>
                </Space>;
            }
        },


    ];
};

export const DirectoryTable: React.FC<IProps> = ({ directory, data }) => {
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const [deletedItems, setDeletedItems] = useState<string[]>([]);
    const columns = getColumns((id: string) => {
        DirectoriesApi.delete(directory, id).then(() => setDeletedItems([...deletedItems, String(id)]));
    });
    return (
        <div className={'directory-main-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={DirectoriesController.getDirectoryName(directory)}
                subtitle={ts('Довідник')}
                extra={[
                    <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(DIRECTORIES_ROUTES.CREATE, directory))}>
                        <PlusCircleFilled />
                        {ts('Створити')}
                    </Button>
                ]}
            />
            <Table columns={columns}
                   dataSource={data.filter((item) => !deletedItems.includes(String(item.id)))}
            />
        </div>
    );
}