export const LOCAL_STORAGE_ACCESS_KEY = 'access-token';

export enum API_METHODS {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
}

export const VERSION = 1;

export const API_BASE_URL = `https://api.medbuy.world/v${VERSION}`;

export enum ENDPOINTS {
    AUTH = 'auth',
    STUFF = 'stuff',
    CATEGORY = 'category',
    DIRECTORY = 'directory',
    CLIENT = 'client',
    ORDER = 'order',
    WAREHOUSE = 'warehouse',
    PRODUCT = 'product',
    LANG = 'lang',
    CURRENCY = 'currency',
    SLIDER = 'slider',
    ATTRIBUTE = 'attribute',
    TAG = 'tag',
    USER = 'user',
    COMPANY = 'company',
}
