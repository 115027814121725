import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/client/reducer";
import {Button, Form, notification} from "antd";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {Input} from "../../../components-base/Input/Input";
import {Switch} from "../../../components-base/Switch/Switch";
import {CLIENT_FORM_FIELDS, INITIAL_CLIENT_FORM} from "../../constants";
import {IClientForm} from "../../../../interfaces/client";
import {validateEmail} from "../../../../helpers/validators";
import {COMPANY_FORM_FIELDS} from "../../../app-company/constants";
import {ApiClient} from "../../../../api/endpoints/client";

interface IClientFormProps {
    create?: boolean;
    id?: string | undefined;
    client?: IClientForm;
}

export const ClientForm: React.FC<IClientFormProps> = ({ create, id, client }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<IClientForm>(INITIAL_CLIENT_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<CLIENT_FORM_FIELDS, any>>({} as Record<CLIENT_FORM_FIELDS, any>)

    const changeForm = (key: keyof IClientForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<string, any> = {};
        const emailErrors = validateEmail(form[COMPANY_FORM_FIELDS.EMAIL]);

        if (!form[CLIENT_FORM_FIELDS.NAME]) errors[CLIENT_FORM_FIELDS.NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[CLIENT_FORM_FIELDS.MIDDLE_NAME]) errors[CLIENT_FORM_FIELDS.MIDDLE_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[CLIENT_FORM_FIELDS.SURNAME]) errors[CLIENT_FORM_FIELDS.SURNAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (emailErrors) errors[CLIENT_FORM_FIELDS.EMAIL] = emailErrors;
        if (!form[CLIENT_FORM_FIELDS.PHONE]) errors[CLIENT_FORM_FIELDS.PHONE] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if(form[CLIENT_FORM_FIELDS.IS_COMPANY]) {
            if (!form[CLIENT_FORM_FIELDS.COMPANY_NAME]) errors[CLIENT_FORM_FIELDS.COMPANY_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[CLIENT_FORM_FIELDS.COMPANY_PDV_CODE]) errors[CLIENT_FORM_FIELDS.COMPANY_PDV_CODE] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[CLIENT_FORM_FIELDS.COMPANY_CODE]) errors[CLIENT_FORM_FIELDS.COMPANY_CODE] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[CLIENT_FORM_FIELDS.COMPANY_ADDRESS]) errors[CLIENT_FORM_FIELDS.COMPANY_ADDRESS] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        }

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await handlerCreate() : await handlerUpdate();
            dispatch(setLoading(false));
        }
    };

    const handlerCreate = async () => {
        const res = await ApiClient.create(form);
        if (res) {
            notification.success({
                message: ts('Клієнт успішно створений!'),
            });
            navigate('/' + MAIN_APP_ROUTES.CLIENTS);
        }
    };

    const handlerUpdate = async () => {
        if (!id) return;
        const res = await ApiClient.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (client) setForm(client);
    }, [client]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Input value={form[CLIENT_FORM_FIELDS.NAME]}
                   placeholder={ts('Введіть і\'мя')}
                   label={ts('І\'мя')}
                   handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.NAME, value)}
                   errors={validateErrors?.[CLIENT_FORM_FIELDS.NAME]}
                   required
            />
            <Input value={form[CLIENT_FORM_FIELDS.SURNAME]}
                   placeholder={ts('Введіть прізвище')}
                   label={ts('Прізвище')}
                   handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.SURNAME, value)}
                   errors={validateErrors?.[CLIENT_FORM_FIELDS.SURNAME]}
                   required
            />
            <Input value={form[CLIENT_FORM_FIELDS.MIDDLE_NAME]}
                   placeholder={ts('Введіть по-батькові')}
                   label={ts('По-батькові')}
                   handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.MIDDLE_NAME, value)}
                   errors={validateErrors?.[CLIENT_FORM_FIELDS.MIDDLE_NAME]}
                   required
            />
            <Input value={form[CLIENT_FORM_FIELDS.PHONE]}
                   placeholder={ts('Введіть телефон')}
                   label={ts('Телефон')}
                   handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.PHONE, value)}
                   errors={validateErrors?.[CLIENT_FORM_FIELDS.PHONE]}
                   required
            />
            <Input value={form[CLIENT_FORM_FIELDS.EMAIL]}
                   placeholder={ts('Введіть e-mail')}
                   label={ts('E-mail')}
                   handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.EMAIL, value)}
                   errors={validateErrors?.[CLIENT_FORM_FIELDS.EMAIL]}
                   required
            />

            <Switch checked={form[CLIENT_FORM_FIELDS.IS_COMPANY] || false}
                    handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.IS_COMPANY, value)}
                    label={ts('Юридична особа')}
                    name={CLIENT_FORM_FIELDS.IS_COMPANY}
            />
            {form[CLIENT_FORM_FIELDS.IS_COMPANY] && (
                <>
                    <Input value={form[CLIENT_FORM_FIELDS.COMPANY_NAME]}
                           placeholder={ts('Введіть назву компанії')}
                           label={ts('Назва компанії')}
                           handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.COMPANY_NAME, value)}
                           errors={validateErrors?.[CLIENT_FORM_FIELDS.COMPANY_NAME]}
                           required
                    />
                    <Input value={form[CLIENT_FORM_FIELDS.COMPANY_ADDRESS]}
                           placeholder={ts('Введіть адресу компанії')}
                           label={ts('Адреса компанії')}
                           handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.COMPANY_ADDRESS, value)}
                           errors={validateErrors?.[CLIENT_FORM_FIELDS.COMPANY_ADDRESS]}
                           required
                    />
                    <Input value={form[CLIENT_FORM_FIELDS.COMPANY_CODE]}
                           placeholder={ts('Введіть код компанії')}
                           label={ts('Код компанії')}
                           handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.COMPANY_CODE, value)}
                           errors={validateErrors?.[CLIENT_FORM_FIELDS.COMPANY_CODE]}
                           required
                    />
                    <Input value={form[CLIENT_FORM_FIELDS.COMPANY_PDV_CODE]}
                           placeholder={ts('Введіть податковий код компанії')}
                           label={ts('Податковий код компанії')}
                           handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.COMPANY_PDV_CODE, value)}
                           errors={validateErrors?.[CLIENT_FORM_FIELDS.COMPANY_PDV_CODE]}
                           required
                    />
                </>
            )}
            <Switch checked={form[CLIENT_FORM_FIELDS.ACTIVE] || false}
                    handlerChange={(value: any) => changeForm(CLIENT_FORM_FIELDS.ACTIVE, value)}
                    label={ts('Активність')}
                    name={CLIENT_FORM_FIELDS.ACTIVE}
            />
            <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
        </Form>
    );
};