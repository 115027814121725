import React from 'react';
import {Checkbox as AntCheckbox, Form} from 'antd';
import {ts} from "../../../translations/helpers";

interface ICheckboxProps {
    handlerChange: (value: boolean) => void;
    label: string;
    name: string;
    checked: boolean;
    disabled?: boolean;
    required?: boolean;
    errorMessage?: string;
}

export const Checkbox: React.FC<ICheckboxProps> = (
    {
        handlerChange,
        label,
        name,
        checked,
        required,
        disabled = false,
        errorMessage,
    }) => {
    return (
        <Form.Item name={name}
                   rules={[
                       {
                           required,
                           message: errorMessage || ts('Необхідно обрати '),
                       },
                   ]}>
            <AntCheckbox onChange={(event) => handlerChange(event.target.checked)} checked={checked} disabled={disabled}>
                {label}
            </AntCheckbox>
        </Form.Item>
    );
}