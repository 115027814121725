import { Form, Switch as AntSwitch } from 'antd';
import React from 'react';

interface ISwitchProps {
    checked: boolean;
    handlerChange: (checked: boolean ) => void;
    name: string;
    label?: string;
    disabled?: boolean;
    reverse?: boolean;
}

export const Switch: React.FC<ISwitchProps> = ({ checked, name, handlerChange, label, reverse, disabled }) => {
    return (
        <Form.Item name={name} label={label} style={{ marginBottom: 0 }} className={`ant-switcher-form-item ${reverse ? 'reverse' : ''}`}>
            <AntSwitch onChange={handlerChange} checked={checked} disabled={disabled}/>
        </Form.Item>
    );
};
