import React, {ReactNode, useEffect} from 'react';
import {useAppDispatch} from "../../../../../store/store";
import {LOCAL_STORAGE_LANGUAGE} from "../../../constants";
import {setCurrencies, setLang, setLangAvailable} from "../../../../../store/reducers/common/reducer";
import {DEFAULT_LANG} from "../../../../../constants";
import {getLanguages} from "../../../../../api/endpoints/lang";
import {getCurrencies} from "../../../../../api/endpoints/currency";

interface ILanguagesProps {
    children?: ReactNode;
}

export const Languages: React.FC<ILanguagesProps> = ({ children }) => {
    const dispatch = useAppDispatch();

    const currentLang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

    useEffect(() => {
        getLanguages().then((languages) => {
            dispatch(setLang(currentLang ?? DEFAULT_LANG));
            dispatch(setLangAvailable(languages));
        });
        getCurrencies().then((currencies) => {
            dispatch(setCurrencies(currencies));
        });
    }, []);

    return (
        <>
            {children}
        </>
    );
}
