import React, {useEffect, useState} from 'react';
import {AutoComplete as AntAutocomplete, Button, Col, Divider, Form, notification, Row} from "antd";
import {ts} from "../../../../translations/helpers";
import {UploadFile} from "../../../components-base/UploadFile/UploadFile";
import {FILE_UPLOADS_TYPE, ICountry} from "../../../../interfaces/main";
import {Input} from "../../../components-base/Input/Input";
import {ApiSlider} from "../../../../api/endpoints/slider";
import {useSelector} from "react-redux";
import {selectLangAvailable} from "../../../../store/reducers/common/selectors";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import {PRODUCT_FORM_FIELDS} from "../../../app-product/constants";
import {Select} from "../../../components-base/Select/Select";

interface IProps {
    countryId: number;
    handlerUpdate: (status: boolean) => void;
}

export const SliderHomePageForm: React.FC<IProps> = ({ countryId, handlerUpdate }) => {
    const [sort, setSort] = useState<number>(0);
    const [image, setImage] = useState<number | string | null>(null);
    const [imageLink, setImageLink] = useState<string | null>(null);
    const [link, setLink] = useState<string>('');
    const [lang, setLang] = useState<number | null>(null);
    const langAvailable = useSelector(selectLangAvailable);

    const handlerUploadImage = async ({ file_id, link }: { file_id: string, name: string, link: string }) => {
        setImage(file_id);
        setImageLink(link);
        notification.success({
            message: ts('Зміни успішно збережені!'),
        })
    };

    const handlerSubmit = async () => {
        if( image && lang ) {
            await ApiSlider.createImageHomePage(String(image), sort, countryId, link, lang);
            setSort(0);
            setImage(null);
            setLink('');
            setImageLink(null);
            handlerUpdate(true);
        }

    };

    return (
        <div className="slider-add-form">
            <Form layout={'horizontal'} onFinish={handlerSubmit}>
                <Row>
                    <Col md={3}>
                        {imageLink
                            ? <img src={imageLink} alt={''} style={{ maxWidth: '100%', maxHeight: '100px' }}/>
                            : <UploadFile type={FILE_UPLOADS_TYPE.IMAGE}
                                           handlerCreate={handlerUploadImage}
                                           maxCount={1}
                                           uploadedFiles={[]}
                              />
                        }
                    </Col>
                    <Col md={6}>
                        <Input value={sort}
                               placeholder={ts('Введіть порядок сортування')}
                               label={ts('Сортування')}
                               handlerChange={setSort}
                        />
                        <Input value={link}
                               placeholder={ts('Введіть посилання')}
                               label={ts('Посилання')}
                               handlerChange={setLink}
                        />
                    </Col>
                    <Col md={1}>

                    </Col>
                    <Col md={6}>
                        <Select options={langAvailable.map((lang) => ({ value: lang.id, label: lang.name }))}
                                value={lang}
                                label={ts('Мова')}
                                handlerChange={(value: any) => setLang(value)}
                        />
                        <Button type={'primary'} htmlType={'submit'} style={{ marginBottom: '23px'}}>{ts('Створити')}</Button>
                    </Col>
                </Row>
            </Form>

        </div>
    );
}
