import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import React from "react";
import {CompanyForm} from "../CompanyForm";

export const CompanyCreate = () => {
    const { breadcrumbs } = useActiveRoute();

    return (
        <div className={'company-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Створити компанію')}/>
            <PageHeader
                title={ts('Створити компанію')}
            />
            <CompanyForm create/>
        </div>
    );
};
