import React from 'react';
import {ts} from "../../../../translations/helpers";
import {Descriptions} from "antd";

interface IOrdersInfoCustomerProps {
    name: string;
    surname: string;
    middle_name: string;
    email: string;
    phone: string;
}
export const OrdersInfoCustomer: React.FC<IOrdersInfoCustomerProps> = ({ name, surname, middle_name, email, phone }) => {
    return (
        <Descriptions>
            <Descriptions.Item label={ts('І\'мя')}>
                {name || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={ts('Прізвище')}>
                {surname || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={ts('По-батькові')}>
                {middle_name || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={ts('E-mail')}>
                {email || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={ts('Телефон')}>
                {phone || '-'}
            </Descriptions.Item>
        </Descriptions>
    );
}
