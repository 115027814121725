import {apiRequest} from "../instance";
import {API_METHODS, ENDPOINTS} from "../constants";
import {API_RESPONSE_CODE, IApiDataPaginationResponse, IGetPaginatedDataParams} from "../../interfaces/api";
import {IFilter, ISort, IUser} from "../../interfaces/main";
import {notification} from "antd";
import {ts} from "../../translations/helpers";
import {IUserForm} from "../../interfaces/users";
import {ApiBase} from "../ApiBase";

export interface IUpdateStuffParams {
    name?: string;
    surname?: string;
    middle_name?: string;
    active?: boolean;
    signatory?: boolean;
}

class ApiStuff extends ApiBase<IUpdateStuffParams, IUserForm, IUser >{
    public endpoint = ENDPOINTS.STUFF;
    public mapUpdateForm = (form: IUpdateStuffParams): any => ({ ...form, active: Number(form?.active), signatory: Number(form?.signatory) });
}

const apiStuff = new ApiStuff();

export { apiStuff as ApiStuff };

// export const getStuff = async (
//     {
//         filters,
//         page,
//         pageSize,
//         sort,
//     }: IGetPaginatedDataParams): Promise<IApiDataPaginationResponse<IUser[]>> =>
//     {
//         const res = await apiRequest(
//             `/${ENDPOINTS.STUFF}`,
//             {
//                 filters,
//                 page,
//                 pageSize,
//                 sort,
//             },
//             API_METHODS.POST
//         );
//         return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
//             ? res.data
//             : false;
//     };
//
// export const createStuff = async (form: IUserForm) => {
//     const res = await apiRequest(
//         `/${ENDPOINTS.STUFF}/create`,
//         form,
//         API_METHODS.POST
//     );
//     return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
//         ? true
//         : notification.error({
//             message: ts('Помилка при створенні'),
//             description: JSON.stringify(res.message),
//             onClick: () => {
//                 console.log('Notification Clicked!');
//             },
//         });
// };
//
// export const updateStuff = async (form: IUpdateStuffParams, id: string): Promise<IUser> => {
//     const res = await apiRequest(
//         `/${ENDPOINTS.STUFF}/${id}`,
//         { ...form, active: Number(form?.active), signatory: Number(form?.signatory) },
//         API_METHODS.PUT
//     );
//     return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
//         ? res.data
//         : notification.error({
//             message: ts('Помилка при редагуванні'),
//             description: JSON.stringify(res.message),
//             onClick: () => {
//                 console.log('Notification Clicked!');
//             },
//         });
// };
//
// export const getOneStuff = async (id: string): Promise<IUser> => {
//     const res = await apiRequest(
//         `/${ENDPOINTS.STUFF}/${id}`,
//         {},
//         API_METHODS.GET
//     );
//
//     return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
//         ? res.data
//         : {} as IUser;
// };
//
// export const deleteStuff = async (id: string): Promise<boolean | void> => {
//     const res = await apiRequest(
//         `/${ENDPOINTS.STUFF}/${id}`,
//         {},
//         API_METHODS.DELETE
//     );
//
//     return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
//         ? true
//         : notification.error({
//             message: ts('Помилка при видалені'),
//             description: JSON.stringify(res.message),
//         });
// };