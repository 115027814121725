import React, {useMemo} from 'react';
import {List, Typography} from "antd";
import {IButtonListItem} from "../../../interfaces/component-base";

interface IButtonListProps {
    data: IButtonListItem[];
}

export const ButtonList: React.FC<IButtonListProps> = ({ data }) => {
    return (
        <List className={'buttons-list-container'} style={{ minWidth: '190px' }}>
            {data.map((item) => (
                <List.Item key={item.name}>
                    {item.icon} <Typography.Text onClick={item.onClick}>{item.name}</Typography.Text>
                </List.Item>
            ))}
        </List>
    );
}