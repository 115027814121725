import {getWarehouseProducts} from "./actions";
import {ISort} from "../../../interfaces/main";
import {REDUCERS} from "../../../constants";
import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {IWarehouseActionProduct, IWarehouseLot} from "../../../interfaces/warehouse";

const _ = require('lodash');

interface WarehouseState {
    isLoading: boolean;
    products: IWarehouseActionProduct[];
    dataCount: number;
    sort: ISort | null;
}

const initialState: WarehouseState = {
    isLoading: false,
    products: [],
    dataCount: 0,
    sort: null,
};

export const warehouseSlice: Slice<WarehouseState> = createSlice({
    name: REDUCERS.WAREHOUSE,
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWarehouseProducts.pending, (state, dispatch) => {
            state.isLoading = true;
        });
        builder.addCase(getWarehouseProducts.fulfilled,
            (state, { payload }) => {
                state.products = payload;
                state.dataCount = payload.length;
                state.isLoading = false;
            });
    },
});

export const { setLoading  } = warehouseSlice.actions;

export default warehouseSlice;