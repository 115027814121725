import {MAIN_APP_ROUTES} from "../app-main/constants";
import {ITagForm} from "../../interfaces/tag";

export const getRoute = (route: string): string => `/${MAIN_APP_ROUTES.TAGS}/${route}`;

export enum TAGS_ROUTES {
    CREATE = 'create',
    EDIT = ':id',
}

export enum TAG_FORM_FIELDS {
    NAME = 'name',
    ALIAS = 'alias',
    ACTIVE = 'active',
    LANG = 'lang',
}

export const INITIAL_TAG_FORM: ITagForm = {
    [TAG_FORM_FIELDS.NAME]: '',
    [TAG_FORM_FIELDS.ALIAS]: '',
    [TAG_FORM_FIELDS.ACTIVE]: false,
};