import React, {useState} from "react";
import {ts} from "../../../../translations/helpers";
import { Divider, Tabs } from "antd";
import {SliderRelatedProductsForm} from "./SliderRelatedProductsForm";
import {SliderRelatedProductsTable} from "./SliderRelatedProductsTable";

const {TabPane} = Tabs;

interface IProps {
    countryId: number;
    shouldUpdate: boolean;
    setShouldUpdate: (status: boolean) => void;
}

export const SliderRelatedProducts: React.FC<IProps> = ({ countryId, shouldUpdate, setShouldUpdate }) => {
    const [] = useState<boolean>(true);
    console.log('RRRRRRRRRRRRRRRRRRRRRR');
    return (
        <div className={'slider-settings-container'}>
            <Divider children={ts('Додати елемент слайдера')} orientation="left" orientationMargin="0"/>
            <SliderRelatedProductsForm countryId={countryId} handlerUpdate={setShouldUpdate}/>
            <Divider children={ts('Завантажені елементи слайдера')} orientation="left" orientationMargin="0"/>
            <SliderRelatedProductsTable countryId={countryId} shouldUpdate={shouldUpdate} handlerUpdate={setShouldUpdate}/>
        </div>
    );
};