import React from 'react';
import {Layout} from "antd";
import {Menu} from "../Menu/Menu";
import {Logo} from "../../../components-base/Logo/Logo";


const { Sider } = Layout;

const sidebarStyles: Record<string, any> = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
};

interface ISidebarProps {
    collapsed: boolean;
}

export const Sidebar: React.FC<ISidebarProps> = ({ collapsed }) => {
    return (
        <Sider trigger={null}
               collapsible
               collapsed={collapsed}
               style={sidebarStyles}
        >
            <Logo inverted={false}/>
            <Menu/>
        </Sider>
    );
}