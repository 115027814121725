import React, {useEffect, useState} from 'react';
import {Form} from "antd";
import {Select} from "../../../components-base/Select/Select";
import {ISelectItem} from "../../../../interfaces/main";
import {ApiProduct} from "../../../../api/endpoints/product";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";

interface IProductCategoryProps {
    product_id: number | string;
    selectedCountries: string[];
}

export const ProductCountry: React.FC<IProductCategoryProps> = ({ product_id, selectedCountries }) => {

    const countries = DirectoriesController.getCountries();
    const [selected, setSelected] = useState<ISelectItem[]>([]);

    const handlerSelect = (country_id: string) => {
        ApiProduct.createCountry(country_id, String(product_id));
    };

    const handlerDeselect = (country_id: string) => {
        ApiProduct.deleteCountry(country_id, String(product_id));
    };

    useEffect(() => {
        if(countries && selectedCountries.length > 0) {
            setSelected(
                countries
                    .filter((country) => selectedCountries.includes(String(country.id)))
                    .map((country) => ({ value: country.id, label: country.name}))
            )
        }
    }, [countries, selectedCountries]);

    return countries ? (
        <Form layout={'vertical'}>
            <Select options={countries.map((country) => ({ value: country.id, label: country.name }))}
                    value={selected}
                    handlerSelect={handlerSelect}
                    handlerDeselect={handlerDeselect}
                    handlerChange={setSelected}
                    multiple
            />
        </Form>
    ) : <></>;
}