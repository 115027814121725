import React, {useEffect, useState} from 'react';
import {useAppDispatch} from "../../../../store/store";
import {IOrderInfoForm} from "../../../../interfaces/order";
import {ORDER_INFO_FORM_FIELDS} from "../../constants";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/order/reducer";
import {ApiOrder} from "../../../../api/endpoints/order";
import {Button, Col, Form, notification, Row} from "antd";
import {Input} from "../../../components-base/Input/Input";

interface IOrdersFormCompanyProps {
    id: string;
    orderInfo: IOrderInfoForm
}

export const OrdersFormCompany: React.FC<IOrdersFormCompanyProps> = ({ id, orderInfo }) => {
    const dispatch = useAppDispatch();
    const [form, setForm] = useState<IOrderInfoForm>({});
    const [validateErrors, setValidateErrors] = useState<Record<ORDER_INFO_FORM_FIELDS, any>>({} as Record<ORDER_INFO_FORM_FIELDS, any>);

    const changeForm = (key: keyof IOrderInfoForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<ORDER_INFO_FORM_FIELDS, any> = {} as Record<ORDER_INFO_FORM_FIELDS, any>;

        if (!form[ORDER_INFO_FORM_FIELDS.COMPANY_NAME]) errors[ORDER_INFO_FORM_FIELDS.COMPANY_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[ORDER_INFO_FORM_FIELDS.COMPANY_CODE]) errors[ORDER_INFO_FORM_FIELDS.COMPANY_CODE] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[ORDER_INFO_FORM_FIELDS.COMPANY_ADDRESS]) errors[ORDER_INFO_FORM_FIELDS.COMPANY_ADDRESS] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        // if (!form[ORDER_INFO_FORM_FIELDS.COMPANY_PDV_CODE]) errors[ORDER_INFO_FORM_FIELDS.COMPANY_PDV_CODE] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            if (!id) return;
            dispatch(setLoading(true));
            const res = await ApiOrder.updateInfo(form, id);
            if (res) {
                notification.success({
                    message: ts('Зміни успішно збережені!'),
                });
            }
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        setForm({
            [ORDER_INFO_FORM_FIELDS.COMPANY_NAME]: orderInfo.company_name,
            [ORDER_INFO_FORM_FIELDS.COMPANY_CODE]: orderInfo.company_code,
            [ORDER_INFO_FORM_FIELDS.COMPANY_ADDRESS]: orderInfo.company_address,
            [ORDER_INFO_FORM_FIELDS.COMPANY_PDV_CODE]: orderInfo.company_pdv_code,
        });
    }, [orderInfo, id]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Row>
                <Col md={12}>
                    <Input value={form[ORDER_INFO_FORM_FIELDS.COMPANY_NAME]}
                           placeholder={ts('Назва')}
                           label={ts('Назва')}
                           handlerChange={(value: any) => changeForm(ORDER_INFO_FORM_FIELDS.COMPANY_NAME, value)}
                           errors={validateErrors?.[ORDER_INFO_FORM_FIELDS.COMPANY_NAME]}
                           required
                    />
                </Col>
                <Col md={12}>
                    <Input value={form[ORDER_INFO_FORM_FIELDS.COMPANY_CODE]}
                           placeholder={ts('Код')}
                           label={ts('Код')}
                           handlerChange={(value: any) => changeForm(ORDER_INFO_FORM_FIELDS.COMPANY_CODE, value)}
                           errors={validateErrors?.[ORDER_INFO_FORM_FIELDS.COMPANY_CODE]}
                           required
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Input value={form[ORDER_INFO_FORM_FIELDS.COMPANY_ADDRESS]}
                           placeholder={ts('Адреса')}
                           label={ts('Адреса')}
                           handlerChange={(value: any) => changeForm(ORDER_INFO_FORM_FIELDS.COMPANY_ADDRESS, value)}
                           errors={validateErrors?.[ORDER_INFO_FORM_FIELDS.COMPANY_ADDRESS]}
                           required
                    />
                </Col>
                <Col md={12}>
                    <Input value={form[ORDER_INFO_FORM_FIELDS.COMPANY_PDV_CODE]}
                           placeholder={ts('ПДВ код')}
                           label={ts('ПДВ код')}
                           handlerChange={(value: any) => changeForm(ORDER_INFO_FORM_FIELDS.COMPANY_PDV_CODE, value)}
                    />
                </Col>
            </Row>
            <Button type={'primary'} htmlType={'submit'}>{ts('Зберегти')}</Button>
        </Form>
    );
};
