import React, {useEffect, useState} from 'react';
import { Table as AntTable } from 'antd';
import {ColumnsType, SorterResult} from "antd/lib/table/interface";
import {TableProps as RcTableProps} from "rc-table/lib/Table";
import {IPaginationData, ISort} from "../../../interfaces/main";
import {scrollTop} from "../../../helpers/browser";
import {SORT_TYPES} from "../../../interfaces/api";

interface ITableInterface<RecordType> {
    columns: ColumnsType<RecordType> ;
    data: RcTableProps<RecordType>['data'];
    total: number;
    page: number;
    pageSize: number;
    sort: ISort | null;
    setPagination?: (pagination: IPaginationData) => void;
    setSort?: (sort: ISort) => void;
    loading?: boolean;
}

const SORTER_VALUES = {
    ascend: SORT_TYPES.ASC,
    descend: SORT_TYPES.DESC,
}

export const Table: React.FC<ITableInterface<any>> = (
    {
        columns,
        data,
        total,
        page,
        pageSize,
        sort,
        loading,
        setPagination,
        setSort,
    }) => {

    return (
        <AntTable columns={columns}
                  dataSource={data}
                  loading={loading}
                  rowClassName={(record) => record.enabled === false ? "ant-table-disabled-row" : ''}
                  pagination={{
                      current: page,
                      pageSize: pageSize,
                      onChange: (page, pageSize) => {
                          setPagination && setPagination({ page, pageSize });
                          scrollTop();
                      },
                      total: total,
                      pageSizeOptions: [10, 30, 50]

                  }}
                  onChange={(pagination, filters, sorter, extra) => {
                      const { columnKey, order } = sorter as { columnKey: string, order: 'ascend' | 'descend' };
                      if (!order && setSort) setSort(null);
                      setSort && setSort({ column: columnKey, type: SORTER_VALUES[order]});
                  }}
        />
    );
}