import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import {validateEmail} from "../../../../helpers/validators";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/company/reducer";
import {Button, Col, Divider, Form, notification, Row} from "antd";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {Input} from "../../../components-base/Input/Input";
import {Switch} from "../../../components-base/Switch/Switch";
import {ICompanyForm} from "../../../../interfaces/company";
import {COMPANY_FORM_FIELDS, COMPANY_INFO_FORM_FIELDS, INITIAL_COMPANY_FORM} from "../../constants";
import {ApiCompany} from "../../../../api/endpoints/company";
import {ATTRIBUTES_TYPES, DIRECTORIES, ICountry, ICurrency} from "../../../../interfaces/main";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {DirectoriesApi} from "../../../../api/endpoints/directory";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import {TextArea} from "../../../components-base/TextArea/TextArea";
import {ATTRIBUTE_FORM_FIELDS} from "../../../app-attribute/constants";
import {useSelector} from "react-redux";
import {selectCurrencies} from "../../../../store/reducers/common/selectors";

interface ICompanyFormProps {
    create?: boolean;
    id?: string | undefined;
    company?: ICompanyForm;
    readonly?: boolean;
}

export const CompanyForm: React.FC<ICompanyFormProps> = (
    {
        create,
        id,
        company,
        readonly = false,
    }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<ICompanyForm>(INITIAL_COMPANY_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<COMPANY_FORM_FIELDS, any>>({} as Record<COMPANY_FORM_FIELDS, any>);
    const currencies = useSelector(selectCurrencies);

    const changeForm = (key: keyof ICompanyForm, value: any) => setForm({...form, [key]: value });
    console.log(form);
    const validateForm = () => {
        const errors: Record<string, any> = {};
        const emailErrors = validateEmail(form[COMPANY_FORM_FIELDS.EMAIL]);
        const emailUSerErrors = validateEmail(form[COMPANY_FORM_FIELDS.USER_EMAIL]);

        if (!form[COMPANY_FORM_FIELDS.NAME]) errors[COMPANY_FORM_FIELDS.NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_FORM_FIELDS.DESCRIPTION]) errors[COMPANY_FORM_FIELDS.DESCRIPTION] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_FORM_FIELDS.COUNTRY]) errors[COMPANY_FORM_FIELDS.COUNTRY] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (emailErrors) errors[COMPANY_FORM_FIELDS.EMAIL] = emailErrors;

        if (create) {
            if (!form[COMPANY_FORM_FIELDS.COMPANY_NAME]) errors[COMPANY_FORM_FIELDS.COMPANY_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.IBAN]) errors[COMPANY_FORM_FIELDS.IBAN] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (form[COMPANY_FORM_FIELDS.IBAN]?.length !== 29) errors[COMPANY_FORM_FIELDS.IBAN] = { status: 'error', message: ts('IBAN повинен бути 29 символів. Зараз - ') + form[COMPANY_FORM_FIELDS.IBAN]?.length };
            if (!form[COMPANY_FORM_FIELDS.SURNAME_SIGNATURES]) errors[COMPANY_FORM_FIELDS.SURNAME_SIGNATURES] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.NAME_SIGNATURES]) errors[COMPANY_FORM_FIELDS.NAME_SIGNATURES] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.POSITION_PERSON]) errors[COMPANY_FORM_FIELDS.POSITION_PERSON] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.ADDRESS_CORRESPONDENCE]) errors[COMPANY_FORM_FIELDS.ADDRESS_CORRESPONDENCE] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.ADDRESS_REGISTER]) errors[COMPANY_FORM_FIELDS.ADDRESS_REGISTER] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.BANK_INFO]) errors[COMPANY_FORM_FIELDS.BANK_INFO] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.PHONE]) errors[COMPANY_FORM_FIELDS.PHONE] = { status: 'error', message: ts('Поле повинне бути заповненим') };

            if (!form[COMPANY_FORM_FIELDS.USER_NAME]) errors[COMPANY_FORM_FIELDS.USER_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.USER_SURNAME]) errors[COMPANY_FORM_FIELDS.USER_SURNAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[COMPANY_FORM_FIELDS.USER_MIDDLE_NAME]) errors[COMPANY_FORM_FIELDS.USER_MIDDLE_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };

            if (emailUSerErrors) errors[COMPANY_FORM_FIELDS.USER_EMAIL] = emailUSerErrors;
        }

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await handlerCreate() : await handlerUpdate();
            dispatch(setLoading(false));
        }
    };

    const handlerCreate = async () => {
        const res = await ApiCompany.create(form);
        if (res) {
            notification.success({
                message: ts('Компанія успішно створена!'),
            });
            navigate('/' + MAIN_APP_ROUTES.COMPANIES);
        }
    };

    const handlerUpdate = async () => {
        if (!id) return;
        const res = await ApiCompany.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (company) setForm(company);
    }, [company]);

    console.log(form);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Divider children={ts('Основна інформація')} orientation="left" orientationMargin="0"/>
            <Row>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Input value={form[COMPANY_FORM_FIELDS.NAME]}
                           placeholder={ts('Введіть назву')}
                           label={ts('Назва компанії')}
                           handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.NAME, value)}
                           errors={validateErrors?.[COMPANY_FORM_FIELDS.NAME]}
                           disabled={readonly}
                           required
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Autocomplete name={'country'}
                                  label={ts('Країна')}
                                  value={form[COMPANY_FORM_FIELDS.COUNTRY]}
                                  placeholder={ts('Оберіть країну')}
                                  optionsMapper={(data: ICountry) => ({ value: data.name, id: data.id })}
                                  items={DirectoriesController.getCountries()}
                                  disabled={readonly}
                                  errors={validateErrors?.[COMPANY_FORM_FIELDS.COUNTRY]}
                                  onChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.COUNTRY, value)}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Input value={form[COMPANY_FORM_FIELDS.EMAIL]}
                           placeholder={ts('Введіть e-mail')}
                           label={ts('E-mail')}
                           handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.EMAIL, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_FORM_FIELDS.EMAIL]}
                           required
                    />
                </Col>
            </Row>
            <TextArea value={form[COMPANY_FORM_FIELDS.DESCRIPTION]}
                      placeholder={ts('Введіть короткий опис')}
                      label={ts('Опис')}
                      disabled={readonly}
                      handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.DESCRIPTION, value)}
                      errors={validateErrors?.[COMPANY_FORM_FIELDS.DESCRIPTION]}
                      required
            />



            <Switch checked={form[COMPANY_FORM_FIELDS.ACTIVE]}
                    handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.ACTIVE, value)}
                    disabled={readonly}
                    label={ts('Активність')}
                    name={COMPANY_FORM_FIELDS.ACTIVE}
            />
            {create && (
                <>
                    <Divider children={ts('Юридична інформація')} orientation="left" orientationMargin="0"/>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <Input value={form[COMPANY_FORM_FIELDS.COMPANY_NAME]}
                                   placeholder={ts('Введіть назву')}
                                   label={ts('Назва компанії')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.COMPANY_NAME, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.COMPANY_NAME]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.IBAN]}
                                   placeholder={ts('Введіть IBAN')}
                                   label={ts('IBAN')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.IBAN, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.IBAN]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.BANK_INFO]}
                                   placeholder={ts('Введіть інформацію про банк')}
                                   label={ts('Інформація про банк')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.BANK_INFO, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.BANK_INFO]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.ADDRESS_REGISTER]}
                                   placeholder={ts('Введіть адресу реєстрації')}
                                   label={ts('Адреса реєстрації')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.ADDRESS_REGISTER, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.ADDRESS_REGISTER]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.ADDRESS_CORRESPONDENCE]}
                                   placeholder={ts('Введіть адресу для кореспонденції')}
                                   label={ts('Адресу для кореспонденції')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.ADDRESS_CORRESPONDENCE, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.ADDRESS_CORRESPONDENCE]}
                                   required
                            />
                            <Autocomplete label={ts('Валюта')}
                                          value={form[COMPANY_FORM_FIELDS.CURRENCY]}
                                          placeholder={ts('Оберіть валюту')}
                                          optionsMapper={(data: ICurrency) => ({ value: data.name, id: data.id })}
                                          items={currencies}
                                          errors={validateErrors?.[COMPANY_FORM_FIELDS.CURRENCY]}
                                          onChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.CURRENCY, value)}
                            />

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <Input value={form[COMPANY_FORM_FIELDS.PHONE]}
                                   placeholder={ts('Введіть телефон')}
                                   label={ts('Телефон')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.PHONE, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.PHONE]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.POSITION_PERSON]}
                                   placeholder={ts('Введіть ім\'я представника компанії')}
                                   label={ts('Ім\'я представника компанії')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.POSITION_PERSON, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.POSITION_PERSON]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.NAME_SIGNATURES]}
                                   placeholder={ts('Введіть ім\'я підписанта')}
                                   label={ts('Ім\'я підписанта')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.NAME_SIGNATURES, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.NAME_SIGNATURES]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.SURNAME_SIGNATURES]}
                                   placeholder={ts('Введіть прізвище підписанта')}
                                   label={ts('Прізвище підписанта')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.SURNAME_SIGNATURES, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.SURNAME_SIGNATURES]}
                                   required
                            />
                        </Col>
                    </Row>
                    <Divider children={ts('Дані користувача-адміністратора')} orientation="left" orientationMargin="0"/>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <Input value={form[COMPANY_FORM_FIELDS.USER_NAME]}
                                   placeholder={ts('Введіть ім\'я')}
                                   label={ts('Ім\'я')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.USER_NAME, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.USER_NAME]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.USER_SURNAME]}
                                   placeholder={ts('Введіть прізвище')}
                                   label={ts('Прізвище')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.USER_SURNAME, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.USER_SURNAME]}
                                   required
                            />
                            <Switch checked={form[COMPANY_FORM_FIELDS.SIGNATORY] || false}
                                    handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.SIGNATORY, value)}
                                    label={ts('Підписант')}
                                    name={COMPANY_FORM_FIELDS.SIGNATORY}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <Input value={form[COMPANY_FORM_FIELDS.USER_MIDDLE_NAME]}
                                   placeholder={ts('Введіть по-батькові')}
                                   label={ts('По-батькові')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.USER_MIDDLE_NAME, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.USER_MIDDLE_NAME]}
                                   required
                            />
                            <Input value={form[COMPANY_FORM_FIELDS.USER_EMAIL]}
                                   placeholder={ts('Введіть email')}
                                   label={ts('Email')}
                                   handlerChange={(value: any) => changeForm(COMPANY_FORM_FIELDS.USER_EMAIL, value)}
                                   errors={validateErrors?.[COMPANY_FORM_FIELDS.USER_EMAIL]}
                                   required
                            />
                        </Col>
                    </Row>
                </>
            )}
            {!readonly && (
                <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
            )}
        </Form>
    );
};