import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";

export const selectReducer = (state: RootState) => state[REDUCERS.CLIENT];
export const selectIsLoading = (state: RootState) => selectReducer(state).isLoading;
export const selectClients = (state: RootState) => selectReducer(state).ids;
export const selectClient = (state: RootState, id: number | null | undefined) => id ? selectReducer(state).idsData[id] : null;
export const selectClientsById = (state: RootState) => selectReducer(state).idsData;
export const selectClientsCount = (state: RootState) => selectReducer(state).dataCount;
export const selectClientsPage = (state: RootState) => selectReducer(state).page;
export const selectClientsPageSize = (state: RootState) => selectReducer(state).pageSize;
export const selectClientsSort = (state: RootState) => selectReducer(state).sort;
