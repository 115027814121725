import React, {useEffect, useState} from 'react';
import {Form} from "antd";
import {ApiCategory} from "../../../../api/endpoints/category";
import {ICategory} from "../../../../interfaces/category";
import {Select} from "../../../components-base/Select/Select";
import {ISelectItem} from "../../../../interfaces/main";
import {ApiProduct} from "../../../../api/endpoints/product";

interface IProductCategoryProps {
    product_id: number | string;
    selectedCategories: string[];
}

export const ProductCategory: React.FC<IProductCategoryProps> = ({ product_id, selectedCategories }) => {

    const [categories, setCategories] = useState<ICategory[] | null>(null);
    const [selected, setSelected] = useState<ISelectItem[]>([]);

    const handlerSelect = (category_id: string) => {
        ApiProduct.createCategory(category_id, String(product_id));
    };

    const handlerDeselect = (category_id: string) => {
        ApiProduct.deleteCategory(category_id, String(product_id));
    };

    useEffect(() => {
        ApiCategory.getAll({ filters: [], page: 1, pageSize: 9999}).then((res) => {
            setCategories(res?.data || []);
        });
    }, []);

    useEffect(() => {
        if(categories && selectedCategories.length > 0) {
            setSelected(
                categories
                    .filter((category) => selectedCategories.includes(String(category.id)))
                    .map((category) => ({ value: category.id, label: category.name}))
            )
        }
    }, [categories, selectedCategories]);

    return categories ? (
        <Form layout={'vertical'}>
            <Select options={categories.map((category) => ({ value: category.id, label: category.name }))}
                    value={selected}
                    handlerSelect={handlerSelect}
                    handlerDeselect={handlerDeselect}
                    handlerChange={setSelected}
                    multiple
            />
        </Form>
    ) : <></>;
}