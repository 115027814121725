import {Col, Row, Select} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import {ts} from "../../../../translations/helpers";
import {assignRole, getRoles, unAssignRole} from "../../../../api/endpoints/user";
import {IUserRole} from "../../../../interfaces/users";
import {Switch} from "../../../components-base/Switch/Switch";
import {useAppDispatch} from "../../../../store/store";

interface IUserRolesProps {
    userRoles: number[];
    userId: string;
}

export const UserRoles: React.FC<IUserRolesProps> = ({ userId, userRoles }) => {
    const [roles, setRoles] = useState<IUserRole[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

    const handlerChange = (id: number, active: boolean) => {
        if (active) {
            setSelectedRoles([...selectedRoles, id]);
            assignRole(userId, id);
        } else {
            setSelectedRoles(selectedRoles.filter((role) => role !== id));
            unAssignRole(userId, id);
        }
    };

    const roleSwitchers = useMemo(() => {
        return roles.map((role) => (
            <Row>
                <Switch checked={selectedRoles.includes(role.id)}
                        name={`${role.id}_${role.name}`}
                        label={ts(role.name)}
                        handlerChange={(active) => handlerChange(role.id, active)}
                        reverse
                />
            </Row>
        ));
    }, [roles, selectedRoles]);

    useEffect(() => {
        getRoles().then((res) => setRoles(res));
    }, []);

    useEffect(() => {
        setSelectedRoles(userRoles);
    }, [userRoles])

    return (
        <div className={'user-role-container'}>
            {roleSwitchers}
        </div>
    );
};