import {createAsyncThunk} from "@reduxjs/toolkit";
import {ACTION_TYPES} from "../product/actionTypes";
import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";
import {notification} from "antd";
import {ts} from "../../../translations/helpers";
import {ApiProduct, IUpdateProductParams} from "../../../api/endpoints/product";
import {FILTER_TYPES, IFilter} from "../../../interfaces/main";
import {getClients} from "../client/actions";

interface IEditFormParams {
    form: IUpdateProductParams;
    id: string;
}

export const loadProducts = createAsyncThunk(
    ACTION_TYPES.GET_PRODUCTS,
    async ({ ids = [] }: { ids?: number[] }, thunkApi) => {
        return thunkApi.dispatch(getProducts({
            filters: [
                {type: FILTER_TYPES.LIKE, column: 'ids', value: ids}
            ]
        }));
    }
);

export const getProducts = createAsyncThunk(
    ACTION_TYPES.GET_PRODUCTS,
    async ({ filters = [] }: { filters?: IFilter[]}, thunkApi) => {
        const state = (thunkApi.getState() as RootState)[REDUCERS.PRODUCT];
        const { page, pageSize, sort } = state;
        return await ApiProduct.getAll({ filters, page, pageSize, sort });
    }
);

export const editProduct = createAsyncThunk(
    ACTION_TYPES.EDIT_PRODUCT,
    async ({ form, id }: IEditFormParams, thunkApi) => {
        const res = await ApiProduct.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені'),
            });
            return res;
        }
    }
);

export const deleteProduct = createAsyncThunk(
    ACTION_TYPES.DELETE_PRODUCT,
    async (id: string, thunkApi) => {
        const res = await ApiProduct.delete(id);
        if (res) {
            notification.success({
                message: ts('Продукт успішно видалений'),
            });
            return id;
        }
    }
);
