import React, {useEffect, useState} from 'react';
import {Button, Form} from "antd";
import {CATEGORY_FORM_FIELDS} from "../../../app-category/constants";
import {ts} from "../../../../translations/helpers";
import {Input} from "../../../components-base/Input/Input";
import {DIRECTORIES, ICountry, IManufacturer, ITax} from "../../../../interfaces/main";
import {DirectoriesApi} from "../../../../api/endpoints/directory";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {useNavigate} from "react-router-dom";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";

interface IProps {
    isCreate?: boolean;
    directory: DIRECTORIES;
    handlerUpdate?: () => void;
    directoryItem?: ITax | IManufacturer | ICountry | null;
}

export const DirectoryValueForm: React.FC<IProps> = ({ isCreate, directory, directoryItem, handlerUpdate }) => {
    const [name, setName] = useState<string | null>(null);
    const [validateErrors, setValidateErrors] = useState<Record<string, any>>({} as Record<string, any>);
    const navigate = useNavigate();

    const onSubmit = () => {
        if(validateForm()) {
            isCreate ? create() : update();
        }
    };

    const create = () => name && DirectoriesApi.create(directory, name).then(() => {
        setName('');
        handlerUpdate && handlerUpdate();
    });

    const update = () => name && directoryItem && DirectoriesApi.update(directory, name, String(directoryItem.id)).then(() => {
        handlerUpdate && handlerUpdate();
        navigate(`/${MAIN_APP_ROUTES.DIRECTORIES}-${directory}`);
    });

    const validateForm = () => {
        const errors: Record<string, any> = {};
        if (!name) errors['name'] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    useEffect(() => {
        if(!isCreate && directoryItem && name === null) {
            setName(directoryItem.name);
        }
    }, [isCreate, directoryItem]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Input value={name || ''}
                   placeholder={ts('Введіть назву')}
                   label={ts('Назва елементу довідника')}
                   handlerChange={setName}
                   errors={validateErrors?.['name']}
                   required
            />
            <Button type={'primary'} htmlType={'submit'}>{isCreate ? ts('Створити') : ts('Зберегти')}</Button>
        </Form>
    );
}