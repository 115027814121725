import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {ORDER_FORM_FIELDS} from "../../constants";
import {Button, Col, Collapse, Dropdown, MenuProps, notification, Row, Space} from "antd";
import {IOrder, IOrderDoc, IOrderForm, IOrderInfoForm, IOrderProduct} from "../../../../interfaces/order";
import {ApiOrder} from "../../../../api/endpoints/order";
import {OrdersForm} from "../OrdersForm";
import {OrdersStatusSelector} from "../OrdersStatusSelector";
import {OrdersFomAddress} from "../OrdersForm/OrdersFomAddress";
import {OrdersFomClient} from "../OrdersForm/OrdersFormClient";
import {OrdersFormCompany} from "../OrdersForm/OrdersFormCompany";
import {OrdersFormProducts} from "../OrdersForm/OrdersFormProducts";
import {formatDate} from "../../../../helpers/formaters";
import {DollarCircleOutlined, FilePdfOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

export const OrdersUpdate = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [order, setOrder] = useState<IOrderForm>({} as IOrderForm);
    const [orderInfo, setOrderInfo] = useState<IOrderInfoForm>({} as IOrderInfoForm);
    const [orderData, setOrderData] = useState<Record<string, any>>({});
    const [orderProducts, setOrderProducts] = useState<IOrderProduct[]>([]);
    const [needUpdate, setNeedUpdate] = useState<boolean>(true);
    const documents: MenuProps['items'] = (orderData?.doc as IOrderDoc[])?.map((e) => {
        return {
            key: e.id,
            label: (
                <a href={e.link} target={'_blank'}>
                    <Space>
                        <FilePdfOutlined />
                        {formatDate(Number(e.created_at))}
                    </Space>
                </a>
            ),
        }
    }) || [];

    useEffect(() => {
        if (id && needUpdate) {
            ApiOrder.getOne(id)
                .then((order: IOrder) => {
                    if (order.id) {
                        setOrderData(order);
                        setOrder({
                            [ORDER_FORM_FIELDS.STATUS]: order.status,
                            [ORDER_FORM_FIELDS.PAYMENT]: order.payment,
                            [ORDER_FORM_FIELDS.CLIENT_ID]: order.client_id,
                            [ORDER_FORM_FIELDS.CITY]: order.city,
                            [ORDER_FORM_FIELDS.ADDRESS]: order.address,
                            [ORDER_FORM_FIELDS.CURRENCY]: order.currency_id,
                            [ORDER_FORM_FIELDS.AMOUNT]: order.amount,
                        });
                        order.orderInfo && setOrderInfo(order.orderInfo);
                        order.products && setOrderProducts(order.products);
                    }
                    setNeedUpdate(false);
                });
        }
    }, [id, needUpdate]);

    const triggerUpdate = () => { setNeedUpdate(true); };

    const handlerSendInvoice = (): void => {
        id && ApiOrder.sendInvoice(id).then((res) => {
            if (res) {
                notification.success({
                    message: ts('Успішно'),
                    description: ts('Інвойс був надісланий повторно'),
                });
            }
        });
    };

    return (
        <div className={'company-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Редагування замовлення')}/>
            <PageHeader
                title={ts('Редагування замовлення')}
                extra={(
                    <Space>
                        {orderData.card_status && <DollarCircleOutlined style={{ color: "green", fontSize: '30px' }}/>}
                        <OrdersStatusSelector status={order?.status || ''} order_id={id || ''} handlerUpdate={triggerUpdate} />
                        <Button type={'default'} onClick={handlerSendInvoice}>{ts('Надіслати інвойс')}</Button>
                        {orderData?.doc && orderData?.doc.length > 0 && (
                            <Dropdown menu={{
                                items: documents,
                            }}>
                                <Button type={'default'} htmlType={'submit'}>{ts('Файли/документи')}</Button>
                            </Dropdown>
                        )}
                    </Space>
                )}
            />
            <Collapse defaultActiveKey={[]}>
                <Panel header={ts('Основна інформація')} key="main">
                    <OrdersForm order={order} id={id}/>
                </Panel>
                <Panel header={ts('Адреса доставки')} key="address">
                    <OrdersFomAddress id={id || ''} address={order.address || ''} city={order.city || ''} />
                </Panel>
                <Panel header={ts('Дані клієнта')} key="client">
                    <OrdersFomClient id={id || ''} orderInfo={orderInfo} />
                </Panel>
                <Panel header={ts('Дані компанії')} key="company">
                    <OrdersFormCompany id={id || ''} orderInfo={orderInfo} />
                </Panel>
            </Collapse>
            <Row>
                <Col md={24}>
                    <OrdersFormProducts products={orderProducts}
                                        currency={order.currency_id || null}
                                        amount={order.amount || undefined}
                                        order_id={Number(id)}
                                        handlerUpdate={triggerUpdate}
                    />
                </Col>
            </Row>
            {/*<Debugger data={orderData}/>*/}
        </div>
    );
};
