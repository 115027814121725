export enum INPUT_TYPES {
    TEXT = 'text',
    PASSWORD = 'password',
    DATE = 'date',
    NUMBER = 'number',
}

export enum INPUT_STATUSES {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = '',
}

export interface IAutocompleteOption {
    value: any,
    id: any
}

export type ILangEditorValue = { lang: number; value: string};

export interface IUploadList {
    uid: number,
    name: string,
    status: string,
    url: string,
}
