import React, {ReactNode} from 'react';
import {Loader} from "../../../../components-base/Loader/Loader";

interface ILoadingProps {
    children?: ReactNode;
    isLoading: boolean;
    hide?: boolean;
}

export const Loading: React.FC<ILoadingProps> = ({ children, isLoading, hide }) => {
    return (
        <>
            {(hide && isLoading) ? '' : children}
            {isLoading && <Loader/>}
        </>
    );
};
