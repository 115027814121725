import {IPaginationData, ISort} from "../../../interfaces/main";
import {DEFAULT_PAGE_SIZE, REDUCERS} from "../../../constants";
import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {mapDataById} from "../../../helpers/data";
import {deleteClient, editClient, getClients} from "./actions";
import {IClient} from "../../../interfaces/client";

interface ClientState {
    isLoading: boolean;
    ids: string[];
    idsData: Record<string, IClient>;
    dataCount: number;
    page: number;
    pageSize: number;
    sort: ISort | null;
}

const initialState: ClientState = {
    isLoading: false,
    ids: [],
    idsData: {},
    dataCount: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: null,
};

export const clientSlice: Slice<ClientState> = createSlice({
    name: REDUCERS.CLIENT,
    initialState,
    reducers: {
        setPagination: (state, action: PayloadAction<IPaginationData>) => {
            const { page, pageSize } = action.payload;
            state.page = page;
            state.pageSize = pageSize;
        },
        setSort: (state, action: PayloadAction<ISort>) => {
            state.sort = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getClients.pending, (state, dispatch) => {
            state.isLoading = true;
        });
        builder.addCase(getClients.fulfilled,
            (state, { payload }) => {
                const { data, count, page, pageSize } = payload;
                state.ids = data.map((item) => String(item.id));
                state.idsData = { ...state.idsData, ...mapDataById(data)};
                state.dataCount = count;
                state.page = page;
                state.pageSize = pageSize;
                state.isLoading = false;
            });
        builder.addCase(editClient.fulfilled,
            (state, { payload }) => {
                if(payload?.id) {
                    state.idsData[payload.id] = payload;
                }
            });
        builder.addCase(deleteClient.fulfilled,
            (state, { payload }) => {
                if(payload) {
                    state.idsData[payload] = { ...state.idsData[payload], enabled: false } ;
                }
            });
    },
});

export const { setPagination, setSort, setLoading  } = clientSlice.actions;

export default clientSlice;