import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";

export const selectReducer = (state: RootState) => state[REDUCERS.CATEGORY];
export const selectIsLoading = (state: RootState) => selectReducer(state).isLoading;
export const selectCategories = (state: RootState) => selectReducer(state).ids;
export const selectCategoriesById = (state: RootState) => selectReducer(state).idsData;
export const selectCategoriesCount = (state: RootState) => selectReducer(state).dataCount;
export const selectCategoriesPage = (state: RootState) => selectReducer(state).page;
export const selectCategoriesPageSize = (state: RootState) => selectReducer(state).pageSize;
export const selectCategoriesSort = (state: RootState) => selectReducer(state).sort;