import React, {useEffect, useState} from 'react';
import {Form, Input as AntInput} from "antd";
import draftToHtml from 'draftjs-to-html';
import {INPUT_STATUSES, INPUT_TYPES} from "../../../interfaces/form";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Draft, {convertToRaw, EditorState, ContentState} from "draft-js";
import './TextEditor.scss';
import { stateFromHTML } from 'draft-js-import-html';

const AntTextArea = AntInput.TextArea;

interface IInputProps {
    value: any;
    handlerChange: (value: any) => void;
    handlerBlur?: (value: any) => void;
    label?: string;
    disabled?: boolean;
    prefix?: any;
    rows?: number
    placeholder?: string;
    fullWidth?: boolean;
    errors?: { status: INPUT_STATUSES, message: string }
    defaultValue?: any;
    required?: boolean;
}

export const TextEditor: React.FC<IInputProps> = (
    {
        value,
        handlerChange,
        handlerBlur,
        prefix,
        placeholder,
        fullWidth,
        required = false,
        errors = { status: INPUT_STATUSES.SUCCESS, message: '' },
        rows = 4,
        label,
        disabled = false,
        defaultValue
    }) => {
    const { status, message } = errors;
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );
    const [blockUpdate, setBlockUpdate] = useState<boolean>(false);
    const handleChange = (content: EditorState) => {
        setBlockUpdate(true);
        handlerChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        setEditorState(content);
    };

    useEffect(() => {
        if(value && !editorState.getCurrentContent().hasText() && !blockUpdate) {
            setEditorState(() => {
                return EditorState.createWithContent(stateFromHTML(value))
            })
        }
    }, [value]);

    return (
        <Form.Item
            label={label}
            className={required ? 'is-required' : ''}
            validateStatus={status}
            help={status && message}
        >
            <Editor
                editorState={editorState}
                wrapperClassName="text-editor-wrapper"
                onEditorStateChange={handleChange}
                onBlur={() => handlerBlur && handlerBlur(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
            />
            <AntTextArea prefix={prefix}
                         placeholder={placeholder}
                         onChange={(event) => handlerChange(event.target.value)}
                         onBlur={(event) => !(handlerBlur) || handlerBlur(event.target.value)}
                         value={value}
                         status={status}
                         rows={rows}
                         style={{ maxWidth: fullWidth ? '900px' : '300px' }}
                         disabled={disabled}
                         defaultValue={defaultValue}
                         className={'ant-text-area-hidden'}
            />
        </Form.Item>
    );
};
