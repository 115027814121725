import {getAuth} from "../../../../api/endpoints/auth";
import {IUser, IUserCompany} from "../../../../interfaces/main";

class AuthManager {
    public isAuth: boolean;
    private user: IUser | null;
    private company: IUserCompany | null;
    private permission: string[];

    constructor() {
        this.isAuth = false;
        this.user = null;
        this.company = null;
        this.permission = [];
    }

    public async getAuth(): Promise<boolean> {
        const auth = await getAuth();

        if (!auth?.id) return false;

        const { id, name, surname, middle_name, email, signatory, company, permission } = auth;

        this.user = {
            id,
            name,
            surname,
            middle_name: middle_name || '',
            email,
            signatory,
            role: []
        };
        this.company = company;
        this.permission = permission;
        this.isAuth = true;

        return true;
    }

    public getFullName(): string {
        return this.user ? `${this.user.surname} ${this.user.name} ${this.user?.middle_name}` : '';
    }

    public getAccess(key: string): boolean {
        return this.permission.includes(key);
    }

    public getCurrency(): string | undefined {
        return this.company?.currency_id;
    }

    public getCompanyId(): number | undefined {
        return this.company?.id;
    }
}

const authManager = new AuthManager();

export default authManager;