import {Dropdown, MenuProps, notification, Space, Tag} from 'antd';
import React from 'react';
import {ts} from "../../../../translations/helpers";
import {ORDERS_STATUSES} from "../../constants";
import {ApiOrder} from "../../../../api/endpoints/order";

interface IOrdersStatusSelectorProps {
    status: string;
    order_id: string;
    handlerUpdate?: () => void;
}

export const OrdersStatusSelector: React.FC<IOrdersStatusSelectorProps> = ({ status, order_id, handlerUpdate }) => {

    const items: MenuProps['items'] = ORDERS_STATUSES.filter((e) => e?.key !== status).map((e) => {
        return {
            key: e.key,
            label: (
                <Tag color={e.color}>{ts(e.key)}</Tag>
            ),
        }
    });
    const currentStatus = ORDERS_STATUSES.find((e) => e?.key === status);

    const handleClick: MenuProps['onClick'] = async ({ key }) => {
        const res = await ApiOrder.updateStatus({ status: key }, order_id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
            handlerUpdate && handlerUpdate();
        }
    };

    return (
        <Space align={'center'}>
            <span>
                {ts('Статус замовлення')}:
            </span>
            <Dropdown menu={{
                items,
                onClick: handleClick
            }} placement="bottomLeft">
                {currentStatus ? (
                    <Tag color={currentStatus?.color}>{ts(currentStatus?.key)}</Tag>
                ) : <></>}
            </Dropdown>
        </Space>
    );
};
