import {MAIN_APP_ROUTES} from "../app-main/constants";
import {IProductForm} from "../../interfaces/product";

export const getRoute = (route: string): string => `/${MAIN_APP_ROUTES.PRODUCTS_ALL}/${route}`;

export enum PRODUCTS_ROUTES {
    CREATE = 'create',
    EDIT = ':id',
}

export enum PRODUCT_FORM_FIELDS {
    NAME = 'name',
    MODEL = 'model',
    ALIAS = 'alias',
    PRODUCER = 'producer',
    CARD_NAME = 'card_name',
    DESCRIPTION = 'description',
    PRICE = 'price',
    SEO = 'seo',
    ATTRIBUTES = 'attributes',
    TAX = 'tax',
    DISCOUNT = 'discount',
    IMAGE = 'image',
    DOCUMENT = 'doc',
    PRE_ORDER = 'preorder',
    IS_NEW = 'isNew',
    LANG = 'lang',
    ACTIVE = 'active',
}
export const INITIAL_PRODUCT_FORM: IProductForm = {
    [PRODUCT_FORM_FIELDS.NAME]: '',
    [PRODUCT_FORM_FIELDS.MODEL]: '',
    [PRODUCT_FORM_FIELDS.PRODUCER]: '',
    [PRODUCT_FORM_FIELDS.DESCRIPTION]: '',
    [PRODUCT_FORM_FIELDS.CARD_NAME]: '',
    [PRODUCT_FORM_FIELDS.ALIAS]: '',
    [PRODUCT_FORM_FIELDS.PRICE]: '',
    [PRODUCT_FORM_FIELDS.PRE_ORDER]: false,
    [PRODUCT_FORM_FIELDS.IS_NEW]: false,
    [PRODUCT_FORM_FIELDS.LANG]: [],
    [PRODUCT_FORM_FIELDS.ACTIVE]: 1,
};
