import {MAIN_APP_ROUTES} from "../app-main/constants";
import {IClientForm} from "../../interfaces/client";

export const getRoute = (route: string): string => `/${MAIN_APP_ROUTES.CLIENTS}/${route}`;

export enum CLIENTS_ROUTES {
    CREATE = 'create',
    EDIT = ':id',
}

export enum CLIENT_FORM_FIELDS {
    NAME = 'name',
    SURNAME = 'surname',
    MIDDLE_NAME = 'middle_name',
    PHONE = 'phone',
    EMAIL = 'email',
    ACTIVE = 'active',
    COMPANY_NAME = 'company_name',
    COMPANY_CODE = 'company_code',
    COMPANY_PDV_CODE = 'company_pdv_code',
    COMPANY_ADDRESS = 'company_address',
    IS_COMPANY = 'isCompany',
}
export const INITIAL_CLIENT_FORM: IClientForm = {
    [CLIENT_FORM_FIELDS.NAME]: '',
    [CLIENT_FORM_FIELDS.SURNAME]: '',
    [CLIENT_FORM_FIELDS.MIDDLE_NAME]: '',
    [CLIENT_FORM_FIELDS.PHONE]: '',
    [CLIENT_FORM_FIELDS.EMAIL]: '',
    [CLIENT_FORM_FIELDS.COMPANY_NAME]: '',
    [CLIENT_FORM_FIELDS.COMPANY_CODE]: '',
    [CLIENT_FORM_FIELDS.COMPANY_PDV_CODE]: '',
    [CLIENT_FORM_FIELDS.COMPANY_ADDRESS]: '',
    [CLIENT_FORM_FIELDS.IS_COMPANY]: false,
    [CLIENT_FORM_FIELDS.ACTIVE]: false,
};
