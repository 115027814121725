import {DIRECTORIES, ICountry, IManufacturer, ITax} from "../../../../interfaces/main";
import {DirectoriesApi} from "../../../../api/endpoints/directory";
import {ts} from "../../../../translations/helpers";

class DirectoriesController {
    public countries: ICountry[] | undefined;
    public taxes: ITax[] | undefined;
    public manufacturers: IManufacturer[] | undefined;

    public loadDirectory = async (type: DIRECTORIES): Promise<any> => {
        switch (type) {
            case DIRECTORIES.COUNTRY:
                this.countries = await DirectoriesApi.getCountries();
                return this.countries;
            case DIRECTORIES.TAX:
                this.taxes = await DirectoriesApi.getTaxes();
                return this.taxes;
            case DIRECTORIES.MANUFACTURER:
                this.manufacturers = await DirectoriesApi.getManufacturers();
                return this.manufacturers;
            default:
                return ;

        }

    };

    public getDirectoryName = (directory: DIRECTORIES) => {
        switch (directory) {
            case DIRECTORIES.COUNTRY:
                return ts('Країни');
            case DIRECTORIES.TAX:
                return ts('Податки');
            case DIRECTORIES.MANUFACTURER:
                return ts('Виробники');
            default:
                return '';
        }
    };

    public loadCountries = async () => await this.loadDirectory(DIRECTORIES.COUNTRY);

    public loadTaxes = async () => await this.loadDirectory(DIRECTORIES.TAX);

    public loadManufacturers = async () => await this.loadDirectory(DIRECTORIES.MANUFACTURER);

    public getCountries = (): ICountry[] => this.countries || [];

    public getTaxes = (): ITax[] => this.taxes || [];

    public getManufacturers = (): IManufacturer[] => this.manufacturers || [];

    public getCountry = (id: number) => {
        return this.countries?.find((country: ICountry) => country.id === id);
    };

    public getCountryName = (id: number): string | undefined => {
        return this.countries?.find((country: ICountry) => Number(country.id) === Number(id))?.name;
    }
}

const directories = new DirectoriesController();

export { directories as DirectoriesController };