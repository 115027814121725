import React, {ReactNode, useEffect, useState} from 'react';
import {APP_ITEMS} from "../Menu/Menu";
import {Route, Routes} from "react-router-dom";
import {DashboardApp} from "../../../app-dashboard/DashboardApp";
import {IMenuItem} from "../../../../interfaces/main";
import authManager from "../AuthManager/AuthManager";

export const Router: React.FC = () => {

    const getAllRoutes = (items: IMenuItem[]): ReactNode[] => {

        let allRoutes: ReactNode[] = [];

        items.forEach((item) => {
            if(authManager.getAccess(item.key)) {
                if (item.element) {
                    allRoutes.push(<Route key={item.key} path={item.key + '/*'} element={item.element} />);
                }
                if (item.children) {
                    allRoutes = [...allRoutes, ...getAllRoutes(item.children)];
                }
            }
        });

        return allRoutes;
    };

    const allRoutes = getAllRoutes(APP_ITEMS);

    return (
        <Routes>
            <Route path={'/'}>
                {allRoutes}
                <Route path={'/'} element={<DashboardApp />} />
            </Route>
        </Routes>
    );
};
// element={<DashboardApp/>