import React from 'react';
import {Form, Input as AntInput} from "antd";
import {ts} from "../../../translations/helpers";
import {INPUT_STATUSES, INPUT_TYPES} from "../../../interfaces/form";

interface IInputProps {
    value: any;
    handlerChange: (value: any) => void;
    handlerBlur?: (value: any) => void;
    label?: string;
    disabled?: boolean;
    prefix?: any;
    type?: INPUT_TYPES;
    placeholder?: string;
    errors?: { status: INPUT_STATUSES, message: string }
    defaultValue?: any;
    required?: boolean;
}

export const Input: React.FC<IInputProps> = (
    {
        value,
        handlerChange,
        handlerBlur,
        prefix,
        placeholder,
        required = false,
        errors = { status: INPUT_STATUSES.SUCCESS, message: '' },
        type= INPUT_TYPES.TEXT,
        label,
        disabled = false,
        defaultValue
    }) => {
    const { status, message } = errors;
    return (
        <Form.Item
            label={label}
            className={required ? 'is-required' : ''}
            validateStatus={status}
            help={status && message}
        >
            <AntInput prefix={prefix}
                      placeholder={placeholder}
                      onChange={(event) => handlerChange(event.target.value)}
                      onBlur={(event) => !(handlerBlur) || handlerBlur(event.target.value)}
                      value={value}
                      status={status}
                      style={{ maxWidth: '300px' }}
                      disabled={disabled}
                      defaultValue={defaultValue}
                      type={type}
            />
        </Form.Item>
    );
};
