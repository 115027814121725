import {ApiBase} from "../ApiBase";
import {API_METHODS, ENDPOINTS} from "../constants";
import {ORDER_FORM_FIELDS} from "../../components/app-orders/constants";
import {IOrder, IOrderForm, IOrderInfoForm} from "../../interfaces/order";
import {ICompany, ICompanyInfoForm} from "../../interfaces/company";
import {apiRequest} from "../instance";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {notification} from "antd";
import {ts} from "../../translations/helpers";

export interface IUpdateOrderParams {
    [ORDER_FORM_FIELDS.STATUS]?: string | null;
    [ORDER_FORM_FIELDS.PAYMENT]?: string | null;
    [ORDER_FORM_FIELDS.ADDRESS]?: string | null;
    [ORDER_FORM_FIELDS.CITY]?: string | null;
}

class ApiOrder extends ApiBase<IUpdateOrderParams, IOrderForm, IOrder >{
    public endpoint = ENDPOINTS.ORDER;

    public updateInfo = async (form: IOrderInfoForm, id: string): Promise<ICompany> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/info`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
    };

    public updateStatus = async (form: { status: string }, id: string): Promise<ICompany> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/status`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
    };

    public addProduct = async (
        form: { product_id: string, count: number, price: number },
        id: string,
    ): Promise<ICompany> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/product`,
            form,
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : notification.error({
                message: ts('Помилка'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteProduct = async (product_id: string, id: string): Promise<boolean | void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/product`,
            { order_product_id: product_id },
            API_METHODS.DELETE
        );

        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? true
            : notification.error({
                message: ts('Помилка при видалені'),
                description: JSON.stringify(res.message),
            });
    };

    public changeProduct = async (
        form: { order_product_id: string, count: number, price: number, product_id: string },
        id: string,
    ): Promise<ICompany> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/products`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : notification.error({
                message: ts('Помилка'),
                description: JSON.stringify(res.message),
            });
    };

    public addProductLot = async (
        form: { order_product_id: number, count: number, lot_id: number },
        id: string,
    ): Promise<ICompany> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/product-lot`,
            form,
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : notification.error({
                message: ts('Помилка'),
                description: JSON.stringify(res.message),
            });
    };

    public sendInvoice = async (
        id: string,
    ): Promise<boolean | void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/invoice`,
            {},
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? true
            : notification.error({
                message: ts('Помилка'),
                description: JSON.stringify(res.message),
            });
    };

    public changeProductLot = async (
        form: { order_product_lot_id: number, count: number, lot_id: number },
        id: string,
    ): Promise<ICompany> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/product-lot`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : notification.error({
                message: ts('Помилка'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteProductLot = async (order_product_lot_id: string, id: string): Promise<boolean | void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${id}/product-lot`,
            { order_product_lot_id: order_product_lot_id },
            API_METHODS.DELETE
        );

        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? true
            : notification.error({
                message: ts('Помилка при видалені'),
                description: JSON.stringify(res.message),
            });
    };

    /// Add product         |||   POST          |||    /v1/order/:order_id/product       |||   { product_id, count, price }
    /// Delete product      |||   DELETE        |||    /v1/order/:order_id/product       |||   { order_product_id }
    /// Change product      |||   PUT           |||    /v1/order/:order_id/products      |||   { order_product_id, product_id, count, price }

    /// Add product lot     |||   POST          |||    /v1/order/:order_id/product-lot   |||   { order_product_id, count, lot_id }
    /// Change product lot  |||   PUT           |||    /v1/order/:order_id/product-lot   |||   { order_product_lot_id, count, lot_id }
    /// Delete product lot  |||   DELETE        |||    /v1/order/:order_id/product-lot   |||   { order_product_lot_id }
    /// Get product lots    |||   POST          |||    /v1/warehouse/lot/:product_id     |||   { }

}

const apiOrder = new ApiOrder();

export { apiOrder as ApiOrder };
