import {API_RESPONSE_CODE} from "../../interfaces/api";
import {API_METHODS, ENDPOINTS} from "../constants";
import {apiRequest} from "../instance";
import {notification} from "antd";
import {ts} from "../../translations/helpers";
import {IMainSliderItem, IProductSliderItem} from "../../interfaces/slider";
import {FILTER_TYPES} from "../../interfaces/main";

class ApiSlider {
    public endpoint = ENDPOINTS.SLIDER;

    public getHomePage = async (country_id: number): Promise<IMainSliderItem[]> => {
        const res = await apiRequest(
            `/${this.endpoint}/main`,
            { filters: [ { column: 'country', type: FILTER_TYPES.EQUAL, value: country_id } ] },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data?.data
            ? res?.data.data
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createImageHomePage = async (file_id: string, sort: number, country_id: number, link: string, lang: number): Promise<void> => {
        const res = await apiRequest(
            `/${this.endpoint}/main/create`,
            { file_id, sort, country_id, link, lang },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Елемнт слайдеру успішно створений!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteImageHomePage = async (id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.endpoint}/main/${id}`,
            {},
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createProductRelatedSlider = async (product_id: string, sort: number, country_id: number): Promise<void> => {
        const res = await apiRequest(
            `/${this.endpoint}/product/create`,
            { product_id, sort, country_id },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Елемнт слайдеру успішно створений!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public getRelatedProducts = async (country_id: number): Promise<IProductSliderItem[]> => {
        const res = await apiRequest(
            `/${this.endpoint}/product`,
            { filters: [ { column: 'country', type: FILTER_TYPES.EQUAL, value: country_id } ] },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data?.data
            ? res?.data.data
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteRelatedProductsItem = async (id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.endpoint}/product/${id}`,
            {},
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public updateRelatedProductsItem = async (id: string, form: Record<string, any>): Promise<void> => {
        const res = await apiRequest(
            `/${this.endpoint}/product/${id}`,
            {...form},
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public updateImageHomePage = async (id: string, form: Record<string, any>): Promise<void> => {
        const res = await apiRequest(
            `/${this.endpoint}/main/${id}`,
            {...form},
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

}

const apiSlider = new ApiSlider();

export { apiSlider as ApiSlider };
