import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoading} from "../../store/reducers/client/selectors";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {Route, Routes} from "react-router-dom";
import {ClientTable} from "./components/ClientTable";
import {CLIENTS_ROUTES} from "./constants";
import {ClientCreate} from "./components/ClientCreate";
import {ClientUpdate} from "./components/ClientUpdate";

export const ClientApp: React.FC = () => {
    const isLoading = useSelector(selectIsLoading);

    return (
        <div className={'company-container'}>
            <Loading isLoading={isLoading}>
                <Routes>
                    <Route path={CLIENTS_ROUTES.CREATE} element={<ClientCreate/>}/>
                    <Route path={CLIENTS_ROUTES.EDIT} element={<ClientUpdate/>}/>
                    <Route path={'/'} element={<ClientTable/>}/>
                </Routes>
            </Loading>
        </div>
    );
}