import { createAsyncThunk } from "@reduxjs/toolkit";
import {ACTION_TYPES} from "./actionTypes";
import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";
import {notification} from "antd";
import {ts} from "../../../translations/helpers";
import {ApiCategory, IUpdateCategoryParams} from "../../../api/endpoints/category";

interface IEditFormParams {
    form: IUpdateCategoryParams;
    id: string;
}

export const getCategories = createAsyncThunk(
    ACTION_TYPES.GET_CATEGORIES,
    async ({}: {}, thunkApi) => {
        const state = (thunkApi.getState() as RootState)[REDUCERS.CATEGORY];
        const { page, pageSize, sort } = state;
        return await ApiCategory.getAll({ page, pageSize, sort });
    }
);

export const editCategory = createAsyncThunk(
    ACTION_TYPES.EDIT_CATEGORY,
    async ({ form, id }: IEditFormParams, thunkApi) => {
        const res = await ApiCategory.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені'),
            });
            return res;
        }
    }
);

export const deleteCategory = createAsyncThunk(
    ACTION_TYPES.DELETE_CATEGORY,
    async (id: string, thunkApi) => {
        const res = await ApiCategory.delete(id);
        if (res) {
            notification.success({
                message: ts('Категорія успішно видалена'),
            });
            return id;
        }
    }
);