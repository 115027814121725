import React from 'react';
import {Descriptions} from "antd";

interface IOrdersInfoCommentProps {
    comment: string;
}

export const OrdersInfoComment: React.FC<IOrdersInfoCommentProps> = ({ comment }) => {
    return (
        <Descriptions>
            <Descriptions.Item>
                {comment}
            </Descriptions.Item>
        </Descriptions>
    );
}
