import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoading} from "../../store/reducers/category/selectors";
import {Route, Routes} from "react-router-dom";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {CategoryTable} from "./components/CategoryTable";
import {CATEGORIES_ROUTES} from "./constants";
import {CategoryCreate} from "./components/CategoryCreate";
import {CategoryUpdate} from "./components/CategoryUpdate";

export const CategoryApp: React.FC = () => {
    const isLoading = useSelector(selectIsLoading);

    return (
        <div className={'company-container'}>
            <Loading isLoading={isLoading}>
                <Routes>
                    <Route path={CATEGORIES_ROUTES.CREATE} element={<CategoryCreate/>}/>
                    <Route path={CATEGORIES_ROUTES.EDIT} element={<CategoryUpdate/>}/>
                    <Route path={'/'} element={<CategoryTable/>}/>
                </Routes>
            </Loading>
        </div>
    );
}