import React, {useEffect, useState} from "react";
import {RootState, useAppDispatch} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/product/reducer";
import {ApiProduct} from "../../../../api/endpoints/product";
import {Button, Form, notification} from "antd";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {Input} from "../../../components-base/Input/Input";
import {Switch} from "../../../components-base/Switch/Switch";
import {IProductForm} from "../../../../interfaces/product";
import {INITIAL_PRODUCT_FORM, PRODUCT_FORM_FIELDS} from "../../constants";
import {INPUT_TYPES} from "../../../../interfaces/form";
import {TextArea} from "../../../components-base/TextArea/TextArea";
import {Select} from "../../../components-base/Select/Select";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import {transliteration} from "../../../../helpers/data";
import {useSelector} from "react-redux";
import {selectCurrencyName} from "../../../../store/reducers/common/selectors";
import authManager from "../../../app-main/components/AuthManager/AuthManager";
import {TextEditor} from "../../../components-base/TextEditor/TextEditor";

interface IProductFormProps {
    create?: boolean;
    id?: string | undefined;
    product?: IProductForm;
}

export const ProductForm: React.FC<IProductFormProps> = ({ create, id, product }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<IProductForm>(INITIAL_PRODUCT_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<PRODUCT_FORM_FIELDS, any>>({} as Record<PRODUCT_FORM_FIELDS, any>);
    const companyCurrency = useSelector((state: RootState) => selectCurrencyName(state, authManager.getCurrency() || ''));

    const changeForm = (key: keyof IProductForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<string, any> = {};

        if(create) {
            if (!form[PRODUCT_FORM_FIELDS.NAME]) errors[PRODUCT_FORM_FIELDS.NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[PRODUCT_FORM_FIELDS.CARD_NAME]) errors[PRODUCT_FORM_FIELDS.CARD_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        }

        if (!form[PRODUCT_FORM_FIELDS.ALIAS]) errors[PRODUCT_FORM_FIELDS.ALIAS] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[PRODUCT_FORM_FIELDS.MODEL]) errors[PRODUCT_FORM_FIELDS.MODEL] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[PRODUCT_FORM_FIELDS.PRODUCER]) errors[PRODUCT_FORM_FIELDS.PRODUCER] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[PRODUCT_FORM_FIELDS.PRICE]) errors[PRODUCT_FORM_FIELDS.PRICE] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (Number(form[PRODUCT_FORM_FIELDS.PRICE]) > 999999999) errors[PRODUCT_FORM_FIELDS.PRICE] = { status: 'error', message: ts('Перевищено максимально допустиме число') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await handlerCreate() : await handlerUpdate();
            dispatch(setLoading(false));
        }
    };

    const handlerCreate = async () => {
        const res = await ApiProduct.create(form);
        if (res) {
            notification.success({
                message: ts('Товар успішно створений!'),
            });
            navigate('/' + MAIN_APP_ROUTES.PRODUCTS_ALL);
        }
    };

    const handlerUpdate = async () => {
        if (!id) return;
        const res = await ApiProduct.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (product) setForm(product);
    }, [product]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            {create && (
                <>
                    <Input value={form[PRODUCT_FORM_FIELDS.NAME]}
                           placeholder={ts('Введіть назву')}
                           label={ts('Назва товару')}
                           handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.NAME, value)}
                           handlerBlur={(value: any) => changeForm(PRODUCT_FORM_FIELDS.ALIAS, transliteration(value))}
                           errors={validateErrors?.[PRODUCT_FORM_FIELDS.NAME]}
                           required
                    />
                    <Input value={form[PRODUCT_FORM_FIELDS.CARD_NAME]}
                           placeholder={ts('Введіть коротку назву')}
                           label={ts('Коротку назва')}
                           handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.CARD_NAME, value)}
                           errors={validateErrors?.[PRODUCT_FORM_FIELDS.CARD_NAME]}
                           required
                    />
                    {/*<TextArea value={form[PRODUCT_FORM_FIELDS.DESCRIPTION]}*/}
                    {/*          placeholder={ts('Введіть короткий опис')}*/}
                    {/*          label={ts('Опис')}*/}
                    {/*          handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.DESCRIPTION, value)}*/}
                    {/*          errors={validateErrors?.[PRODUCT_FORM_FIELDS.DESCRIPTION]}*/}
                    {/*          required*/}
                    {/*/>*/}
                    <TextEditor value={form[PRODUCT_FORM_FIELDS.DESCRIPTION]}
                                placeholder={ts('Введіть короткий опис')}
                                label={ts('Опис')}
                                handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.DESCRIPTION, value)}
                                errors={validateErrors?.[PRODUCT_FORM_FIELDS.DESCRIPTION]}
                                required
                    />
                </>
            )}
            <Input value={form[PRODUCT_FORM_FIELDS.ALIAS]}
                   placeholder={ts('Введіть аліас')}
                   label={ts('Аліас')}
                   handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.ALIAS, value)}
                   errors={validateErrors?.[PRODUCT_FORM_FIELDS.ALIAS]}
                   required
            />
            <Input value={form[PRODUCT_FORM_FIELDS.MODEL]}
                   placeholder={ts('Введіть модель')}
                   label={ts('Модель')}
                   handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.MODEL, value)}
                   errors={validateErrors?.[PRODUCT_FORM_FIELDS.MODEL]}
                   required
            />
            <Select options={DirectoriesController.getManufacturers().map((manufacturer) => ({ value: manufacturer.id, label: manufacturer.name }))}
                    value={form[PRODUCT_FORM_FIELDS.PRODUCER]}
                    label={ts('Виробник')}
                    handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.PRODUCER, value)}
                    errors={validateErrors?.[PRODUCT_FORM_FIELDS.PRODUCER]}
            />
            <Input value={form[PRODUCT_FORM_FIELDS.PRICE]}
                   placeholder={`${ts('Введіть ціну')} ${companyCurrency}`}
                   label={`${ts('Ціна')} ${companyCurrency}`}
                   handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.PRICE, value)}
                   type={INPUT_TYPES.NUMBER}
                   errors={validateErrors?.[PRODUCT_FORM_FIELDS.PRICE]}
                   required
            />
            <Switch checked={Boolean(Number(form[PRODUCT_FORM_FIELDS.ACTIVE]))}
                    handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.ACTIVE, value)}
                    label={ts('Активність')}
                    name={PRODUCT_FORM_FIELDS.ACTIVE}
            />
            <Switch checked={Boolean(Number(form[PRODUCT_FORM_FIELDS.PRE_ORDER]))}
                    handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.PRE_ORDER, value)}
                    label={ts('Доступний на передзамовлення')}
                    name={PRODUCT_FORM_FIELDS.PRE_ORDER}
            />
            <Switch checked={Boolean(Number(form[PRODUCT_FORM_FIELDS.IS_NEW]))}
                    handlerChange={(value: any) => changeForm(PRODUCT_FORM_FIELDS.IS_NEW, value)}
                    label={ts('Новинка')}
                    name={PRODUCT_FORM_FIELDS.IS_NEW}
            />
            <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
        </Form>
    );
};
