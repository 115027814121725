import {apiRequest} from "../instance";
import {ENDPOINTS} from "../constants";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {ILang} from "../../interfaces/main";

export const getLanguages = async (): Promise<ILang[]> => {
    const res = await apiRequest(`/${ENDPOINTS.LANG}`);
    return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data?.data
        ? res.data.data
        : [];
};


