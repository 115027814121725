import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {ua} from "./source/ua";
import {en} from "./source/en";

i18n.use(LanguageDetector).init({
    resources: {
        ua: { translations: ua },
        en: { translations: en },
    },
    fallbackLng: "ua",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        formatSeparator: ","
    },
    react: {
        wait: true
    }
});

export default i18n;