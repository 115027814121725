import {ApiBase} from "../ApiBase";
import {API_METHODS, ENDPOINTS} from "../constants";
import {TAG_FORM_FIELDS} from "../../components/app-tag/constants";
import {ITag, ITagForm} from "../../interfaces/tag";
import {apiRequest} from "../instance";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {notification} from "antd";
import {ts} from "../../translations/helpers";

export interface IUpdateTagParams {
    [TAG_FORM_FIELDS.NAME]?: string;
    [TAG_FORM_FIELDS.ALIAS]?: string;
    [TAG_FORM_FIELDS.ACTIVE]?: boolean;
}

class ApiTag extends ApiBase<IUpdateTagParams, ITagForm, ITag >{
    public endpoint = ENDPOINTS.TAG;

    public updateLang = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/lang/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };
}

const apiTag = new ApiTag();

export { apiTag as ApiTag };