import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/order/reducer";
import {Button, Form, notification, Row, Col} from "antd";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {Input} from "../../../components-base/Input/Input";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {IOrderForm} from "../../../../interfaces/order";
import {ApiOrder} from "../../../../api/endpoints/order";
import {ApiClient} from "../../../../api/endpoints/client";
import {IClient} from "../../../../interfaces/client";
import {INITIAL_ORDER_FORM, ORDER_FORM_FIELDS} from "../../constants";
import {ApiWarehouse} from "../../../../api/endpoints/warehouse";

interface IOrdersFormProps {
    create?: boolean;
    id?: string | undefined;
    order?: IOrderForm;
}

export const OrdersForm: React.FC<IOrdersFormProps> = ({ create, id, order }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<IOrderForm>(INITIAL_ORDER_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<ORDER_FORM_FIELDS, any>>({} as Record<ORDER_FORM_FIELDS, any>);

    const changeForm = (key: keyof IOrderForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<ORDER_FORM_FIELDS, any> = {} as Record<ORDER_FORM_FIELDS, any>;

        if (!form[ORDER_FORM_FIELDS.STATUS]) errors[ORDER_FORM_FIELDS.STATUS] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[ORDER_FORM_FIELDS.PAYMENT]) errors[ORDER_FORM_FIELDS.STATUS] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await handlerCreate() : await handlerUpdate();
            dispatch(setLoading(false));
        }
    };

    const handlerCreate = async () => {
        const res = await ApiOrder.create(form);
        if (res) {
            notification.success({
                message: ts('Замовлення успішно створене!'),
            });
            navigate('/' + MAIN_APP_ROUTES.ORDERS);
        }
    };

    const handlerUpdate = async () => {
        if (!id) return;
        const res = await ApiOrder.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (order) setForm(order);
    }, [order]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Row>
                <Col md={12}>
                    <Autocomplete name={'payment'}
                                  label={ts('Тип оплати')}
                                  value={form[ORDER_FORM_FIELDS.PAYMENT]}
                                  placeholder={ts('Оберіть тип оплати')}
                                  optionsMapper={(data: string) => ({ value: data, id: data })}
                                  items={['INVOICE', 'CARD']}
                                  errors={validateErrors?.[ORDER_FORM_FIELDS.PAYMENT]}
                                  onChange={(value: any) => changeForm(ORDER_FORM_FIELDS.PAYMENT, value)}
                    />
                </Col>
                <Col md={12}>
                    <Autocomplete name={'client'}
                                  label={ts('Клієнт')}
                                  value={form[ORDER_FORM_FIELDS.CLIENT_ID]}
                                  placeholder={ts('Оберіть клієнта для замовлення')}
                                  optionsMapper={(data: IClient) => ({ value: data.name, id: data.id })}
                                  exclusion={id ? [Number(id)] : []}
                                  getData={() => ApiClient.getAll({ filters: [], page: 1, pageSize: 9999})}
                                  errors={validateErrors?.[ORDER_FORM_FIELDS.CLIENT_ID]}
                                  onChange={(value: any) => changeForm(ORDER_FORM_FIELDS.CLIENT_ID, value)}
                    />
                </Col>
            </Row>
            <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
        </Form>
    );
};
