import React, {useEffect, useState} from 'react';
import {Button, Form} from "antd";
import {IAttribute} from "../../../../interfaces/attribute";
import {ApiAttribute} from "../../../../api/endpoints/attributes";
import {ts} from "../../../../translations/helpers";
import {ATTRIBUTES_TYPES, FILTER_TYPES} from "../../../../interfaces/main";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {Select} from "../../../components-base/Select/Select";
import {useSelector} from "react-redux";
import {ApiProduct} from "../../../../api/endpoints/product";
import {RootState, useAppDispatch} from "../../../../store/store";
import {getAttributes} from "../../../../store/reducers/attribute/actions";
import {selectAttribute, selectAttributesById} from "../../../../store/reducers/attribute/selectors";
import {Input} from "../../../components-base/Input/Input";
import {IProductAttribute} from "../../../../interfaces/product";
import {ProductAddedItem} from "../ProductAddedItem";

interface IProductsAttributeProps {
    product_id: string;
    selectedAttributes: IProductAttribute[];
    handlerUpdate: () => void;
}

export const ProductsAttribute: React.FC<IProductsAttributeProps> = ({ product_id, selectedAttributes, handlerUpdate}) => {
    const dispatch = useAppDispatch();
    const [attribute, setAttribute] = useState<string | null>(null);
    const attributeData = useSelector((state: RootState) => selectAttribute(state, attribute || ''));
    const attributes = useSelector(selectAttributesById);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const options = attributeData?.values?.map((value) => ({ value: value.record_id, label: value.value || '' })) || [];
    const [validateErrors, setValidateErrors] = useState<Record<string, any>>({} as Record<string, any>);

    const onCreate = async () => {
        if (validateForm()) {
            await ApiProduct.createAttribute(String(attribute), selectedValue, product_id);
            setSelectedValue(null);
            handlerUpdate();
        }
    };

    const onDelete = (attribute: string) => {
        ApiProduct.deleteAttribute(String(attribute), product_id);
        handlerUpdate();
    };


    const validateForm = () => {
        const errors: Record<string, any> = {};
        if (!attribute) errors['attribute'] = { status: 'error', message: ts('Необхідно обрати атрибут') };
        if (attributeData?.type === ATTRIBUTES_TYPES.SELECT && !selectedValue) errors['value'] = { status: 'error', message: ts('Необхідно обрати значення') };
        if (attributeData?.type === ATTRIBUTES_TYPES.INPUT && !selectedValue) errors['value'] = { status: 'error', message: ts('Необхідно ввести значення') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    useEffect(() => {
        if(attribute) {
            dispatch(getAttributes({ filters: [{ column: 'ids', type: FILTER_TYPES.IN, value: attribute }] }));
        }
        if(selectedAttributes) {
            dispatch(getAttributes({ filters: [{ column: 'ids', type: FILTER_TYPES.IN, value: selectedAttributes.map((e) => Number(e.attribute_id)) }] }));
        }
    }, [attribute, selectedAttributes]);

    console.log(selectedAttributes);

    return (
        <div className={'products-attributes-update-container'}>
            <div className="products-attributes-add">
                <Form layout={'vertical'} onFinish={onCreate}>
                    <Autocomplete name={'attribute'}
                                  label={ts('Атрибут')}
                                  value={attribute}
                                  placeholder={ts('Оберіть атрибут')}
                                  optionsMapper={(data: IAttribute) => ({ value: data.name, id: data.id })}
                                  // exclusion={id ? [Number(id)] : []}
                                  getData={() => ApiAttribute.getAll({ filters: [], page: 1, pageSize: 9999})}
                                  errors={validateErrors?.['attribute']}
                                  onChange={(value: any) => setAttribute(value)}
                    />
                    {attribute && attributeData && (
                        <>
                            {attributeData?.type === ATTRIBUTES_TYPES.SELECT && (
                                <Select options={options}
                                        value={selectedValue}
                                        handlerChange={setSelectedValue}
                                        errors={validateErrors?.['value']}
                                />
                            )}
                            {attributeData?.type === ATTRIBUTES_TYPES.INPUT && (
                                <Input value={selectedValue}
                                       placeholder={ts('Введіть значення атрибуту')}
                                       handlerChange={setSelectedValue}
                                       errors={validateErrors?.['value']}
                                       required
                                />
                            )}
                        </>
                    )}
                    <Button type={'primary'} htmlType={'submit'}>{ts('Зберегти')}</Button>
                </Form>
            </div>
            <div className="products-attributes-added">
                {selectedAttributes.map((attribute) => {
                    const attr = attributes[attribute.attribute_id];
                    const value = attr?.type === ATTRIBUTES_TYPES.SELECT
                                    ? attr?.values?.find((e) => e.record_id === attribute?.value)?.value
                                    : attribute?.value || '';
                    return <ProductAddedItem name={attr?.name}
                                             value={String(value)}
                                             handlerDelete={() => onDelete(attr?.id)}
                    />
                })}
            </div>
        </div>
    );
}