import React from 'react';
import {ts} from "../../../translations/helpers";
import {Typography} from "antd";

interface IEllipsisTextProps {
    text: string;
}
export const EllipsisText: React.FC<IEllipsisTextProps> = ({ text }) => {
    return (
        <Typography.Paragraph ellipsis={{
            rows: 3,
            expandable: true,
            symbol: ts('детальніше'),
        }}>
            {text}
        </Typography.Paragraph>
    );
}