import {ts} from "../../../../translations/helpers";
import {formatDate} from "../../../../helpers/formaters";
import {Button, Space} from "antd";
import React, {useEffect, useState} from "react";
import {DeleteOutlined} from '@ant-design/icons'
import {Table} from "../../../components-base/Table/Table";
import {IMainSliderItem} from "../../../../interfaces/slider";
import {ApiSlider} from "../../../../api/endpoints/slider";
import {SORT_TYPES} from "../../../../interfaces/api";
import {SliderSortInput} from "../SliderSortInput";
import {SliderLinkInput} from "../SliderLinkInput";

interface IProps {
    countryId: number;
    shouldUpdate: boolean;
    handlerUpdate: (status: boolean) => void;
}

const getColumns = (
    handlerDelete: (id: string) => void,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Зображення'),
            dataIndex: 'file_id',
            key: 'file_id',
            render: (value: any, item: IMainSliderItem) => <img src={item.file.link} alt={item.file.name} style={{ maxHeight: '50px' }}/>,
        },
        {
            title: ts('Сортування'),
            dataIndex: 'sort',
            key: 'sort',
            sorter: true,
            render: ((value: any, item: IMainSliderItem) => <SliderSortInput current={value} handler={(form) => ApiSlider.updateImageHomePage(String(item.id), form)}/>)
        },
        {
            title: ts('Посилання'),
            dataIndex: 'link',
            key: 'link',
            render: ((value: any, item: IMainSliderItem) => <SliderLinkInput current={value} handler={(form) => ApiSlider.updateImageHomePage(String(item.id), form)}/> )
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    {/*<Button><SelectOutlined /></Button>*/}
                    <Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>
                </Space>;
            }
        },


    ];
};

export const SliderHomePageTable: React.FC<IProps> = ({ countryId, shouldUpdate, handlerUpdate }) => {
    const [uploaded, setUploaded] = useState<IMainSliderItem[]>([]);

    const handlerDelete = async (id: string) => {
        await ApiSlider.deleteImageHomePage(id);
        handlerUpdate(true);
    };

    const columns = getColumns(handlerDelete);

    useEffect(() => {
        if (shouldUpdate) {
            ApiSlider.getHomePage(countryId).then((res) => {
                setUploaded(res);
                handlerUpdate(false);
            });
        }
    }, [shouldUpdate, countryId]);

    return (
        <div className={'slider-home-page-table-container'}>
            <Table columns={columns}
                   data={uploaded}
                   total={uploaded.length}
                   page={1}
                   pageSize={9999}
                   sort={{column: 'id', type: SORT_TYPES.ASC}}
                // loading={loading}
            />
        </div>
    );
};
