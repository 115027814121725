import React, {useEffect, useState} from 'react';
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {UserForm} from "../UserForm";
import { useParams } from 'react-router-dom';
import {UserRoles} from "../UserRoles";
import {Divider} from "antd";
import {ApiStuff} from "../../../../api/endpoints/stuff";
import {IUser} from "../../../../interfaces/main";
import {USER_FORM_FIELDS} from "../../constants";
import {IUserForm} from "../../../../interfaces/users";

export const UserUpdate = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [user, setUser] = useState<IUserForm>({} as IUserForm);
    const [userRoles, setUserRoles] = useState<number[]>([]);

    useEffect(() => {
        if (id) {
            ApiStuff.getOne(id)
                .then((stuff: IUser) => {
                    if (stuff.id) {
                        setUser({
                            [USER_FORM_FIELDS.NAME]: stuff.name,
                            [USER_FORM_FIELDS.SURNAME]: stuff.surname,
                            [USER_FORM_FIELDS.MIDDLE_NAME]:stuff.middle_name,
                            [USER_FORM_FIELDS.ACTIVE]: Boolean(Number(stuff.active)),
                            [USER_FORM_FIELDS.SIGNATORY]: Boolean(Number(stuff.signatory)),
                        });
                        setUserRoles(stuff.role)
                    }
                });
        }
    }, [id]);

    return (
        <div className={'user-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Редагування користувача')}/>
            <PageHeader
                title={ts('Редагування користувача')}
            />
            <Divider children={ts('Основна інформація')} orientation="left" orientationMargin="0"/>
            <UserForm id={id} user={user}/>
            <Divider children={ts('Ролі користувача')}  orientation="left" orientationMargin="0"/>
            <UserRoles userId={id as string} userRoles={userRoles}/>
        </div>
    );
}