import React from 'react';
import {ts} from "../../../../translations/helpers";
import {Divider} from "antd";
import {LangEditor} from "../../../components-base/LangEditor/LangEditor";
import {IApiLangValue} from "../../../../interfaces/api";
import {ApiProduct} from "../../../../api/endpoints/product";

interface IProductSEOProps {
    product_id: string;
    translates: IApiLangValue[];
}

export const ProductSEO: React.FC<IProductSEOProps> = ({translates, product_id}) => {
    const handlerChangeTitle = async (value: any) => product_id && await ApiProduct.updateSEOTitle(value, product_id);
    const handlerChangeDescription = async (value: any) => product_id && await ApiProduct.updateSEODescription(value, product_id);
    const handlerChangeKeywords = async (value: any) => product_id && await ApiProduct.updateSEOKeywords(value, product_id);

    return (
        <>
            <Divider children={ts('Назва')} orientation="left" orientationMargin="0"/>
            <LangEditor handlerChange={handlerChangeTitle} translates={translates} keyValue={'meta_title'}/>
            <Divider children={ts('Ключові слова')} orientation="left" orientationMargin="0"/>
            <LangEditor handlerChange={handlerChangeKeywords} translates={translates} keyValue={'meta_keywords'}/>
            <Divider children={ts('Опис')} orientation="left" orientationMargin="0"/>
            <LangEditor handlerChange={handlerChangeDescription} translates={translates} keyValue={'meta_description'} withTextArea/>

        </>
    );
};