import {ts} from "../../../../translations/helpers";
import {formatDate} from "../../../../helpers/formaters";
import {Button, Space, Tag} from "antd";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useMemo} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useSelector} from "react-redux";
import {IPaginationData, ISort} from "../../../../interfaces/main";
import {setPagination, setSort} from "../../../../store/reducers/order/reducer";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Table} from "../../../components-base/Table/Table";
import {
    selectOrders,
    selectOrdersById, selectOrdersCount, selectOrdersPage,
    selectOrdersPageSize,
    selectOrdersSort
} from "../../../../store/reducers/order/selectors";
import {getOrders} from "../../../../store/reducers/order/actions";
import {EditOutlined} from "@ant-design/icons";
import {IOrder} from "../../../../interfaces/order";
import {SelectOutlined} from "@ant-design/icons/lib";

const getColumns = () => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Клієнт'),
            dataIndex: 'client',
            key: 'client',
            sorter: true,
        },
        {
            title: ts('Тип оплати'),
            dataIndex: 'payment',
            key: 'payment',
            sorter: true,
            render: (value: any, record: IOrder) => {
                return (
                    <div className={`order-table-payment-type-cell ${record.card_status === 'paid' ? 'paid' : ''}`}>{value}</div>
                );
            }
        },
        {
            title: ts('Тип замовлення'),
            dataIndex: 'type',
            key: 'type',
            sorter: true,
        },
        {
            title: ts('Статус'),
            dataIndex: 'status',
            key: 'status',
            render: (value: any, record: IOrder) => <Tag color={'green'}>{value}</Tag>,
        },
        {
            title: ts('Сума'),
            dataIndex: 'amount',
            key: 'amount',
            render: (value: any, record: IOrder) => `${value} ${record.currency}`,
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    <Button type={'default'}>
                        <Link to={`show/${item.id}`}><SelectOutlined /></Link>
                    </Button>
                    <Button type={'default'}>
                        <Link to={`${item.id}`}><EditOutlined /></Link>
                    </Button>
                    {/*<Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>*/}
                </Space>;
            }
        },


    ];
};

export const OrdersTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const data = useSelector(selectOrders);
    const dataById = useSelector(selectOrdersById);
    const totalData: number = useSelector(selectOrdersCount);
    const page = useSelector(selectOrdersPage);
    const pageSize = useSelector(selectOrdersPageSize);
    const sort = useSelector(selectOrdersSort);

    const handlerPagination = (pagination: IPaginationData) => dispatch(setPagination(pagination));
    const handlerSort = (sort: ISort) => dispatch(setSort(sort));

    const orders = useMemo(() => {
        return data.map((id) => dataById[id]);
    }, [data, dataById]);

    const columns = getColumns();

    useEffect(() => {
        dispatch(getOrders({}));
    }, [page, pageSize, sort?.column, sort?.type]);

    return (
        <div className={'user-table-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={ts('Замовлення')}
                subtitle={ts('Перелік усіх замовлень компанії')}
                // extra={[
                //     <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(ORDERS_ROUTES.CREATE))}>
                //         <PlusCircleFilled />
                //         {ts('Створити')}
                //     </Button>
                // ]}
            />
            <Table columns={columns}
                   data={orders}
                   total={totalData}
                   setPagination={handlerPagination}
                   setSort={handlerSort}
                   page={page}
                   pageSize={pageSize}
                   sort={sort}
                // loading={loading}
            />
        </div>
    );
};
