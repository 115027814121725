import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Col, Divider, Row, Tabs} from "antd";
import {LangEditor} from "../../../components-base/LangEditor/LangEditor";
import {IProduct, IProductDiscount, IProductForm} from "../../../../interfaces/product";
import {ApiProduct} from "../../../../api/endpoints/product";
import {PRODUCT_FORM_FIELDS} from "../../constants";
import {ProductForm} from "../ProductForm";
import {UploadFile} from "../../../components-base/UploadFile/UploadFile";
import 'antd/dist/antd.css';
import {ProductCategory} from "../ProductCategory";
import {Directories} from "../../../app-main/components/Wrappers/Directories/Directories";
import {ProductCountry} from "../ProductCountry";
import {ProductTag} from "../ProductTag";
import {ProductsAttribute} from "../ProductsAttribute";
import {ProductSEO} from "../ProductSEO";
import {ProductDiscountAndTaxes} from "../ProductDiscountAndTaxes";
import {FILE_UPLOADS_TYPE} from "../../../../interfaces/main";

const { TabPane } = Tabs;

export const ProductUpdate = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [product, setProduct] = useState<IProductForm>({} as IProductForm);
    const [categories, setCategories] = useState<string[]>([]);
    const [countries, setCountries] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(true);

    const handlerNameChanges = async (value: any) => id && await ApiProduct.updateLangName(value, id);
    const handlerDescriptionChanges = async (value: any) => id && await ApiProduct.updateDescription(value, id);
    const handlerCardNameChanges = async (value: any) => id && await ApiProduct.updateCardName(value, id);
    const handlerUploadImage = async ({ file_id }: { file_id: string, name: string }) => id && await ApiProduct.createImage(file_id, id);
    const handlerDeleteImage = async (file_id: string) => id && await ApiProduct.deleteImage(file_id, id);
    const handlerUploadDocument = async ({ file_id, name }: { file_id: string, name: string }) => id && await ApiProduct.createDocument(file_id,name, id);
    const handlerDeleteDocument = async (file_id: string) => id && await ApiProduct.deleteDocument(file_id, id);

    useEffect(() => {
        if (id && shouldUpdate) {
            ApiProduct.getOne(id)
                .then((product: IProduct) => {
                    if (product.id) {
                        setProduct({
                            [PRODUCT_FORM_FIELDS.NAME]: product.name,
                            [PRODUCT_FORM_FIELDS.MODEL]: product.model,
                            [PRODUCT_FORM_FIELDS.PRODUCER]: product.producer,
                            [PRODUCT_FORM_FIELDS.PRICE]: product.price,
                            [PRODUCT_FORM_FIELDS.ALIAS]: product.alias,
                            [PRODUCT_FORM_FIELDS.LANG]: product.lang,
                            [PRODUCT_FORM_FIELDS.ACTIVE]: product.active,
                            [PRODUCT_FORM_FIELDS.PRE_ORDER]: product.preorder,
                            [PRODUCT_FORM_FIELDS.IS_NEW]: product.isNew,
                            [PRODUCT_FORM_FIELDS.SEO]: product.seoOne,
                            [PRODUCT_FORM_FIELDS.IMAGE]: product.image,
                            [PRODUCT_FORM_FIELDS.DOCUMENT]: product.doc,
                            [PRODUCT_FORM_FIELDS.ATTRIBUTES]: product.attribut,
                            [PRODUCT_FORM_FIELDS.TAX]: product.tax,
                            [PRODUCT_FORM_FIELDS.DISCOUNT]: product.discount,
                        });
                        setCategories(product.category || []);
                        setCountries(product.country || []);
                        setTags(product.tag || []);
                    }
                    setShouldUpdate(false);
                });
        }
    }, [id, shouldUpdate]);

    return id ? (
        <Directories country
                     tax
                     manufacturer
        >
            <div className={'company-form-container'}>
                <Breadcrumbs items={breadcrumbs} extra={ts('Редагування продукту')}/>
                <PageHeader
                    title={ts('Редагування продукту')}
                />
                <Tabs defaultActiveKey="main">
                    <TabPane tab={ts('Основні')} key="main">
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={8} >
                                <Divider children={ts('Назва')} orientation="left" orientationMargin="0"/>
                                <LangEditor handlerChange={handlerNameChanges} translates={product.lang} keyValue={'name'}/>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} >
                                <Divider children={ts('Коротка назва')} orientation="left" orientationMargin="0"/>
                                <LangEditor handlerChange={handlerCardNameChanges} translates={product.lang} keyValue={'card_name'}/>
                            </Col>
                        </Row>
                        <Divider children={ts('Опис')} orientation="left" orientationMargin="0"/>
                        <LangEditor handlerChange={handlerDescriptionChanges} translates={product.lang} keyValue={'description'} withTextArea/>
                        <Divider children={ts('Основна інформація')} orientation="left" orientationMargin="0"/>
                        <ProductForm product={product} id={id}/>
                    </TabPane>
                    <TabPane tab={ts('SEO')} key="seo">
                        {/*<Divider children={ts('SEO-дані необхідні для товару')} orientation="left" orientationMargin="0"/>*/}
                        <ProductSEO product_id={id} translates={product.seo || []}/>
                    </TabPane>
                    <TabPane tab={ts('Категорії')} key="category">
                        <Divider children={ts('Оберіть категорії для продукту')} orientation="left" orientationMargin="0"/>
                        <ProductCategory product_id={id} selectedCategories={categories}/>
                    </TabPane>
                    <TabPane tab={ts('Країни')} key="country">
                        <Divider children={ts('Оберіть для яких країн буде доступним товар')} orientation="left" orientationMargin="0"/>
                        <ProductCountry product_id={id} selectedCountries={countries}/>
                    </TabPane>
                    <TabPane tab={ts('Теги')} key="tags">
                        <Divider children={ts('Оберіть які теги будуть пов\'язані з товаром')} orientation="left" orientationMargin="0"/>
                        <ProductTag product_id={id} selectedTags={tags}/>
                    </TabPane>
                    <TabPane tab={ts('Атрибути')} key="attribute">
                        <Divider children={ts('Налаштування атрибутів товару')} orientation="left" orientationMargin="0"/>
                        <ProductsAttribute product_id={id} selectedAttributes={product.attributes || []} handlerUpdate={() => setShouldUpdate(true)}/>
                    </TabPane>
                    <TabPane tab={ts('Знижки та податки')} key="discounts-and-taxes">
                        {/*<Divider children={ts('Вкажіть знижку на товар та податки, які були закладені у раніше вказану ціну')} orientation="left" orientationMargin="0"/>*/}
                        <ProductDiscountAndTaxes product_id={id} addedTaxes={product?.tax || []} handlerUpdate={() => setShouldUpdate(true)} discount={product?.discount || {} as IProductDiscount}/>
                    </TabPane>
                    <TabPane tab={ts('Зображення')} key="images">
                        <Divider children={ts('Завантажте зображення товару')} orientation="left" orientationMargin="0"/>
                        <UploadFile type={FILE_UPLOADS_TYPE.IMAGE}
                                    handlerCreate={handlerUploadImage}
                                    handlerDelete={handlerDeleteImage}
                                    uploadedFiles={product?.image?.map((e) => ({
                                        uid: e.file_id,
                                        name: '',
                                        status: 'done',
                                        url: e.link,
                                    })) || []}
                        />
                    </TabPane>
                    <TabPane tab={ts('Документи')} key="documents">
                        <Divider children={ts('Документи звязані з товаром')} orientation="left" orientationMargin="0"/>
                        <UploadFile type={FILE_UPLOADS_TYPE.FILE}
                                    handlerCreate={handlerUploadDocument}
                                    handlerDelete={handlerDeleteDocument}
                                    uploadedFiles={product?.doc?.map((e) => ({
                                        uid: e.file_id,
                                        name: e.name || '',
                                        status: 'done',
                                        url: e.link,
                                    })) || []}
                                    previewOpen
                        />
                    </TabPane>
                </Tabs>
            </div>
        </Directories>
        ) : <></>;
};
