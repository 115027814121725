import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import React from "react";
import {AttributeForm} from "../AttributeForm";

export const AttributeCreate = () => {
    const { breadcrumbs } = useActiveRoute();

    return (
        <div className={'attribute-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Створити атрибут')}/>
            <PageHeader
                title={ts('Створити атрибут')}
            />
            <AttributeForm create/>
        </div>
    );
};