import React, {useState} from 'react';
import {Form, Select as AntSelect } from 'antd';
import {ISelectItem} from "../../../interfaces/main";
import {INPUT_STATUSES} from "../../../interfaces/form";

interface ISelectProps {
    options: ISelectItem[];
    handlerChange?: (value: any) => void;
    handlerSelect?: (value: any) => void;
    handlerDeselect?: (value: any) => void;
    label?: string;
    errors?: { status: INPUT_STATUSES, message: string }
    value: any;
    defaultValue?: any;
    multiple?: boolean;
}

export const Select: React.FC<ISelectProps> = (
    {
        options,
        handlerChange,
        handlerSelect,
        handlerDeselect,
        label,
        errors = { status: INPUT_STATUSES.SUCCESS, message: '' },
        defaultValue,
        value,
        multiple
    }) => {
    const { status, message } = errors;
    return (
        <Form.Item validateStatus={status}
                   help={status && message}
                   label={label}
        >
            <AntSelect
                mode={multiple ? 'multiple' : undefined}
                showArrow
                defaultValue={defaultValue ?? null}
                value={value}
                onChange={handlerChange}
                filterOption={(search, item) => item && search ? item?.label?.indexOf(search) > -1 : true}
                options={options}
                onSelect={handlerSelect}
                onDeselect={handlerDeselect}
                style={{
                    width: multiple ? '400px' : '300px',
                }}
            />
        </Form.Item>
    );
}