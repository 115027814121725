import React, {useEffect, useMemo, useState} from "react";
import {AutoComplete as AntAutocomplete, Form} from "antd";
import {FILTER_TYPES} from "../../../interfaces/main";
import {IAutocompleteOption, INPUT_STATUSES} from "../../../interfaces/form";
import {IApiDataPaginationResponse, IApiRequestData} from "../../../interfaces/api";

interface IAutocompleteProps {
    name?: string;
    value: any;
    optionsMapper: (data: any) => IAutocompleteOption;
    getData?: (data: IApiRequestData) => Promise<IApiDataPaginationResponse<any>>;
    onChange: (value: any) => void;
    onBlur?: (value: any) => void;
    errors?: { status: INPUT_STATUSES, message: string };
    exclusion?: number[];
    items?: any[];
    disabled?: boolean;
    placeholder?: string;
    label?: string;
}

export const Autocomplete: React.FC<IAutocompleteProps> = (
    {
        value,
        name,
        label,
        optionsMapper,
        getData,
        exclusion,
        items,
        disabled,
        errors = { status: INPUT_STATUSES.SUCCESS, message: '' },
        placeholder,
        onBlur,
        onChange,
    }) => {
    const [options, setOptions] = useState<IAutocompleteOption[]>([]);
    const { status, message } = errors;
    const requestData = async (searchQuery: string) => {
        const res = items || (getData ? (await getData({
            filters: [
                {
                    type: FILTER_TYPES.LIKE,
                    column: 'name',
                    value: searchQuery,
                }
            ],
            pageSize: 10
        }))?.data : []);

        let requestOptions = res?.map(optionsMapper);
        if(exclusion) {
            requestOptions = requestOptions.filter((option: { value: string, id: number }) => !exclusion.includes(Number(option.id)));
        }
        setOptions(requestOptions);
    };

    const onSearch = async (searchQuery: string) => {
        await requestData(searchQuery);
    };

    const onSelect = (value: any, option: IAutocompleteOption) => {
        onChange(option.id);
    };

    useEffect(() => {
        requestData('');
    }, []);

    return (
        <Form.Item name={name}
                   label={label}
                   validateStatus={status}
                   help={status && message}
        >
            {value !== undefined && options?.length > 0 ? (
                <AntAutocomplete
                    options={options}
                    onSearch={onSearch}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={options.find((option) => option.id === value)}
                    defaultValue={options.find((option) => option.id === value)}
                    onSelect={onSelect}
                    disabled={disabled}
                    placeholder={placeholder}
                    style={{ maxWidth: '300px' }}
                />
            ) : ''}
        </Form.Item>
    );
}
