import React, {useEffect, useState} from 'react';
import {Form} from "antd";
import {Select} from "../../../components-base/Select/Select";
import {ISelectItem} from "../../../../interfaces/main";
import {ApiProduct} from "../../../../api/endpoints/product";
import {ITag} from "../../../../interfaces/tag";
import {ApiTag} from "../../../../api/endpoints/tag";

interface IProductCategoryProps {
    product_id: number | string;
    selectedTags: string[];
}

export const ProductTag: React.FC<IProductCategoryProps> = ({ product_id, selectedTags }) => {

    const [tags, setTags] = useState<ITag[] | null>(null);
    const [selected, setSelected] = useState<ISelectItem[]>([]);

    const handlerSelect = (tag_id: string) => {
        ApiProduct.createTag(tag_id, String(product_id));
    };

    const handlerDeselect = (tag_id: string) => {
        ApiProduct.deleteTag(tag_id, String(product_id));
    };

    useEffect(() => {
        ApiTag.getAll({ filters: [], page: 1, pageSize: 9999}).then((res) => {
            setTags(res?.data || []);
        });
    }, []);

    useEffect(() => {
        if(tags && selectedTags.length > 0) {
            setSelected(
                tags
                    .filter((tag) => selectedTags.includes(String(tag.id)))
                    .map((tag) => ({ value: tag.id, label: tag.name}))
            )
        }
    }, [tags, selectedTags]);

    return tags ? (
        <Form layout={'vertical'}>
            <Select options={tags.map((tag) => ({ value: tag.id, label: tag.name }))}
                    value={selected}
                    handlerSelect={handlerSelect}
                    handlerDeselect={handlerDeselect}
                    handlerChange={setSelected}
                    multiple
            />
        </Form>
    ) : <></>;
}