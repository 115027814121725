import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import {validateEmail} from "../../../../helpers/validators";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/company/reducer";
import {Button, Col, Form, notification, Row} from "antd";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {Input} from "../../../components-base/Input/Input";
import {Switch} from "../../../components-base/Switch/Switch";
import {ICompanyForm, ICompanyInfoForm} from "../../../../interfaces/company";
import {
    COMPANY_INFO_FORM_FIELDS,
    INITIAL_COMPANY_INFO_FORM
} from "../../constants";
import {ApiCompany} from "../../../../api/endpoints/company";
import {DIRECTORIES, ICountry} from "../../../../interfaces/main";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {DirectoriesApi} from "../../../../api/endpoints/directory";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import {TextArea} from "../../../components-base/TextArea/TextArea";

interface ICompanyFormProps {
    create?: boolean;
    id?: string | undefined;
    company?: ICompanyInfoForm;
    readonly?: boolean;
}

export const CompanyInfoForm: React.FC<ICompanyFormProps> = (
    {
        id,
        company,
        create,
        readonly = false,
    }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<ICompanyInfoForm>(INITIAL_COMPANY_INFO_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<COMPANY_INFO_FORM_FIELDS, any>>({} as Record<COMPANY_INFO_FORM_FIELDS, any>)

    const changeForm = (key: keyof ICompanyInfoForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<string, any> = {};

        if (!form[COMPANY_INFO_FORM_FIELDS.COMPANY_NAME]) errors[COMPANY_INFO_FORM_FIELDS.COMPANY_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_INFO_FORM_FIELDS.IBAN]) errors[COMPANY_INFO_FORM_FIELDS.IBAN] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_INFO_FORM_FIELDS.SURNAME_SIGNATURES]) errors[COMPANY_INFO_FORM_FIELDS.SURNAME_SIGNATURES] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_INFO_FORM_FIELDS.NAME_SIGNATURES]) errors[COMPANY_INFO_FORM_FIELDS.NAME_SIGNATURES] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_INFO_FORM_FIELDS.POSITION_PERSON]) errors[COMPANY_INFO_FORM_FIELDS.POSITION_PERSON] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_INFO_FORM_FIELDS.ADDRESS_CORRESPONDENCE]) errors[COMPANY_INFO_FORM_FIELDS.ADDRESS_CORRESPONDENCE] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_INFO_FORM_FIELDS.ADDRESS_REGISTER]) errors[COMPANY_INFO_FORM_FIELDS.ADDRESS_REGISTER] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_INFO_FORM_FIELDS.BANK_INFO]) errors[COMPANY_INFO_FORM_FIELDS.BANK_INFO] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[COMPANY_INFO_FORM_FIELDS.PHONE]) errors[COMPANY_INFO_FORM_FIELDS.PHONE] = { status: 'error', message: ts('Поле повинне бути заповненим') };


        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await handlerCreate() : await handlerUpdate();
            dispatch(setLoading(false));
        }
    };

    const handlerCreate = async () => {
        const res = await ApiCompany.createInfo(form);
        if (res) {
            notification.success({
                message: ts('Компанія успішно створена!'),
            });
            navigate('/' + MAIN_APP_ROUTES.COMPANIES);
        }
    };

    const handlerUpdate = async () => {
        if (!id) return;
        const res = await ApiCompany.updateInfo(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (company) setForm(company);
    }, [company]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.COMPANY_NAME]}
                           placeholder={ts('Введіть назву')}
                           label={ts('Назва компанії')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.COMPANY_NAME, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.COMPANY_NAME]}
                           required
                    />
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.IBAN]}
                           placeholder={ts('Введіть IBAN')}
                           label={ts('IBAN')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.IBAN, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.IBAN]}
                           required
                    />
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.BANK_INFO]}
                           placeholder={ts('Введіть інформацію про банк')}
                           label={ts('Інформація про банк')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.BANK_INFO, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.BANK_INFO]}
                           required
                    />
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.ADDRESS_REGISTER]}
                           placeholder={ts('Введіть адресу реєстрації')}
                           label={ts('Адреса реєстрації')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.ADDRESS_REGISTER, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.ADDRESS_REGISTER]}
                           required
                    />
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.ADDRESS_CORRESPONDENCE]}
                           placeholder={ts('Введіть адресу для кореспонденції')}
                           label={ts('Адресу для кореспонденції')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.ADDRESS_CORRESPONDENCE, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.ADDRESS_CORRESPONDENCE]}
                           required
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.PHONE]}
                           placeholder={ts('Введіть телефон')}
                           label={ts('Телефон')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.PHONE, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.PHONE]}
                           required
                    />
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.POSITION_PERSON]}
                           placeholder={ts('Введіть ім\'я представника компанії')}
                           label={ts('Ім\'я представника компанії')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.POSITION_PERSON, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.POSITION_PERSON]}
                           required
                    />
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.NAME_SIGNATURES]}
                           placeholder={ts('Введіть ім\'я підписанта')}
                           label={ts('Ім\'я підписанта')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.NAME_SIGNATURES, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.NAME_SIGNATURES]}
                           required
                    />
                    <Input value={form[COMPANY_INFO_FORM_FIELDS.SURNAME_SIGNATURES]}
                           placeholder={ts('Введіть прізвище підписанта')}
                           label={ts('Прізвище підписанта')}
                           handlerChange={(value: any) => changeForm(COMPANY_INFO_FORM_FIELDS.SURNAME_SIGNATURES, value)}
                           disabled={readonly}
                           errors={validateErrors?.[COMPANY_INFO_FORM_FIELDS.SURNAME_SIGNATURES]}
                           required
                    />
                </Col>
            </Row>
            {!readonly && (
                <Row justify={'start'}>
                    <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
                </Row>
            )}
        </Form>
    );
};