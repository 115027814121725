export const mapDataById = (data: any[]) => {
    return data.reduce((acc, item) => ({ ...acc, [String(item.id)]: item }), {});
};

export const transliteration = ( word: string ) => {

    const matches: Record<string, string> = {
        'а': 'a', 'б': 'b',  'в': 'v', 'г': 'g',  'д': 'd',
        'е': 'e', 'ё': 'e',  'ж': 'j', 'з': 'z',  'и': 'i',
        'к': 'k', 'л': 'l',  'м': 'm', 'н': 'n',  'о': 'o',
        'п': 'p', 'р': 'r',  'с': 's', 'т': 't',  'у': 'u',
        'ф': 'f', 'х': 'h',  'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'ы': 'y', 'э': 'e',  'ю': 'u', 'я': 'ya', 'щ': 'shch',
        'і': 'i', 'ї': 'yi', ' ': '_',
    };
    const n_str: string[] = []

    word = word.replace(/[ъь]+/g, '').replace(/й/g, 'i');

    for ( let i: number = 0; i < word.length; ++i ) {
        n_str.push(
            matches[ word[i] ]
            || matches[ word[i].toLowerCase() ] == undefined && word[i]
            || matches[ word[i].toLowerCase() ].replace(/^(.)/, function ( match: string ) { return match.toUpperCase() }
        ));
    }

    return n_str.join('');
};

export const generateRandomInteger = () => {
    return Math.floor(Math.random() * (1000000000 - 999999999 + 1)) + 1000000000;
}