import React, {ReactNode} from 'react';
import {PageHeader as AntPageHeader} from "antd";

interface PageHeaderProps {
    title: string,
    subtitle?: string,
    extra?: ReactNode | ReactNode[]
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, extra}) => {
    return <AntPageHeader
        className="site-page-header"
        title={title}
        subTitle={subtitle}
        extra={extra}
        style={{
            padding: 0,
            marginBottom: '31px',
        }}
    />
};
