import React, {useEffect, useState} from 'react';
import {useAppDispatch} from "../../../../store/store";
import {IOrderInfoForm} from "../../../../interfaces/order";
import {ORDER_INFO_FORM_FIELDS} from "../../constants";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/order/reducer";
import {ApiOrder} from "../../../../api/endpoints/order";
import {Button, Col, Form, notification, Row} from "antd";
import {Input} from "../../../components-base/Input/Input";

interface IOrdersFormClientProps {
    id: string;
    orderInfo: IOrderInfoForm
}

export const OrdersFomClient: React.FC<IOrdersFormClientProps> = ({ id, orderInfo }) => {
    const dispatch = useAppDispatch();
    const [form, setForm] = useState<IOrderInfoForm>({});
    const [validateErrors, setValidateErrors] = useState<Record<ORDER_INFO_FORM_FIELDS, any>>({} as Record<ORDER_INFO_FORM_FIELDS, any>);

    const changeForm = (key: keyof IOrderInfoForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<ORDER_INFO_FORM_FIELDS, any> = {} as Record<ORDER_INFO_FORM_FIELDS, any>;

        if (!form[ORDER_INFO_FORM_FIELDS.CUSTOMER_NAME]) errors[ORDER_INFO_FORM_FIELDS.CUSTOMER_NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[ORDER_INFO_FORM_FIELDS.CUSTOMER_SURNAME]) errors[ORDER_INFO_FORM_FIELDS.CUSTOMER_SURNAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[ORDER_INFO_FORM_FIELDS.CUSTOMER_EMAIL]) errors[ORDER_INFO_FORM_FIELDS.CUSTOMER_EMAIL] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[ORDER_INFO_FORM_FIELDS.CUSTOMER_PHONE]) errors[ORDER_INFO_FORM_FIELDS.CUSTOMER_PHONE] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            if (!id) return;
            dispatch(setLoading(true));
            const res = await ApiOrder.updateInfo(form, id);
            if (res) {
                notification.success({
                    message: ts('Зміни успішно збережені!'),
                });
            }
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        setForm({
            [ORDER_INFO_FORM_FIELDS.CUSTOMER_NAME]: orderInfo.customer_name,
            [ORDER_INFO_FORM_FIELDS.CUSTOMER_SURNAME]: orderInfo.customer_surname,
            [ORDER_INFO_FORM_FIELDS.CUSTOMER_EMAIL]: orderInfo.customer_email,
            [ORDER_INFO_FORM_FIELDS.CUSTOMER_PHONE]: orderInfo.customer_phone,
        });
    }, [orderInfo, id]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Row>
                <Col md={12}>
                    <Input value={form[ORDER_INFO_FORM_FIELDS.CUSTOMER_NAME]}
                           placeholder={ts('Імʼя')}
                           label={ts('Імʼя')}
                           handlerChange={(value: any) => changeForm(ORDER_INFO_FORM_FIELDS.CUSTOMER_NAME, value)}
                           errors={validateErrors?.[ORDER_INFO_FORM_FIELDS.CUSTOMER_NAME]}
                           required
                    />
                </Col>
                <Col md={12}>
                    <Input value={form[ORDER_INFO_FORM_FIELDS.CUSTOMER_SURNAME]}
                           placeholder={ts('Прізвище')}
                           label={ts('Прізвище')}
                           handlerChange={(value: any) => changeForm(ORDER_INFO_FORM_FIELDS.CUSTOMER_SURNAME, value)}
                           errors={validateErrors?.[ORDER_INFO_FORM_FIELDS.CUSTOMER_SURNAME]}
                           required
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Input value={form[ORDER_INFO_FORM_FIELDS.CUSTOMER_PHONE]}
                           placeholder={ts('Телефон')}
                           label={ts('Телефон')}
                           handlerChange={(value: any) => changeForm(ORDER_INFO_FORM_FIELDS.CUSTOMER_PHONE, value)}
                           errors={validateErrors?.[ORDER_INFO_FORM_FIELDS.CUSTOMER_PHONE]}
                           required
                    />
                </Col>
                <Col md={12}>
                    <Input value={form[ORDER_INFO_FORM_FIELDS.CUSTOMER_EMAIL]}
                           placeholder={ts('E-mail')}
                           label={ts('E-mail')}
                           handlerChange={(value: any) => changeForm(ORDER_INFO_FORM_FIELDS.CUSTOMER_EMAIL, value)}
                           errors={validateErrors?.[ORDER_INFO_FORM_FIELDS.CUSTOMER_EMAIL]}
                           required
                    />
                </Col>
            </Row>
            <Button type={'primary'} htmlType={'submit'}>{ts('Зберегти')}</Button>
        </Form>
    );
};
