import React from 'react';
import {Form, Input as AntInput} from "antd";
import {INPUT_STATUSES, INPUT_TYPES} from "../../../interfaces/form";

const AntTextArea = AntInput.TextArea;

interface IInputProps {
    value: any;
    handlerChange: (value: any) => void;
    handlerBlur?: (value: any) => void;
    label?: string;
    disabled?: boolean;
    prefix?: any;
    rows?: number
    placeholder?: string;
    fullWidth?: boolean;
    errors?: { status: INPUT_STATUSES, message: string }
    defaultValue?: any;
    required?: boolean;
}

export const TextArea: React.FC<IInputProps> = (
    {
        value,
        handlerChange,
        handlerBlur,
        prefix,
        placeholder,
        fullWidth,
        required = false,
        errors = { status: INPUT_STATUSES.SUCCESS, message: '' },
        rows = 4,
        label,
        disabled = false,
        defaultValue
    }) => {
    const { status, message } = errors;
    return (
        <Form.Item
            label={label}
            className={required ? 'is-required' : ''}
            validateStatus={status}
            help={status && message}
        >
            <AntTextArea prefix={prefix}
                      placeholder={placeholder}
                      onChange={(event) => handlerChange(event.target.value)}
                      onBlur={(event) => !(handlerBlur) || handlerBlur(event.target.value)}
                      value={value}
                      status={status}
                      rows={rows}
                      style={{ maxWidth: fullWidth ? '900px' : '300px' }}
                      disabled={disabled}
                      defaultValue={defaultValue}
            />
        </Form.Item>
    );
};
