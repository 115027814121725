import validate from "validate.js";
import {ts} from "../translations/helpers";
import {INPUT_STATUSES} from "../interfaces/form";

export const validateEmail = (email: string | undefined) => {
    if (!email) {
        return {
            status: INPUT_STATUSES.ERROR,
            message: ts('Поле повинне бути заповненим'),
        };
    }

    if (validate.single(email, { email: true })) {
        return {
            status: INPUT_STATUSES.ERROR,
            message: ts('Введіть валідний e-mail'),
        };
    }

    return false;
};