import {API_METHODS, ENDPOINTS} from "../constants";
import {API_RESPONSE_CODE, IApiDataPaginationResponse, IGetPaginatedDataParams} from "../../interfaces/api";
import {apiRequest} from "../instance";
import {DIRECTORIES, ICountry, IManufacturer} from "../../interfaces/main";
import {notification} from "antd";
import {ts} from "../../translations/helpers";



class DirectoriesApi {
    public endpoint = ENDPOINTS.DIRECTORY;

    public getEndpoint = () => String(this.endpoint);

    public get = async (directory: DIRECTORIES): Promise<any[]> =>
    {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${directory}`,
            {
                page: 1,
                pageSize: 9999,
            },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data?.data
            ? res.data.data
            : [];
    };

    public create = async (directory: DIRECTORIES, name: string): Promise<void> =>
    {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${directory}/add`,
            { name },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public delete = async (directory: DIRECTORIES, id: string): Promise<void> =>
    {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${directory}/${id}`,
            {},
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public update = async (directory: DIRECTORIES, name: string, id: string): Promise<void> =>
    {
        const res = await apiRequest(
            `/${this.getEndpoint()}/${directory}/${id}`,
            { name },
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public getCountries = async (): Promise<ICountry[]> => await this.get(DIRECTORIES.COUNTRY);

    public getTaxes = async (): Promise<ICountry[]> => await this.get(DIRECTORIES.TAX);

    public getManufacturers = async (): Promise<IManufacturer[]> => await this.get(DIRECTORIES.MANUFACTURER);
}

const directoriesApi = new DirectoriesApi();

export { directoriesApi as DirectoriesApi };