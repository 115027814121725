import React, {useEffect, useMemo} from 'react';
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {ts} from "../../../../translations/helpers";
import {Button, Space} from "antd";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {Table} from "../../../components-base/Table/Table";
import {useAppDispatch} from "../../../../store/store";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {Link, useNavigate} from "react-router-dom";
import {USERS_ROUTES, getRoute, USER_FORM_FIELDS} from "../../constants";
import {Switch} from "../../../components-base/Switch/Switch";
import {formatDate} from "../../../../helpers/formaters";
import {useSelector} from "react-redux";
import {
    selectUsers,
    selectUsersById,
    selectUsersCount,
    selectUsersPage,
    selectUsersPageSize, selectUsersSort
} from "../../../../store/reducers/users/selectors";
import {IPaginationData, ISort} from "../../../../interfaces/main";
import {setPagination, setSort} from "../../../../store/reducers/users/reducer";
import {deleteStuffs, editStuff, getStuffs} from "../../../../store/reducers/users/actions";

const getColumns = (
    handleChangeActive: (value: boolean, id: string) => void,
    handleChangeSignatory: (value: boolean, id: string) => void,
    handlerDelete: (id: string) => void,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Ім\'я'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: ts('Прізвище'),
            dataIndex: 'surname',
            key: 'surname',
            sorter: true,
        },
        {
            title: ts('По-батькові'),
            dataIndex: 'middle_name',
            key: 'middle_name',
            sorter: true,
        },
        {
            title: ts('E-mail'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: ts('Підписант'),
            dataIndex: 'signatory',
            key: 'signatory',
            render: (value: any, item: any) => {
                return <Switch checked={Boolean(Number(value))} handlerChange={(value) => handleChangeSignatory(value, item.id)} name={'signatory'}/>;
            }
        },
        {
            title: ts('Активність'),
            dataIndex: 'active',
            key: 'active',
            render: (value: any, item: any) => {
                return <Switch checked={Boolean(Number(value))} handlerChange={(value) => handleChangeActive(value, item.id)} name={'active'}/>;
            }
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    <Button type={'default'} >
                        <Link to={`${item.id}`}><EditOutlined /></Link>
                    </Button>
                    {/*<Button><SelectOutlined /></Button>*/}
                    <Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>
                </Space>;
            }
        },
    ];
};

export const UserTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const data = useSelector(selectUsers);
    const dataById = useSelector(selectUsersById);
    const totalData: number = useSelector(selectUsersCount);
    const page = useSelector(selectUsersPage);
    const pageSize = useSelector(selectUsersPageSize);
    const sort = useSelector(selectUsersSort);

    const handlerPagination = (pagination: IPaginationData) => dispatch(setPagination(pagination));
    const handlerSort = (sort: ISort) => dispatch(setSort(sort));
    const handleChangeActive = (value: boolean, id: string) => dispatch(editStuff({ form: { [USER_FORM_FIELDS.ACTIVE]: value }, id }));
    const handleChangeSignatory = (value: boolean, id: string) => dispatch(editStuff({ form: { [USER_FORM_FIELDS.SIGNATORY]: value }, id }));
    const handlerDelete = async (id: string) => await dispatch(deleteStuffs(id));

    const users = useMemo(() => {
        return data.map((id) => dataById[id]);
    }, [data, dataById]);

    const columns = getColumns(handleChangeActive, handleChangeSignatory, handlerDelete);

    useEffect(() => {
        dispatch(getStuffs({}));
    }, [page, pageSize, sort?.column, sort?.type]);

    return (
        <div className={'user-table-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={ts('Користувачі')}
                subtitle={ts('Аккаунти працівників компанії')}
                extra={[
                    <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(USERS_ROUTES.CREATE))}>
                        <PlusCircleFilled />
                        {ts('Створити')}
                    </Button>
                ]}
            />
            <Table columns={columns}
                   data={users}
                   total={totalData}
                   setPagination={handlerPagination}
                   setSort={handlerSort}
                   page={page}
                   pageSize={pageSize}
                   sort={sort}
                // loading={loading}
            />
        </div>
    );
};
