import React from 'react';
import {Layout} from "antd";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import {LangSwitcher} from "../LangSwitcher/LangSwitcher";
import {UserAccount} from "../UserAccount/UserAccount";
import {Notifications} from "../Notifications/Notifications";

interface IHeaderProps {
    setCollapsed: (collapsed: boolean) => void;
    collapsed: boolean;
}

export const Header: React.FC<IHeaderProps> = ({ setCollapsed, collapsed }) => {
    return (
        <Layout.Header
            className="site-layout-background site-layout-header"
        >
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
            })}
            <LangSwitcher />
            <Notifications/>
            <UserAccount/>
        </Layout.Header>
    );
}