import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {IAttribute, IAttributeForm, IAttributeValue} from "../../../../interfaces/attribute";
import {ATTRIBUTE_FORM_FIELDS} from "../../constants";
import {ApiAttribute} from "../../../../api/endpoints/attributes";
import {AttributeForm} from "../AttributeForm";
import {Divider, Tabs} from "antd";
import {LangEditor} from "../../../components-base/LangEditor/LangEditor";
import {CATEGORY_FORM_FIELDS} from "../../../app-category/constants";
import {AttributeValues} from "../AttributeValues";
import {ATTRIBUTES_TYPES} from "../../../../interfaces/main";

const { TabPane } = Tabs;

export const AttributeUpdate = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [attribute, setAttribute] = useState<IAttributeForm>({} as IAttributeForm);
    const [attributeValues, setAttributeValues] = useState<IAttributeValue[]>([]);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(true)
    const handlerNameChanges = async (value: any) => id && await ApiAttribute.updateLang(value, id);

    useEffect(() => {
        if (id && shouldUpdate) {
            ApiAttribute.getOne(id)
                .then((attribute: IAttribute) => {
                    if (attribute.id) {
                        setAttribute({
                            [ATTRIBUTE_FORM_FIELDS.TYPE]: attribute.type,
                            [ATTRIBUTE_FORM_FIELDS.SORT]: attribute.sort,
                            [CATEGORY_FORM_FIELDS.LANG]: attribute.lang,
                        });
                        setAttributeValues(attribute?.value || [])
                    }
                });
        }
        setShouldUpdate(false);
    }, [id, shouldUpdate]);

    return (
        <div className={'attribute-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Редагування атрибута')}/>
            <PageHeader
                title={ts('Редагування атрибута')}
            />
            <Tabs defaultActiveKey="1">
                <TabPane tab="Основні" key="1">
                    <Divider children={ts('Назва')} orientation="left" orientationMargin="0"/>
                    <LangEditor handlerChange={handlerNameChanges} translates={attribute.lang}/>
                    <Divider children={ts('Основна інформація')} orientation="left" orientationMargin="0"/>
                    <AttributeForm attribute={attribute} id={id} triggerUpdate={() => setShouldUpdate(true)}/>
                </TabPane>
                {attribute.type === ATTRIBUTES_TYPES.SELECT && (
                    <TabPane tab="Значення" key="2">
                        <AttributeValues values={attributeValues} id={id} handlerUpdate={() => setShouldUpdate(true)}/>
                    </TabPane>
                )}
            </Tabs>


        </div>
    );
}