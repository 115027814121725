import React, {useEffect, useState} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {Modal, Progress, Upload} from 'antd';
import {apiSendFileRequest} from "../../../api/instance";
import {FILE_UPLOADS_TYPE} from "../../../interfaces/main";
import {IUploadList} from "../../../interfaces/form";

interface IUploadFileProps {
    handlerCreate?: (file: { file_id: string, name: string, link: string }) => void;
    handlerDelete?: (file_id: string) => void;
    type: FILE_UPLOADS_TYPE;
    maxCount?: number;
    uploadedFiles: IUploadList[];
    previewOpen?: boolean;
}

const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });

export const UploadFile: React.FC<IUploadFileProps> = ({
                                                           type,
                                                           handlerCreate,
                                                           handlerDelete,
                                                           uploadedFiles,
                                                           maxCount,
                                                           previewOpen,
                                                       }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<any[]>([]);

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file: any) => {
        if(file.url && previewOpen) {
            window.open(file.url);
        } else {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }

            setPreviewImage(file.url || file.preview);
            setPreviewVisible(true);
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        }
    };

    const handleChange = (value: any) => {
        setFileList(value?.fileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const [progress, setProgress] = useState(0);

    const uploadImage = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options;
        const fmData = new FormData();

        fmData.append("file", file);
        fmData.append("type", type);

        const onUploadProgress = (event: any) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
        };

        const result = await apiSendFileRequest('/file/create', fmData, onUploadProgress);
        if (result?.data?.status === 'OK' || result?.data?.status === 200) {
            onSuccess("Ok");
            handlerCreate && handlerCreate({ file_id: result?.data?.data?.id, name: result?.data?.data?.name, link: result?.data?.data?.link });
        } else {
            onError({ err: 'File does not upload' })
        }
    };

    const onRemove = (value: any) => {
        handlerDelete && handlerDelete(value.uid);
    };

    useEffect(() => {
        if(uploadedFiles && fileList.length === 0) {
            setFileList(uploadedFiles);
        }
    }, [uploadedFiles.length]);

    return (
        <>
            <Upload
                customRequest={uploadImage}
                listType="picture-card"
                fileList={fileList}
                onRemove={onRemove}
                onPreview={handlePreview}
                onChange={handleChange}
                maxCount={maxCount}
            >
                {fileList?.length >= 8 ? null : uploadButton}
            </Upload>
            {progress > 0 ? <Progress percent={progress} /> : null}
            <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
}