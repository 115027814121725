import React, {ReactNode} from 'react';
import {Layout} from "antd";
import {Router} from "../Router/Router";

export const Content: React.FC = () => {
    return (
        <Layout.Content className="site-layout-background site-layout-content">
            <Router />
        </Layout.Content>
    );
};
