import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/category/reducer";
import {Button, Form, notification, Row} from "antd";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {Input} from "../../../components-base/Input/Input";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {ICountry} from "../../../../interfaces/main";
import {Switch} from "../../../components-base/Switch/Switch";
import {CATEGORY_FORM_FIELDS, INITIAL_CATEGORY_FORM} from "../../constants";
import {ICategoryForm} from "../../../../interfaces/category";
import {ApiCategory} from "../../../../api/endpoints/category";
import {transliteration} from "../../../../helpers/data";

interface ICategoryFormProps {
    create?: boolean;
    id?: string | undefined;
    category?: ICategoryForm;
}

export const CategoryForm: React.FC<ICategoryFormProps> = ({ create, id, category }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<ICategoryForm>(INITIAL_CATEGORY_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<CATEGORY_FORM_FIELDS, any>>({} as Record<CATEGORY_FORM_FIELDS, any>)

    const changeForm = (key: keyof ICategoryForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<string, any> = {};

        if(create) {
            if (!form[CATEGORY_FORM_FIELDS.NAME]) errors[CATEGORY_FORM_FIELDS.NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        }

        if (!form[CATEGORY_FORM_FIELDS.ALIAS]) errors[CATEGORY_FORM_FIELDS.ALIAS] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await handlerCreate() : await handlerUpdate();
            dispatch(setLoading(false));
        }
    };

    const handlerCreate = async () => {
        const res = await ApiCategory.create(form);
        if (res) {
            notification.success({
                message: ts('Категорія успішно створена!'),
            });
            navigate('/' + MAIN_APP_ROUTES.CATEGORIES);
        }
    };

    const handlerUpdate = async () => {
        if (!id) return;
        const res = await ApiCategory.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (category) setForm(category);
    }, [category]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            {create && (
                <Input value={form[CATEGORY_FORM_FIELDS.NAME]}
                       placeholder={ts('Введіть назву')}
                       label={ts('Назва категорії')}
                       handlerChange={(value: any) => changeForm(CATEGORY_FORM_FIELDS.NAME, value)}
                       handlerBlur={(value: any) => changeForm(CATEGORY_FORM_FIELDS.ALIAS, transliteration(value))}
                       errors={validateErrors?.[CATEGORY_FORM_FIELDS.NAME]}
                       required
                />
            )}
            <Input value={form[CATEGORY_FORM_FIELDS.ALIAS]}
                   placeholder={ts('Введіть аліас')}
                   label={ts('Аліас')}
                   handlerChange={(value: any) => changeForm(CATEGORY_FORM_FIELDS.ALIAS, value)}
                   errors={validateErrors?.[CATEGORY_FORM_FIELDS.ALIAS]}
                   required
            />
            <Autocomplete name={'parent'}
                          label={ts('Батьківська категорія')}
                          value={form[CATEGORY_FORM_FIELDS.PARENT]}
                          placeholder={ts('Оберіть категорії')}
                          optionsMapper={(data: ICountry) => ({ value: data.name, id: data.id })}
                          exclusion={id ? [Number(id)] : []}
                          getData={() => ApiCategory.getAll({ filters: [], page: 1, pageSize: 9999})}
                          errors={validateErrors?.[CATEGORY_FORM_FIELDS.PARENT]}
                          onChange={(value: any) => changeForm(CATEGORY_FORM_FIELDS.PARENT, value)}
            />
            <Switch checked={form[CATEGORY_FORM_FIELDS.ACTIVE]}
                    handlerChange={(value: any) => changeForm(CATEGORY_FORM_FIELDS.ACTIVE, value)}
                    label={ts('Активність')}
                    name={CATEGORY_FORM_FIELDS.ACTIVE}
            />
            <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
        </Form>
    );
};