import React, {useState} from 'react';
import {Select} from "../../../components-base/Select/Select";
import {Button, Divider, Form, Tabs} from "antd";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import {ts} from "../../../../translations/helpers";
import {INPUT_TYPES} from "../../../../interfaces/form";
import {Input} from "../../../components-base/Input/Input";
import {ApiProduct} from "../../../../api/endpoints/product";
import {IProductDiscount, IProductTax} from "../../../../interfaces/product";
import {ProductAddedItem} from "../ProductAddedItem";
import {ProductDiscount} from "../ProductDiscount";

const { TabPane } = Tabs;

interface IProps {
    product_id: string;
    addedTaxes: IProductTax[];
    discount: IProductDiscount;
    handlerUpdate: () => void;
}

export const ProductDiscountAndTaxes: React.FC<IProps> = ({ product_id, addedTaxes, handlerUpdate, discount }) => {
    const taxes = DirectoriesController.getTaxes();
    const [selectedTax, setSelectedTax] = useState<string | null>(null);
    const [taxValue, setTaxValue] = useState<string | null>(null);
    const [validateErrors, setValidateErrors] = useState<Record<string, any>>({} as Record<string, any>);

    const validateForm = () => {
        const errors: Record<string, any> = {};

        if (!taxValue) errors['value'] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!selectedTax) errors['tax'] = { status: 'error', message: ts('Оберіть один із видів податку') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = () => {
        if (validateForm() && selectedTax) {
            ApiProduct.createTax(selectedTax, taxValue, product_id).then(() => {
                handlerUpdate();
                setSelectedTax(null);
                setTaxValue(null);
            });
        }
    };

    const onDelete = (tax_id: string) => {
        ApiProduct.deleteTax(tax_id, product_id).then(() => {
            handlerUpdate();
        });
    };

    return (
        <div className={'product-discounts-and-taxes-container'}>
            <Tabs defaultActiveKey="tax">
                <TabPane tab={ts('Податки')} key="tax">
                    <Divider children={ts('Додати вид податку')} orientation="left" orientationMargin="0"/>
                    <Form layout={'vertical'} onFinish={onSubmit}>
                        <Select options={taxes.filter((tax) => !addedTaxes.map((e) => Number(e.tax_id)).includes(Number(tax.id)))
                                              .map((tax) => ({ value: tax.id, label: tax.name }))}
                                value={selectedTax}
                                handlerChange={setSelectedTax}
                                label={ts('Податок')}
                                errors={validateErrors?.['tax']}
                        />
                        <Input value={taxValue}
                               placeholder={ts('Введіть ціну')}
                               label={ts('Значення %')}
                               handlerChange={setTaxValue}
                               type={INPUT_TYPES.NUMBER}
                               errors={validateErrors?.['value']}
                        />
                        <Button type={'primary'} htmlType={'submit'}>{ts('Зберегти')}</Button>
                    </Form>
                    <Divider children={ts('Додані до товару податки')} orientation="left" orientationMargin="0"/>
                    {addedTaxes.map((tax) => {
                        const taxData = taxes.find((e) => String(e.id) === String(tax.tax_id));
                        return <ProductAddedItem name={taxData?.name || ''}
                                                 value={String(tax?.value || '')}
                                                 handlerDelete={() => onDelete(String(tax.tax_id))}
                        />;
                    })}
                </TabPane>
                <TabPane tab={ts('Знижки')} key="discount">
                    <ProductDiscount product_id={product_id} addedDiscount={discount} handlerUpdate={handlerUpdate}/>
                </TabPane>
            </Tabs>
        </div>
    );
}