import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";

export const selectReducer = (state: RootState) => state[REDUCERS.TAG];
export const selectIsLoading = (state: RootState) => selectReducer(state).isLoading;
export const selectTags = (state: RootState) => selectReducer(state).ids;
export const selectTagsById = (state: RootState) => selectReducer(state).idsData;
export const selectTagsCount = (state: RootState) => selectReducer(state).dataCount;
export const selectTagsPage = (state: RootState) => selectReducer(state).page;
export const selectTagsPageSize = (state: RootState) => selectReducer(state).pageSize;
export const selectTagsSort = (state: RootState) => selectReducer(state).sort;