import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";

export const selectReducer = (state: RootState) => state[REDUCERS.COMPANY];
export const selectIsLoading = (state: RootState) => selectReducer(state).isLoading;
export const selectCompanies = (state: RootState) => selectReducer(state).ids;
export const selectCompaniesById = (state: RootState) => selectReducer(state).idsData;
export const selectCompaniesCount = (state: RootState) => selectReducer(state).dataCount;
export const selectCompaniesPage = (state: RootState) => selectReducer(state).page;
export const selectCompaniesPageSize = (state: RootState) => selectReducer(state).pageSize;
export const selectCompaniesSort = (state: RootState) => selectReducer(state).sort;