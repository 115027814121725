import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";

export const selectReducer = (state: RootState) => state[REDUCERS.USER];
export const selectIsLoading = (state: RootState) => selectReducer(state).isLoading;
export const selectUsers = (state: RootState) => selectReducer(state).ids;
export const selectUsersById = (state: RootState) => selectReducer(state).idsData;
export const selectUsersCount = (state: RootState) => selectReducer(state).dataCount;
export const selectUsersPage = (state: RootState) => selectReducer(state).page;
export const selectUsersPageSize = (state: RootState) => selectReducer(state).pageSize;
export const selectUsersSort = (state: RootState) => selectReducer(state).sort;