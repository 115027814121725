import React from 'react';
import {NotificationOutlined} from '@ant-design/icons';
import {Badge, Popover} from "antd";
import {NotificationsContent} from "./NotificationsContent";

export const Notifications: React.FC = () => {
    return (
        <Popover content={<NotificationsContent/>} trigger={'click'}>
            <Badge count={5} size={'small'}>
                <NotificationOutlined style={{ fontSize: '19px' }}/>
            </Badge>
        </Popover>
    );
};
