import React from 'react';
import {Descriptions} from "antd";
import {ts} from "../../../../translations/helpers";

interface IOrdersInfoCompanyProps {
    name: string;
    code: string;
    address: string;
    pdvCode: string;
}

export const OrdersInfoCompany: React.FC<IOrdersInfoCompanyProps> = ({ name, code, address, pdvCode }) => {
    return (
        <Descriptions>
            <Descriptions.Item label={ts('Назва компанії')}>
                {name || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={ts('Код компанії')}>
                {code || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={ts('Адреса компанії')}>
                {address || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={ts('Код платника податків')}>
                {pdvCode || '-'}
            </Descriptions.Item>
        </Descriptions>
    );
}
