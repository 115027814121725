import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {IClient, IClientForm} from "../../../../interfaces/client";
import {ApiClient} from "../../../../api/endpoints/client";
import {CLIENT_FORM_FIELDS} from "../../constants";
import {ClientForm} from "../ClientForm";

export const ClientUpdate = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [client, setClient] = useState<IClientForm>({} as IClientForm);

    useEffect(() => {
        if (id) {
            ApiClient.getOne(id)
                .then((client: IClient) => {
                    if (client.id) {
                        setClient({
                            [CLIENT_FORM_FIELDS.NAME]: client.name,
                            [CLIENT_FORM_FIELDS.MIDDLE_NAME]: client.middle_name,
                            [CLIENT_FORM_FIELDS.SURNAME]: client.surname,
                            [CLIENT_FORM_FIELDS.PHONE]: client.phone,
                            [CLIENT_FORM_FIELDS.EMAIL]: client.email,
                            [CLIENT_FORM_FIELDS.ACTIVE]: Boolean(client.active),
                        });
                    }
                });
        }
    }, [id]);

    return (
        <div className={'company-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Редагування клієнт')}/>
            <PageHeader
                title={ts('Редагування клієнт')}
            />
            <ClientForm client={client} id={id}/>
        </div>
    );
}