import React, {useState} from 'react';
import {Input} from "../../../components-base/Input/Input";
import {ts} from "../../../../translations/helpers";
import {ApiSlider} from "../../../../api/endpoints/slider";

interface IProps {
    current: number;
    handler: (form: Record<string, any>) => void;
}

export const SliderLinkInput: React.FC<IProps> = ({ current, handler }) => {
    const [link, setLink] = useState(current);

    const handlerBlur = () => {
        if (link !== current) {
            handler({ link: link });
        }
    };

    return (
        <Input value={link}
               handlerChange={setLink}
               handlerBlur={handlerBlur}
               placeholder={ts('')}
        />
    );
}
