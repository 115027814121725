import {MAIN_APP_ROUTES} from "../app-main/constants";
import {IUserForm} from "../../interfaces/users";

export const getRoute = (route: string): string => `/${MAIN_APP_ROUTES.USERS}/${route}`;

export enum USERS_ROUTES {
    CREATE = 'create',
    EDIT = ':id',
}

export enum USER_FORM_FIELDS {
    NAME = 'name',
    SURNAME = 'surname',
    MIDDLE_NAME = 'middle_name',
    USERNAME = 'username',
    EMAIL = 'email',
    PASSWORD = 'password',
    ACTIVE = 'active',
    SIGNATORY = 'signatory',
}

export const INITIAL_USER_FORM: IUserForm = {
    [USER_FORM_FIELDS.NAME]: '',
    [USER_FORM_FIELDS.SURNAME]: '',
    [USER_FORM_FIELDS.MIDDLE_NAME]:'',
    [USER_FORM_FIELDS.USERNAME]: '',
    [USER_FORM_FIELDS.EMAIL]: '',
    [USER_FORM_FIELDS.PASSWORD]: '',
    [USER_FORM_FIELDS.ACTIVE]: true,
    [USER_FORM_FIELDS.SIGNATORY]: false
};