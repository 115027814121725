import {ApiBase} from "../ApiBase";
import {API_METHODS, ENDPOINTS} from "../constants";
import {IAttribute, IAttributeForm} from "../../interfaces/attribute";
import {ATTRIBUTE_FORM_FIELDS} from "../../components/app-attribute/constants";
import {ATTRIBUTES_TYPES} from "../../interfaces/main";
import {apiRequest} from "../instance";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {notification} from "antd";
import {ts} from "../../translations/helpers";

export interface IUpdateAttributeParams {
    [ATTRIBUTE_FORM_FIELDS.TYPE]?: ATTRIBUTES_TYPES;
    [ATTRIBUTE_FORM_FIELDS.SORT]?: number;
}

class ApiAttribute extends ApiBase<IUpdateAttributeParams, IAttributeForm, IAttribute >{
    public endpoint = ENDPOINTS.ATTRIBUTE;

    public updateLang = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/lang/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createValue = async (form: { data: {lang: number; name: string}[]}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/create/value/${id}`,
            form,
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Значення успішно створене!'),
            })
            : notification.error({
                message: ts('Помилка при створенні'),
                description: JSON.stringify(res.message),
            });
    };

    public updateValueLang = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/value/lang/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteValue = async ( id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/delete/value/${id}`,
            {},
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при видаленні'),
                description: JSON.stringify(res.message),
            });
    };
}

const apiAttribute = new ApiAttribute();

export { apiAttribute as ApiAttribute };