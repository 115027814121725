export const LOCAL_STORAGE_LANGUAGE = 'i18nextLng';

export enum MAIN_APP_ROUTES {
    'DASHBOARD' = 'dashboard',
    'PRODUCTS' = 'products',
    'PRODUCTS_ALL' = 'products_all',
    'CATEGORIES' = 'categories',
    'COMPANIES' = 'companies',
    'CLIENTS' = 'clients',
    'DIRECTORIES' = 'directories',
    'DIRECTORIES_COUNTRY' = 'directories-country',
    'DIRECTORIES_TAX' = 'directories-tax',
    'DIRECTORIES_MANUFACTURER' = 'directories-manufacturer',
    'ATTRIBUTES' = 'attributes',
    'TAGS' = 'tags',
    'ORDERS' = 'orders',
    'USERS' = 'users',
    'SLIDERS' = 'sliders',
    'WAREHOUSES' = 'warehouses',
    'WAREHOUSES_HISTORY' = 'warehouses_history',
    'WAREHOUSES_ACTIONS' = 'warehouses_actions',
    'WAREHOUSES_ACTION_RECEIVING' = 'warehouses/receiving',
    'WAREHOUSES_ACTION_WRITE_OFF' = 'warehouses_action_write_off',
    'WAREHOUSES_ACTION_MOVING' = 'warehouses_action_moving',
    'WAREHOUSES_ACTION_RESERVE' = 'warehouses_action_reserve',
    'ANALYTICS' = 'analytics',
    'SETTINGS' = 'settings',
    'MANUFACTURER' = 'manufacturer',
    'SELL_MANAGER' = 'sell_managers',
    'COMPANY_SETTING' = 'company_settings',
}