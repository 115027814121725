import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from "antd";
import {ts} from "../../../../../translations/helpers";
import {ApiProduct} from "../../../../../api/endpoints/product";
import {Autocomplete} from "../../../../components-base/Autocomplete/Autocomplete";
import {IProduct} from "../../../../../interfaces/product";
import {IWarehouseLot} from "../../../../../interfaces/warehouse";
import {ApiWarehouse} from "../../../../../api/endpoints/warehouse";
import {ApiOrder} from "../../../../../api/endpoints/order";

interface IOrdersFormAddLotProps {
    order_id: number;
    product_id: number;
    order_product_id: number;
    handlerUpdate: () => void;
}

export const OrdersFormAddLot: React.FC<IOrdersFormAddLotProps> = ({ order_id, product_id, order_product_id, handlerUpdate }) => {
    const [lotId, setLotId] = useState<number | null>(null);
    const [lots, setLots] = useState<IWarehouseLot[] | null>(null);

    const handleSubmit = (values: any) => {
        if (!lotId) return;
        ApiOrder.addProductLot({
            order_product_id: order_product_id,
            lot_id: lotId,
            count: 1,
        }, String(order_id)).then((res) => {
            if (res && handlerUpdate) handlerUpdate();
        });
    };

    useEffect(() => {
        ApiWarehouse.getLots(product_id).then((res) => {
            res && setLots(res);
        });
    }, [product_id]);

    return (
        <Form layout={'horizontal'} onFinish={handleSubmit}>
            <Row>
                <Col md={12}>
                    {lots && (
                        <Autocomplete name={'lot_id'}
                                      label={ts('Лот')}
                                      value={lotId}
                                      placeholder={ts('Оберіть лот')}
                                      optionsMapper={(data: IWarehouseLot) => ({ value: data.lot, id: data.id })}
                                      items={lots || []}
                                      onChange={(value: any) => setLotId(value)}
                        />
                    )}
                </Col>
                <Col md={12}>
                    <Button type={'primary'} htmlType={'submit'}>{ts('Додати до Товару')}</Button>
                </Col>
            </Row>
        </Form>
    );
};
