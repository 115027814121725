import React, {useEffect, useState} from 'react';
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {Tabs} from "antd";
import {SliderHomePage} from "../SliderHomePage";
import {ICountry} from "../../../../interfaces/main";
import {DirectoriesApi} from "../../../../api/endpoints/directory";
import {SliderRelatedProducts} from "../SliderRelatedProducts";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";

const {TabPane} = Tabs;

export const SliderMain: React.FC = () => {
    const {breadcrumbs} = useActiveRoute();
    const [country, setCountry] = useState<number | null>(null);
    const [countries, setCountries] = useState<ICountry[] | null>(null);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(true);

    useEffect(() => {
        DirectoriesApi.getCountries().then((res) => {
            setCountries(res);
        })
    }, []);

    return (
        <div className={'sliders-tabs-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Налаштування слайдерів')}/>
            <PageHeader
                title={ts('Налаштування слайдерів')}
            />
            {countries && (<Autocomplete name={'country'}
                                         label={ts('Країна')}
                                         value={country}
                                         placeholder={ts('Оберіть країну')}
                                         optionsMapper={(data: ICountry) => ({ value: data.name, id: data.id })}
                                         items={countries}
                                         onChange={(value) => { setCountry(value); setShouldUpdate(true); }}
            />)}
            {country && (
                <Tabs defaultActiveKey="home" onChange={() => setShouldUpdate(true)}>
                    <TabPane tab={ts('Головна сторінка')} key="home">
                        <SliderHomePage countryId={country} shouldUpdate={shouldUpdate} setShouldUpdate={setShouldUpdate}/>
                    </TabPane>
                    <TabPane tab={ts('Рекомендовані продукти')} key="product">
                        <SliderRelatedProducts countryId={country}  shouldUpdate={shouldUpdate} setShouldUpdate={setShouldUpdate}/>
                    </TabPane>
                </Tabs>
            )}
        </div>
    );
};
