import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import React from "react";
import {ClientForm} from "../ClientForm";

export const ClientCreate = () => {
    const { breadcrumbs } = useActiveRoute();

    return (
        <div className={'clients-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Створити клієнта')}/>
            <PageHeader
                title={ts('Створити клієнта')}
            />
            <ClientForm create/>
        </div>
    );
}