import {MAIN_APP_ROUTES} from "../app-main/constants";
import {IAttributeForm} from "../../interfaces/attribute";
import {ATTRIBUTES_TYPES} from "../../interfaces/main";

export const getRoute = (route: string): string => `/${MAIN_APP_ROUTES.ATTRIBUTES}/${route}`;

export enum ATTRIBUTES_ROUTES {
    CREATE = 'create',
    EDIT = ':id',
}

export enum ATTRIBUTE_FORM_FIELDS {
    TYPE = 'type',
    SORT = 'sort',
    NAME = 'name',
    LANG = 'lang',
}

export const INITIAL_ATTRIBUTE_FORM: IAttributeForm = {
    [ATTRIBUTE_FORM_FIELDS.TYPE]: ATTRIBUTES_TYPES.INPUT,
    [ATTRIBUTE_FORM_FIELDS.SORT]: 0,
    [ATTRIBUTE_FORM_FIELDS.NAME]: '',
};