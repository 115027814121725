import {useAppDispatch} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/tag/reducer";
import {Button, Col, Form, notification, Row} from "antd";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {Input} from "../../../components-base/Input/Input";
import {Switch} from "../../../components-base/Switch/Switch";
import {ITagForm} from "../../../../interfaces/tag";
import {INITIAL_TAG_FORM, TAG_FORM_FIELDS} from "../../constants";
import {ApiTag} from "../../../../api/endpoints/tag";
import {transliteration} from "../../../../helpers/data";

interface ITagFormProps {
    create?: boolean;
    id?: string | undefined;
    tag?: ITagForm;
}

export const TagForm: React.FC<ITagFormProps> = ({ create, id, tag }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<ITagForm>(INITIAL_TAG_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<TAG_FORM_FIELDS, any>>({} as Record<TAG_FORM_FIELDS, any>)

    const changeForm = (key: keyof ITagForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<string, any> = {};

        if(create) {
            if (!form[TAG_FORM_FIELDS.NAME]) errors[TAG_FORM_FIELDS.NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        }

        if (!form[TAG_FORM_FIELDS.ALIAS]) errors[TAG_FORM_FIELDS.ALIAS] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await handlerCreate() : await handlerUpdate();
            dispatch(setLoading(false));
        }
    };

    const handlerCreate = async () => {
        const res = await ApiTag.create(form);
        if (res) {
            notification.success({
                message: ts('Тег успішно створений!'),
            });
            navigate('/' + MAIN_APP_ROUTES.TAGS);
        }
    };

    const handlerUpdate = async () => {
        if (!id) return;
        const res = await ApiTag.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (tag) setForm(tag);
    }, [tag]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            {create && (
                <Input value={form[TAG_FORM_FIELDS.NAME]}
                       placeholder={ts('Введіть назву')}
                       label={ts('Назва тегу')}
                       handlerChange={(value: any) => changeForm(TAG_FORM_FIELDS.NAME, value)}
                       handlerBlur={(value: any) => changeForm(TAG_FORM_FIELDS.ALIAS, transliteration(value))}
                       errors={validateErrors?.[TAG_FORM_FIELDS.NAME]}
                       required
                />
            )}
            <Input value={form[TAG_FORM_FIELDS.ALIAS]}
                   placeholder={ts('Введіть аліас')}
                   label={ts('Аліас')}
                   handlerChange={(value: any) => changeForm(TAG_FORM_FIELDS.ALIAS, value)}
                   errors={validateErrors?.[TAG_FORM_FIELDS.ALIAS]}
                   required
            />
            <Switch checked={form[TAG_FORM_FIELDS.ACTIVE]}
                    handlerChange={(value: any) => changeForm(TAG_FORM_FIELDS.ACTIVE, value)}
                    label={ts('Активність')}
                    name={TAG_FORM_FIELDS.ACTIVE}
            />
            <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
        </Form>
    );
};