import {ApiCompany, IUpdateCompanyParams} from "../../../api/endpoints/company";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";
import {notification} from "antd";
import {ts} from "../../../translations/helpers";
import {ApiAttribute, IUpdateAttributeParams} from "../../../api/endpoints/attributes";
import {ACTION_TYPES} from "./actionTypes";
import {IFilter} from "../../../interfaces/main";

interface IEditFormParams {
    form: IUpdateAttributeParams;
    id: string;
}

export const getAttributes = createAsyncThunk(
    ACTION_TYPES.GET_ATTRIBUTES,
    async ({ filters = [] }: { filters?: IFilter[]}, thunkApi) => {
        const state = (thunkApi.getState() as RootState)[REDUCERS.ATTRIBUTE];
        const { page, pageSize, sort } = state;
        return await ApiAttribute.getAll({ filters, page, pageSize, sort });
    }
);

export const editAttribute = createAsyncThunk(
    ACTION_TYPES.EDIT_ATTRIBUTE,
    async ({ form, id }: IEditFormParams, thunkApi) => {
        const res = await ApiAttribute.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені'),
            });
            return res;
        }
    }
);

export const deleteAttribute = createAsyncThunk(
    ACTION_TYPES.DELETE_ATTRIBUTE,
    async (id: string, thunkApi) => {
        const res = await ApiAttribute.delete(id);
        if (res) {
            notification.success({
                message: ts('Атрибут успішно видалений'),
            })
            return id;
        }
    }
);