import React from 'react';
import {Button} from "antd";
import { DeleteOutlined } from '@ant-design/icons'

interface IProps {
    name: string;
    value: string;
    handlerDelete: () => void;
}

export const ProductAddedItem: React.FC<IProps> = ({ name, value, handlerDelete}) => {
    return (
        <div className={'product-added-item-container'}>
            <div className={'product-added-item-name'}>{name}: </div>
            <div className={'product-added-item-value'}>{value}</div>
            <div className={'product-added-item-delete'}>
                <Button type={'primary'} onClick={() => handlerDelete()} danger>
                    <DeleteOutlined />
                </Button>
            </div>
        </div>
    );
};