import {createSlice, PayloadAction, Slice} from '@reduxjs/toolkit'
import {ICurrency, ILang} from "../../../interfaces/main";
import {REDUCERS} from "../../../constants";

interface CommonState {
    lang: string | null,
    currencies: ICurrency[],
    langAvailable: ILang[],
}

const initialState: CommonState = {
    lang: null,
    langAvailable: [],
    currencies: [],
};

export const commonSlice: Slice<CommonState> = createSlice({
    name: REDUCERS.COMMON,
    initialState,
    reducers: {
        setLang: (state, action: PayloadAction<string>) => {
            state.lang = action.payload;
        },
        setLangAvailable: (state, action: PayloadAction<ILang[]>) => {
            state.langAvailable = action.payload;
        },
        setCurrencies: (state, action: PayloadAction<ICurrency[]>) => {
            state.currencies = action.payload;
        },
    },
    extraReducers: (builder) => {},
});

export const { setLang, setLangAvailable, setCurrencies  } = commonSlice.actions;

export default commonSlice;