import React, {useState} from 'react';
import {Button, Form, Typography} from "antd";
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Input} from "../../../components-base/Input/Input";
import {INPUT_TYPES} from "../../../../interfaces/form";
import {Checkbox} from "../../../components-base/Checkbox/Checkbox";
import {ts} from "../../../../translations/helpers";
import {getLogin} from "../../../../api/endpoints/auth";
import {LOCAL_STORAGE_ACCESS_KEY} from "../../../../api/constants";
import {Loader} from "../../../components-base/Loader/Loader";
import authManager from "../AuthManager/AuthManager";

export const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoginFailed, setIsLoginFailed] = useState<boolean>(false);

    const onSubmit = () => {
        setIsLoading(true);
        getLogin({ username, password, rememberMe }).then((token) => {
            localStorage.setItem(LOCAL_STORAGE_ACCESS_KEY, token);
            if (token) {
                authManager.getAuth().then((auth) => {
                    if (auth) window.location.reload();
                });
            } else {
                setIsLoginFailed(true);
                setIsLoading(false);
            }
        });
    };

    return (
        <div className={'login-form-container'}>
            <Form
                name="normal_login"
                className="login-form"
                // initialValues={{
                //     remember: true,
                // }}
                onFinish={onSubmit}
            >
                <Input value={username}
                       handlerChange={setUsername}
                       placeholder={ts('Логін або e-mail')}
                       prefix={<UserOutlined className="site-form-item-icon"/>}
                       required={true}
                />
                <Input value={password}
                       handlerChange={setPassword}
                       placeholder={ts('Пароль')}
                       type={INPUT_TYPES.PASSWORD}
                       prefix={<LockOutlined className="site-form-item-icon"/>}
                       required={true}

                />
                <Checkbox checked={rememberMe}
                          name={'rememberMe'}
                          label={ts('Запам\'ятати мене')}
                          handlerChange={setRememberMe}
                />
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        {ts('Увійти')}
                    </Button>
                </Form.Item>
                {isLoginFailed && (
                    <Form.Item style={{textAlign: 'center'}}>
                        <Typography.Text type={'danger'}>{ts('Неправильні логін або пароль')}</Typography.Text>
                    </Form.Item>)
                }
            </Form>
            {isLoading && <Loader/>}
        </div>
    );
}