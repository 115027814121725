import {COMPANY_FORM_FIELDS} from "../../components/app-company/constants";
import {ApiBase} from "../ApiBase";
import {API_METHODS, ENDPOINTS} from "../constants";
import {IProduct, IProductForm} from "../../interfaces/product";
import {PRODUCT_FORM_FIELDS} from "../../components/app-product/constants";
import {API_RESPONSE_CODE, IApiLangValue} from "../../interfaces/api";
import {apiRequest} from "../instance";
import {notification} from "antd";
import {ts} from "../../translations/helpers";

export interface IUpdateProductParams {
    [PRODUCT_FORM_FIELDS.NAME]?: string;
    [PRODUCT_FORM_FIELDS.MODEL]?: string;
    [PRODUCT_FORM_FIELDS.ALIAS]?: string;
    [PRODUCT_FORM_FIELDS.PRODUCER]?: string;
    [PRODUCT_FORM_FIELDS.PRICE]?: string;
    [PRODUCT_FORM_FIELDS.PRE_ORDER]?: boolean;
    [PRODUCT_FORM_FIELDS.IS_NEW]?: boolean;
    [PRODUCT_FORM_FIELDS.LANG]?: IApiLangValue[];
    [PRODUCT_FORM_FIELDS.ACTIVE]?: number;
}

class ApiProduct extends ApiBase<IUpdateProductParams, IProductForm, IProduct >{
    public endpoint = ENDPOINTS.PRODUCT;

    public updateLangName = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/lang/name/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public updateCardName = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/lang/card-name/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public updateDescription = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/lang/description/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createCategory = async (category_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/category/${id}`,
            { category_id },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteCategory = async (category_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/category/${id}`,
            { category_id },
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createCountry = async (country_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/country/${id}`,
            { country_id },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteCountry = async (country_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/country/${id}`,
            { country_id },
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createTag = async (tag_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/tag/${id}`,
            { tag_id },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteTag = async (tag_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/tag/${id}`,
            { tag_id },
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createAttribute = async (attribute_id: string, value: any, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/attribute/${id}`,
            { attribute_id, value },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteAttribute = async (attribute_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/attribute/${id}`,
            { attribute_id },
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public updateSEOTitle = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/seo-lang/title/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public updateSEODescription = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/seo-lang/description/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public updateSEOKeywords = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/seo-lang/keywords/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createTax = async (tax_id: string, value: any, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/tax/${id}`,
            { tax_id, value },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteTax = async (tax_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/tax/${id}`,
            { tax_id },
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createDiscount = async (value: any, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/discount/${id}`,
            { price_discount: value },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteDiscount = async (id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/discount/${id}`,
            {},
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createImage = async (file_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/image/${id}`,
            { file_id },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteImage = async (file_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/image/${id}`,
            { file_id },
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public createDocument = async (file_id: string, name: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/document/${id}`,
            { file_id, name },
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

    public deleteDocument = async (file_id: string, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/document/${id}`,
            { file_id },
            API_METHODS.DELETE
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

}

const apiProduct = new ApiProduct();

export { apiProduct as ApiProduct };