import React, {useEffect, useState} from 'react';
import {ILangEditorValue} from "../../../interfaces/form";
import {useSelector} from "react-redux";
import {selectLangAvailable} from "../../../store/reducers/common/selectors";
import {ts} from "../../../translations/helpers";
import {Input} from "../Input/Input";
import {IApiLangValue} from "../../../interfaces/api";
import {PRODUCT_FORM_FIELDS} from "../../app-product/constants";
import {TextArea} from "../TextArea/TextArea";
import {Col, Row} from "antd";
import {TextEditor} from "../TextEditor/TextEditor";

interface ILangEditorProps {
    handlerChange: (val: { lang: number; value: string } | undefined) => void;
    translates: IApiLangValue[] | undefined;
    keyValue?: keyof IApiLangValue;
    withTextArea?: boolean;
}

export const LangEditor: React.FC<ILangEditorProps> = ({handlerChange, translates, keyValue, withTextArea}) => {
    const langAvailable = useSelector(selectLangAvailable);
    const [form, setForm] = useState<ILangEditorValue[]>([]);
    const langKey: keyof IApiLangValue = keyValue || 'value';

    const getValue = (id: number) => form.find((e) => e.lang === id)?.value;
    const changeForm = (lang: number, value: string) => {
        const changes = form.map((e) => {
            if (e.lang === lang) e.value = value;
            return e;
        });
        setForm(changes);
    };

    useEffect(() => {
        if (translates && langAvailable.length > 0 && form.length === 0) {
            setForm(langAvailable.map((lang) => (
                {
                    lang: lang.id,
                    value: String(translates.find((translate) => Number(translate.lang) === Number(lang.id))?.[langKey] || '')
                })
            ));
        }
    }, [langAvailable, form, translates]);

    return !withTextArea
        ? (
            <div className={'lang-editor-container'}>
                {langAvailable.map((lang) => {
                    return <Input key={lang.id}
                                  value={getValue(lang.id)}
                                  placeholder={ts(lang.name)}
                                  label={lang.code.toUpperCase()}
                                  handlerChange={(value: any) => changeForm(lang.id, value)}
                                  handlerBlur={() => handlerChange(form.find((e) => e.lang === lang.id))}

                    />
                })}
            </div>)
        : (
            <div className={'lang-editor-container'}>
                <Row>
                    {langAvailable.map((lang) => {
                        return <Col xs={24} sm={24} md={24} lg={24}>
                            <TextEditor key={lang.id}
                                      value={getValue(lang.id)}
                                      placeholder={ts(lang.name)}
                                      label={lang.code.toUpperCase()}
                                      handlerChange={(value: any) => changeForm(lang.id, value)}
                                      handlerBlur={() => handlerChange(form.find((e) => e.lang === lang.id))}
                                      fullWidth
                            />
                        </Col>
                    })}
                </Row>
            </div>
        )
};


