import React, {useState} from 'react';
import {SliderHomePageForm} from "./SliderHomePageForm";
import {SliderHomePageTable} from "./SliderHomePageTable";
import {Divider} from "antd";
import {ts} from "../../../../translations/helpers";

interface IProps {
    countryId: number;
    shouldUpdate: boolean;
    setShouldUpdate: (status: boolean) => void;
}

export const SliderHomePage: React.FC<IProps> = ({ countryId, shouldUpdate, setShouldUpdate }) => {

    return (
        <div className={'slider-settings-container'}>
            <Divider children={ts('Додати елемент слайдера')} orientation="left" orientationMargin="0"/>
            <SliderHomePageForm countryId={countryId} handlerUpdate={setShouldUpdate}/>
            <Divider children={ts('Завантажені елементи слайдера')} orientation="left" orientationMargin="0"/>
            <SliderHomePageTable countryId={countryId} shouldUpdate={shouldUpdate} handlerUpdate={setShouldUpdate}/>
        </div>
    );
};
