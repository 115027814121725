export const ua = {
    'Hello world !': 'Привіт світ !',
    'Головна': 'Головна',
    'Товари': 'Товари',
    'Усі товари': 'Усі товари',
    'Категорії': 'Категорії',
    'Атрибути': 'Атрибути',
    'Теги': 'Теги',
    'Замовлення': 'Замовлення',
    'Склади': 'Склади',
    'Усі склади': 'Усі склади',
    'Історія руху товарів': 'Історія руху товарів',
    'Дані по складам': 'Дані по складам',
    'Дії з складами': 'Дії з складами',
    'Надходження': 'Надходження',
    'Списання': 'Списання',
    'Переміщення': 'Переміщення',
    'Резервування': 'Резервування',
    'Поле повинно бути заповненим': 'Поле повинно бути заповненим',
    'Аналітика': 'Аналітика',
    'Необхідно обрати ': 'Необхідно обрати ',
    'Налаштування': 'Налаштування',
    'Увійти': 'Увійти',
    'Запам\'ятати мене': 'Запам\'ятати мене',
    'Профіль': 'Профіль',
    'Додати': 'Додати',
    'Вийти': 'Вийти',
    'Основні категорії для товарів': 'Основні категорії для товарів',
    'Пароль': 'Пароль',
    'Логін або e-mail': 'Логін або e-mail',
    'Налаштування магазину': 'Налаштування магазину',
    'Виробники': 'Виробники',
    'Менеджери з продажу': 'Менеджери з продажу',
    'Надіслати інвойс': 'Надіслати інвойс',
    'Успішно': 'Успішно',
    'Інвойс був надісланий повторно': 'Інвойс був надісланий повторно',
    'Файли/документи': 'Файли/документи',
    'Помилка': 'Помилка',
};
