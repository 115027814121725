import {ReactNode} from "react";
import {MAIN_APP_ROUTES} from "../components/app-main/constants";
import {SORT_TYPES} from "./api";

export type ISort = {
    column: string,
    type: SORT_TYPES,
} | null;

export enum FILTER_TYPES {
    LIKE = 'LIKE',
    EQUAL = 'EQUAL',
    IN = 'IN',
}

export enum DIRECTORIES {
    COUNTRY = 'country',
    TAX = 'tax',
    MANUFACTURER = 'manufacturer',
}

export interface ISelectItem {
    label: string;
    value: any;
}

export interface IMenuItem {
    key: MAIN_APP_ROUTES;
    label: string;
    element?: ReactNode;
    icon?: ReactNode;
    children?: IMenuItem[];

}

export interface IPaginationData {
    page: number;
    pageSize: number;
}

export interface IFilter {
    type: FILTER_TYPES,
    column: string,
    value: string | number | string[] | number []
}

export interface IUser extends IReduxDataByIdsOptions {
    id: number;
    name: string;
    surname: string;
    middle_name: string;
    email: string;
    signatory: boolean;
    active?: boolean;
    role: number[],
    created_at?: number;
    updated_at?: number;
}

export interface IUserCompany {
    id: number;
    name: string;
    email: string;
    currency_id: string;
    logo: string | null;

}

export interface ILang {
    id: number;
    name: string;
    code: string;
}

export interface IReduxDataByIdsOptions {
    enabled?: boolean;
}

export interface ICountry {
    id: number;
    name: string;
}

export interface ITax {
    id: number;
    name: string;
}

export interface IManufacturer {
    id: number;
    name: string;
    created_at?: number;
    updated_at?: number;
}

export interface IFile {
    id: number;
    link: string;
    name: string;
    server_link: string;
    size: number;
    type: string;
    created_at: number;
    created_by: number;
    updated_at: number;

}

export enum FILE_UPLOADS_TYPE {
    IMAGE = 'IMAGE',
    FILE = 'FILE',
    LOGO = 'LOGO',
}

export enum ATTRIBUTES_TYPES {
    SELECT = 'SELECT',
    INPUT = 'INPUT',
}

export interface ICurrency {
    id: number;
    name: string;
    symbol: string;
}