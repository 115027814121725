import React from "react";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {ProductForm} from "../ProductForm";
import {Directories} from "../../../app-main/components/Wrappers/Directories/Directories";

export const ProductCreate = () => {
    const { breadcrumbs } = useActiveRoute();

    return (
        <Directories manufacturer>
            <div className={'product-form-container'}>
                <Breadcrumbs items={breadcrumbs} extra={ts('Створити продукт')}/>
                <PageHeader
                    title={ts('Створити продукт')}
                />
                <ProductForm create/>
            </div>
        </Directories>
    );
};