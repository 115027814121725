import React, {useEffect, useState} from 'react';
import {IOrder} from "../../../../interfaces/order";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Divider, Descriptions} from "antd";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useParams} from "react-router";
import {ApiOrder} from "../../../../api/endpoints/order";
import {OrdersInfoMain} from "./OrdersInfoMain";
import {OrdersStatusSelector} from "../OrdersStatusSelector";
import {OrdersInfoComment} from "./OrdersInfoComment";
import {OrdersInfoCustomer} from "./OrdersInfoCustomer";
import {OrdersInfoCompany} from "./OrdersInfoCompany";
import {OrdersInfoProducts} from "./OrdersInfoProducts";
import {OrdersInfoStatuses} from "./OrdersInfoStatuses";

export const OrdersInfo: React.FC = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [order, setOrder] = useState<IOrder | null>(null);
    const [needUpdate, setNeedUpdate] = useState<boolean>(true);

    const triggerUpdate = () => { setNeedUpdate(true); };

    useEffect(() => {
        if (id && needUpdate) {
            ApiOrder.getOne(id)
                .then((order: IOrder) => {
                    setOrder(order);
                    setNeedUpdate(false);
                });
        }
    }, [id, needUpdate]);

    const pageTitle = `${ts('Перегляд замовлення')} №${id}`;

    return (
        <div className={'company-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={pageTitle}/>
            <PageHeader
                title={pageTitle}
                extra={<OrdersStatusSelector status={order?.status || ''} order_id={String(order?.id)} handlerUpdate={triggerUpdate}/>}
            />
            {order && (
                <>
                    <Divider children={ts('Основна інформація')} orientation="left" orientationMargin="0"/>
                    <OrdersInfoMain order={order}/>
                    {order.comment && (
                        <>
                            <Divider children={ts('Коментар')} orientation="left" orientationMargin="0"/>
                            <OrdersInfoComment comment={order.comment}/>
                        </>
                    )}
                    {order.orderInfo && (
                        <>
                            <Divider children={ts('Дані про клієнта')} orientation="left" orientationMargin="0"/>
                            <OrdersInfoCustomer email={order.orderInfo.customer_email}
                                                phone={order.orderInfo.customer_phone}
                                                name={order.orderInfo.customer_name}
                                                surname={order.orderInfo.customer_surname}
                                                middle_name={order.orderInfo.customer_middle_name}
                            />
                            {order.orderInfo.company_name && (
                                <>
                                    <Divider children={ts('Дані про компанію')} orientation="left" orientationMargin="0"/>
                                    <OrdersInfoCompany name={order.orderInfo.company_name}
                                                       code={order.orderInfo.company_code}
                                                       address={order.orderInfo.company_address}
                                                       pdvCode={order.orderInfo.company_pdv_code}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <Divider children={ts('Товари у замовлені')} orientation="left" orientationMargin="0"/>
                    <OrdersInfoProducts products={order.products} currency={order.currency_id} amount={order.amount}/>
                    {order.statuses && (
                        <>
                            <Divider children={ts('Історія змін статусів замовлення')} orientation="left" orientationMargin="0"/>
                            <OrdersInfoStatuses statuses={order.statuses} />
                        </>
                    )}
                </>
            )}
        </div>
    );
};
