import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoading} from "../../store/reducers/order/selectors";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {Route, Routes} from "react-router";
import {ORDERS_ROUTES} from "./constants";
import {OrdersTable} from "./components/OrdersTable";
import {OrdersUpdate} from "./components/OrdersUpdate";
import {OrdersInfo} from "./components/OrdersInfo";

export const OrdersApp: React.FC = () => {
    const isLoading = useSelector(selectIsLoading);

    return (
        <div className={'company-container'}>
            <Loading isLoading={isLoading}>
                <Routes>
                    <Route path={ORDERS_ROUTES.EDIT} element={<OrdersUpdate/>}/>
                    <Route path={ORDERS_ROUTES.SHOW} element={<OrdersInfo/>}/>
                    <Route path={'/'} element={<OrdersTable/>}/>
                </Routes>
            </Loading>
        </div>
    );
}
