import {apiRequest} from "../instance";
import {ENDPOINTS} from "../constants";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {ICurrency} from "../../interfaces/main";

export const getCurrencies = async (): Promise<ICurrency[]> => {
    const res = await apiRequest(`/${ENDPOINTS.CURRENCY}`);
    return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data?.data
        ? res.data.data
        : [];
};
