import {useLocation} from "react-router-dom";
import {IMenuItem} from "../interfaces/main";
import {APP_ITEMS} from "../components/app-main/components/Menu/Menu";
import {MAIN_APP_ROUTES} from "../components/app-main/constants";
import {useMemo} from "react";

const searchSubMenuKey = (item: IMenuItem, key: string): string => {
    let sub = '';
    item.children?.forEach(( child ) => {
        if (child.key === key) sub = item.key;
        if (child.children) {
            const search = searchSubMenuKey(child, key);
            if (search) sub = search;
        }
    });
    return sub;
};

const findMenuItem = (item: IMenuItem, key: string): IMenuItem | null => {
    let app_item = null;
    if (item.key === key) return item;
    item.children?.forEach(( child ) => {
        if (child.key === key) app_item = child;
        if (child.children) {
            const search = findMenuItem(child, key);
            if (search) app_item = search;
        }
    });
    return app_item;
};

const getActiveSubMenus = (sub: string[]): string[] => {
    let submenus: string[] = [];
    APP_ITEMS.forEach((item) => {
        const res = searchSubMenuKey(item, sub[sub.length - 1]);
        if (res) {
            submenus.push(res);
            submenus = [...submenus, ...getActiveSubMenus(submenus)]
        }
    });
    return submenus;
};

const getBredcrumbs = (routes: string[], active: string) => {
    const active_routes: string[] = [
        MAIN_APP_ROUTES.DASHBOARD,
        ...routes,
        active
    ];
    const breadcrumbs_routes: IMenuItem[] = [];
    active_routes.forEach((route) => {
        APP_ITEMS.forEach((item) => {
            const menu_item = findMenuItem(item, route);
            if (menu_item) breadcrumbs_routes.push(menu_item)
        })
    });

    return breadcrumbs_routes.map((menuItem) => {
        return {
            path: menuItem?.key,
            element: menuItem?.element,
            breadcrumbName: menuItem?.label,
        }
    })
}
       
function useActiveRoute() {
    const location = useLocation();
    const active = location.pathname.split('/')[1];
    const sub_routes = useMemo(() => {
        return getActiveSubMenus([active])
    }, [active]);
    const breadcrumbs =  useMemo(() => {
        return getBredcrumbs(sub_routes, active)
    }, [active]);
    return {
        active,
        sub_routes,
        breadcrumbs,
    }
}

export { useActiveRoute };
