import {ICompanyForm} from "../../../../interfaces/company";
import React, {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import {COMPANY_FORM_FIELDS, INITIAL_COMPANY_FORM} from "../../../app-company/constants";
import {validateEmail} from "../../../../helpers/validators";
import {ts} from "../../../../translations/helpers";
import {setLoading} from "../../../../store/reducers/attribute/reducer";
import {Button, Form, notification} from "antd";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {Input} from "../../../components-base/Input/Input";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {IAttributeForm} from "../../../../interfaces/attribute";
import {ATTRIBUTE_FORM_FIELDS, INITIAL_ATTRIBUTE_FORM} from "../../constants";
import {ApiAttribute} from "../../../../api/endpoints/attributes";
import {INPUT_TYPES} from "../../../../interfaces/form";
import {ATTRIBUTES_TYPES} from "../../../../interfaces/main";

interface IAttributeFormProps {
    create?: boolean;
    id?: string | undefined;
    attribute?: IAttributeForm;
    triggerUpdate?: () => void;
}

export const AttributeForm: React.FC<IAttributeFormProps> = ({ create, id, attribute, triggerUpdate }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<IAttributeForm>(INITIAL_ATTRIBUTE_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<ATTRIBUTE_FORM_FIELDS, any>>({} as Record<ATTRIBUTE_FORM_FIELDS, any>)

    const changeForm = (key: keyof IAttributeForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<string, any> = {};

        if (create) {
            if (!form[ATTRIBUTE_FORM_FIELDS.NAME]) errors[ATTRIBUTE_FORM_FIELDS.NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        }
        if (!form[ATTRIBUTE_FORM_FIELDS.TYPE]) errors[ATTRIBUTE_FORM_FIELDS.TYPE] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await handlerCreate() : await handlerUpdate();
            dispatch(setLoading(false));
        }
    };

    const handlerCreate = async () => {
        const res = await ApiAttribute.create(form);
        if (res) {
            notification.success({
                message: ts('Атрибут успішно створений!'),
            });
            navigate('/' + MAIN_APP_ROUTES.ATTRIBUTES);
        }
    };

    const handlerUpdate = async () => {
        if (!id) return;
        const res = await ApiAttribute.update(form, id);
        triggerUpdate && triggerUpdate();
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (attribute) setForm(attribute);
    }, [attribute]);

    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            {create && (
                <Input value={form[ATTRIBUTE_FORM_FIELDS.NAME]}
                       placeholder={ts('Введіть назву')}
                       label={ts('Назва')}
                       handlerChange={(value: any) => changeForm(ATTRIBUTE_FORM_FIELDS.NAME, value)}
                       errors={validateErrors?.[ATTRIBUTE_FORM_FIELDS.NAME]}
                       required
                />
            )}
            <Input value={form[ATTRIBUTE_FORM_FIELDS.SORT]}
                   placeholder={ts('Введіть порядок сортування')}
                   label={ts('Сортування')}
                   type={INPUT_TYPES.NUMBER}
                   handlerChange={(value: any) => changeForm(ATTRIBUTE_FORM_FIELDS.SORT, value)}
                   errors={validateErrors?.[ATTRIBUTE_FORM_FIELDS.SORT]}
            />
            <Autocomplete name={'type'}
                          label={ts('Тип атрибута')}
                          value={form[ATTRIBUTE_FORM_FIELDS.TYPE]}
                          placeholder={ts('Оберіть тип')}
                          optionsMapper={(data: ATTRIBUTES_TYPES) => ({ value: data, id: data })}
                          items={Object.values(ATTRIBUTES_TYPES)}
                          errors={validateErrors?.[ATTRIBUTE_FORM_FIELDS.TYPE]}
                          onChange={(value: any) => changeForm(ATTRIBUTE_FORM_FIELDS.TYPE, value)}
            />
            <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
        </Form>
    );
};