import React, {useState} from 'react';
import {Button, Col, Form, Row, Input, Divider, notification} from "antd";
import {ts} from "../../../../../translations/helpers";
import {ApiProduct} from "../../../../../api/endpoints/product";
import {Autocomplete} from "../../../../components-base/Autocomplete/Autocomplete";
import {IProduct} from "../../../../../interfaces/product";
import {ApiOrder} from "../../../../../api/endpoints/order";

interface IOrdersFormAddProductProps {
    order_id: number;
    handlerUpdate: () => void;
}

export const OrdersFormAddProduct: React.FC<IOrdersFormAddProductProps> = ({ order_id, handlerUpdate }) => {
    const [productId, setProductId] = useState<number | null>(null);
    const [price, setPrice] = useState<number | null>(null);

    const handleSubmit = (values: any) => {
        if (productId && price) {
            ApiOrder.addProduct({ product_id: String(productId), count: 1, price: price }, String(order_id))
                    .then((res) => {
                        if (res.id) {
                            notification.success({
                                message: ts('Товар успішно додано'),
                            });
                            handlerUpdate && handlerUpdate();
                        } else {
                            notification.error({
                                message: ts('Щось пішло не так'),
                            });
                        }
                    });
        }

    };

    return (
        <>
            <Divider children={ts('Додати товар в замовлення')} orientation="left" orientationMargin="0"/>
            <Form layout={'horizontal'} onFinish={handleSubmit}>
                <Row>
                    <Col md={8}>
                        <Autocomplete name={'product_id'}
                                      value={productId}
                                      placeholder={ts('Оберіть товар')}
                                      optionsMapper={(data: IProduct) => ({ value: data.name, id: data.id })}
                                      getData={() => ApiProduct.getAll({ filters: [], page: 1, pageSize: 9999})}
                                      onChange={(value: any) => setProductId(value)}
                        />
                    </Col>
                    <Col md={8}>
                        <Input value={price || ''}
                               placeholder={ts('Введіть ціну')}
                               onChange={(value) => setPrice(Number(value.target.value))}
                               style={{ maxWidth: '300px' }}
                               type={'number'}
                               required
                        />
                    </Col>
                    <Col md={8}>
                        <Button type={'primary'} htmlType={'submit'}>{ts('Додати до замовлення')}</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
