export const DEFAULT_PAGE_SIZE = 30;
export const DEFAULT_LANG = 'ua';

export enum REDUCERS {
    COMMON = 'common',
    CATEGORY = 'category',
    USER = 'user',
    CLIENT = 'client',
    ORDER = 'order',
    PRODUCT = 'product',
    WAREHOUSE = 'warehouse',
    ATTRIBUTE = 'attribute',
    TAG = 'tag',
    COMPANY = 'company',
}
