import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectIsLoading, selectWarehouseProducts} from "../../store/reducers/warehouse/selectors";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {Route, Routes} from "react-router-dom";
import {WAREHOUSES_ROUTES} from "./constants";
import {WarehouseReceiving} from "./components/WarehouseReceiving";
import {useAppDispatch} from "../../store/store";
import {getWarehouseProducts} from "../../store/reducers/warehouse/actions";
import {WarehouseProducts} from "./components/WarehouseProducts";
import {WarehouseWriteOff} from "./components/WarehouseWriteOff";

export const WarehouseApp: React.FC = () => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(selectIsLoading);

    useEffect(() => {
        dispatch(getWarehouseProducts({ companyId: null }));
    }, []);

    return (
        <div className={'company-container'}>
            <Loading isLoading={isLoading}>
                <Routes>
                    <Route path={WAREHOUSES_ROUTES.RECEIVING} element={<WarehouseReceiving/>}/>
                    <Route path={WAREHOUSES_ROUTES.WRITE_OFF} element={<WarehouseWriteOff/>}/>
                    <Route path={'/'} element={<WarehouseProducts/>}/>
                </Routes>
            </Loading>
        </div>
    );
}