import React from "react";
import {useNavigate} from "react-router-dom";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {IWarehouseActionProduct} from "../../../../interfaces/warehouse";
import {ApiWarehouse} from "../../../../api/endpoints/warehouse";
import {notification} from "antd";
import {ts} from "../../../../translations/helpers";
import {getRoute} from "../../constants";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {WarehouseActionForm} from "../WarehouseActionForm";

interface IProps {

}

export const WarehouseWriteOff: React.FC<IProps> = () => {
    const navigate = useNavigate();
    const { breadcrumbs } = useActiveRoute();

    const handlerSubmit = (products: IWarehouseActionProduct[], comment: string) => {
        ApiWarehouse.writeOff(products, comment).then((res) => {
            if (res) {
                notification.success({
                    message: ts('Списання успішно створене!'),
                });
                navigate(getRoute(''));
            } else {

            }
        })
    };

    return (
        <div className={'warehouse-receiving-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Створити списання товарів')}/>
            <PageHeader
                title={ts('Створити списання товарів')}
            />
            <WarehouseActionForm handlerSubmit={handlerSubmit}/>
        </div>
    );
}