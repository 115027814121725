import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoading} from "../../store/reducers/users/selectors";
import {Route, Routes} from "react-router-dom";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {UserTable} from "./components/UserTable";
import {UserCreate} from "./components/UserCreate";
import {USERS_ROUTES} from "./constants";
import {UserUpdate} from "./components/UserUpdate";

export const UserApp: React.FC = () => {
    const isLoading = useSelector(selectIsLoading);
    return (
        <div className={'users-container'}>
            <Loading isLoading={isLoading}>
                <Routes>
                    <Route path={USERS_ROUTES.CREATE} element={<UserCreate/>}/>
                    <Route path={USERS_ROUTES.EDIT} element={<UserUpdate />}/>
                    <Route path={'/'} element={<UserTable/>}/>
                </Routes>
            </Loading>
        </div>
    );
}