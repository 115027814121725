import React, {useEffect} from 'react';
import {IOrderProduct, IOrderProductLot} from "../../../../../interfaces/order";
import {RootState, useAppDispatch} from "../../../../../store/store";
import {useSelector} from "react-redux";
import {selectCurrencyName} from "../../../../../store/reducers/common/selectors";
import {selectProductsById} from "../../../../../store/reducers/product/selectors";
import {ts} from "../../../../../translations/helpers";
import {loadProducts} from "../../../../../store/reducers/product/actions";
import {Button, Space, Table, Input, notification, Divider} from "antd";
import {DeleteOutlined} from "@ant-design/icons/lib";
import {OrdersFormAddProduct} from "./OrdersFormAddProduct";
import {OrdersFormAddLot} from "./OrderFormAddLot";
import {ApiOrder} from "../../../../../api/endpoints/order";

interface IOrdersFormProducts {
    products?: IOrderProduct[];
    currency?: number | null;
    amount?: number;
    order_id: number;
    handlerUpdate: () => void;
}

export const OrdersFormProducts: React.FC<IOrdersFormProducts> = ({ products = [], currency: currencyId, amount, order_id, handlerUpdate }) => {
    const dispatch = useAppDispatch();
    const currency = useSelector((state: RootState) => selectCurrencyName(state, String(currencyId)));
    const productsData = useSelector(selectProductsById);

    const handleDeleteProduct = (product_id: number): void => {
        if (product_id) {
            ApiOrder.deleteProduct(String(product_id), String(order_id))
                    .then((res) => {
                        if (res) {
                            notification.success({
                                message: ts('Товар успішно видалено'),
                            });
                            handlerUpdate && handlerUpdate();
                        }
                    });
        }
    };

    const handleProductCountChanged = (count: number, product: IOrderProduct): void => {
        if (count > 0) {
            ApiOrder.changeProduct(
                {
                    order_product_id: String(product.id),
                    count: count,
                    price: product.price,
                    product_id: String(product.product_id),
                },
                String(order_id)
            ).then((res) => {
                if (res) {
                    notification.success({
                        message: ts('Кількість товару успішно змінено'),
                    });
                    handlerUpdate && handlerUpdate();
                }
            });
        } else {
            notification.error({
                message: ts('Кількість товару не може бути менше 1'),
            });
        }
    };

    const handleProductPriceChanged = (price: number, product: IOrderProduct): void => {
        if (price > 0) {
            ApiOrder.changeProduct(
                {
                    order_product_id: String(product.id),
                    count: product.count,
                    price: price,
                    product_id: String(product.product_id),
                },
                String(order_id)
            ).then((res) => {
                if (res) {
                    notification.success({
                        message: ts('Ціна товару успішно змінено'),
                    });
                    handlerUpdate && handlerUpdate();
                }
            });
        } else {
            notification.error({
                message: ts('Кількість товару не може бути менше 1'),
            });
        }
    };

    const handleDeleteLot = (id: number): void => {
        if (id) {
            ApiOrder.deleteProductLot(String(id), String(order_id))
                .then((res) => {
                    if (res) {
                        notification.success({
                            message: ts('Лот успішно видалено'),
                        });
                        handlerUpdate && handlerUpdate();
                    }
                });
        }
    };

    const handleLotCountChanged = (order_product_lot: IOrderProductLot, count: number): void => {
        if (count > 0) {
            ApiOrder.changeProductLot({
                order_product_lot_id: order_product_lot.id,
                count: count,
                lot_id: order_product_lot.lot_id,
            }, String(order_id)).then((res) => {
                if (res) {
                    notification.success({
                        message: ts('Кількість товару з лота успішно змінено'),
                    });
                    handlerUpdate && handlerUpdate();
                }
            })
        } else {
            notification.error({
                message: ts('Кількість товару не може бути менше 1'),
            });
        }
    };

    const columns = [
        {
            title: ts('Назва товару'),
            dataIndex: 'name',
            key: 'name',
            render: (value: number, _: IOrderProduct) => productsData[_.product_id]?.name
        },
        {
            title: ts('Модель'),
            dataIndex: 'model',
            key: 'model',
            render: (value: number, _: IOrderProduct) => productsData[_.product_id]?.model
        },
        {
            title: ts('Кількість'),
            dataIndex: 'count',
            key: 'count',
            render: (value: number, _: IOrderProduct) => (
                <Input placeholder={ts('Кількість')}
                       defaultValue={value}
                       onBlur={(event) => handleProductCountChanged(Number(event.target.value), _)}
                       min={1}
                       type={'number'}
                       max={9999}
                       required
                />
            )
        },
        {
            title: ts('Ціна'),
            dataIndex: 'price',
            key: 'price',
            render: (value: number, _: IOrderProduct) => (
                <Input placeholder={ts('Ціна')}
                       defaultValue={value}
                       onBlur={(event) => handleProductPriceChanged(Number(event.target.value), _)}
                       min={1}
                       type={'number'}
                       max={9999}
                       required
                />
            )
        },
        {
            title: ts('Сума'),
            dataIndex: 'amount',
            key: 'amount',
            render: (value: number, _: IOrderProduct) => `${_.price * _.count} ${currency}`
        },
        {
            title: ts('Дії'),
            dataIndex: 'id',
            key: 'id',
            render: (value: number, _: IOrderProduct) => {
                return <Space>
                    <Button type={'primary'} onClick={() => handleDeleteProduct(_.id)} danger><DeleteOutlined /></Button>
                </Space>;
            },
        }
    ];

    const lotColumns = [
        {
            title: ts('Назва лоту'),
            dataIndex: 'lot',
            key: 'lot',
        },
        {
            title: ts('Кількість'),
            dataIndex: 'count',
            key: 'count',
            render: (value: number, _: IOrderProductLot) => (
                <Input placeholder={ts('Кількість')}
                       defaultValue={value}
                       onBlur={(event) => handleLotCountChanged(_, Number(event.target.value))}
                       min={1}
                       type={'number'}
                       max={9999}
                       required
                />
            )
        },
        {
            title: ts('Термін придатності'),
            dataIndex: 'date_to',
            key: 'date_to',
        },
        {
            title: ts('Дії'),
            dataIndex: 'id',
            key: 'id',
            render: (value: number, _: IOrderProductLot) => {
                return <Space>
                    <Button type={'primary'} onClick={() => handleDeleteLot(value)} danger><DeleteOutlined /></Button>
                </Space>;
            },
        }
    ];

    useEffect(() => {
        if (products) {
            dispatch(loadProducts({ ids: products.map((product: IOrderProduct) => product.product_id) }));
        }
    }, []);

    return (
        <>
            <OrdersFormAddProduct order_id={order_id} handlerUpdate={handlerUpdate}/>
            <Divider children={ts('Товари в замовленні')} orientation="left" orientationMargin="0"/>
            <Table dataSource={products}
                   columns={columns}
                   rowKey={(record) => record.id}
                   footer={() => (
                       <div style={{ textAlign: 'right' }}>
                           <span>{ts('Загальна сума')}:</span> <b>{amount} {currency}</b>
                       </div>
                   )}
                   expandable={{
                       expandedRowRender: record => {
                           return (
                               <>
                                   <OrdersFormAddLot order_id={order_id}
                                                     product_id={record.product_id}
                                                     order_product_id={record.id}
                                                     handlerUpdate={handlerUpdate}
                                   />
                                   <Table columns={lotColumns} dataSource={record.lot} rowKey={String(record.id)}/>
                               </>
                           );
                       },
                       rowExpandable: record => Boolean(record?.lot),
                   }}
            />
        </>
    );
};
