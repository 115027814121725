import React, {useMemo} from 'react';
import {Menu as AntMenu} from "antd";
import {useNavigate} from 'react-router-dom';
import {
    BankOutlined,
    CodeSandboxOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    FileDoneOutlined,
    FundProjectionScreenOutlined,
    MergeCellsOutlined,
    UserSwitchOutlined,
    OrderedListOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    ContainerOutlined,
} from '@ant-design/icons';
import {DIRECTORIES, IMenuItem} from "../../../../interfaces/main";
import {MAIN_APP_ROUTES} from "../../constants";
import {ts} from "../../../../translations/helpers";
import {DashboardApp} from "../../../app-dashboard/DashboardApp";
import {CategoryApp} from "../../../app-category/CategoryApp";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import authManager from "../AuthManager/AuthManager";
import {UserApp} from "../../../app-users/UsersApp";
import {CompanyApp} from "../../../app-company/CompanyApp";
import {TagApp} from "../../../app-tag/TagApp";
import {AttributeApp} from "../../../app-attribute/AttributeApp";
import {ProductApp} from "../../../app-product/ProductApp";
import {DirectoryApp} from "../../../app-directory/DirectoryApp";
import {ClientApp} from "../../../app-client/ClientApp";
import {WarehouseApp} from "../../../app-warehouse/WarehouseApp";
import {SlidersApp} from "../../../app-sliders/SlidersApp";
import {OrdersApp} from "../../../app-orders/OrdersApp";

export const APP_ITEMS: IMenuItem[] = [
    {
        key: MAIN_APP_ROUTES.DASHBOARD,
        icon: <DashboardOutlined />,
        label: ts('Головна'),
        element: <DashboardApp/>
    },
    {
        key: MAIN_APP_ROUTES.PRODUCTS,
        icon: <CodeSandboxOutlined />,
        label: ts('Товари'),
        children: [
            { key: MAIN_APP_ROUTES.PRODUCTS_ALL, label: ts('Усі товари'), element: <ProductApp/> },
            { key: MAIN_APP_ROUTES.CATEGORIES, label: ts('Категорії'), element: <CategoryApp/> },
            { key: MAIN_APP_ROUTES.ATTRIBUTES, label: ts('Атрибути'), element: <AttributeApp/> },
            { key: MAIN_APP_ROUTES.TAGS, label: ts('Теги'), element: <TagApp/> },
        ]
    },
    {
        key: MAIN_APP_ROUTES.USERS,
        icon: <UsergroupAddOutlined />,
        label: ts('Користувачі'),
        element: <UserApp/>
    },
    {
        key: MAIN_APP_ROUTES.DIRECTORIES,
        icon: <OrderedListOutlined />,
        label: ts('Довідники'),
        children: [
            { key: MAIN_APP_ROUTES.DIRECTORIES_COUNTRY, label: ts('Країни'), element: <DirectoryApp directory={DIRECTORIES.COUNTRY}/> },
            { key: MAIN_APP_ROUTES.DIRECTORIES_TAX, label: ts('Податки'), element: <DirectoryApp directory={DIRECTORIES.TAX}/> },
            { key: MAIN_APP_ROUTES.DIRECTORIES_MANUFACTURER, label: ts('Виробники'), element: <DirectoryApp directory={DIRECTORIES.MANUFACTURER}/> },
        ]
    },
    {
        key: MAIN_APP_ROUTES.COMPANIES,
        icon: <BankOutlined />,
        label: ts('Компанії'),
        element: <CompanyApp/>
    },
    {
        key: MAIN_APP_ROUTES.CLIENTS,
        icon: <UserSwitchOutlined />,
        label: ts('Клієнти'),
        element: <ClientApp/>
    },
    {
        key: MAIN_APP_ROUTES.ORDERS,
        icon: <ContainerOutlined />,
        label: ts('Замовлення'),
        element: <OrdersApp/>
    },
    {
        key: MAIN_APP_ROUTES.SLIDERS,
        icon: <MergeCellsOutlined />,
        label: ts('Слайдери'),
        element: <SlidersApp/>
    },
    {
        key: MAIN_APP_ROUTES.WAREHOUSES,
        icon: <DatabaseOutlined />,
        label: ts('Склади'),
        element: <WarehouseApp/>,
        // children: [
        //     { key: MAIN_APP_ROUTES.WAREHOUSES, label: ts('Дані по складу')},
        //     { key: MAIN_APP_ROUTES.WAREHOUSES_HISTORY, label: ts('Історія руху товарів') },
        //     { key: MAIN_APP_ROUTES.WAREHOUSES_ACTIONS, label: ts('Дії з складами'), children: [
        //             { key: MAIN_APP_ROUTES.WAREHOUSES_ACTION_RECEIVING, label: ts('Надходження') },
        //             { key: MAIN_APP_ROUTES.WAREHOUSES_ACTION_WRITE_OFF, label: ts('Списання') },
        //             { key: MAIN_APP_ROUTES.WAREHOUSES_ACTION_MOVING, label: ts('Переміщення') },
        //             { key: MAIN_APP_ROUTES.WAREHOUSES_ACTION_RESERVE, label: ts('Резервування') },
        //         ]
        //     },
        // ]
    },
    {
        key: MAIN_APP_ROUTES.ANALYTICS,
        icon: <FundProjectionScreenOutlined />,
        label: ts('Аналітика'),
    },
    {
        key: MAIN_APP_ROUTES.SETTINGS,
        icon: <SettingOutlined />,
        label: ts('Налаштування'),
        children: [
            { key: MAIN_APP_ROUTES.COMPANY_SETTING, label: ts('Налаштування магазину') },
            { key: MAIN_APP_ROUTES.MANUFACTURER, label: ts('Виробники') },
            { key: MAIN_APP_ROUTES.SELL_MANAGER, label: ts('Менеджери з продажу') },
        ]
    },
];

const checkAccess = (items: IMenuItem[]): IMenuItem[] => {
    let accepted: IMenuItem[] = [];

    items.forEach((item) => {
       if (authManager.getAccess(item.key)) {
           if (item.children) item.children = checkAccess(item.children);
           accepted.push(item);
       }
    });

    return accepted;
};

export const Menu:React.FC = () => {
    const navigate = useNavigate();
    const { active, sub_routes } = useActiveRoute();
    const menuItems = useMemo(() => checkAccess(APP_ITEMS), [APP_ITEMS]);
    return (
        <AntMenu
            // theme="dark"
            mode="inline"
            defaultSelectedKeys={[active]}
            defaultOpenKeys={sub_routes}
            items={menuItems}
            onClick={(item) => navigate('/' + item.key)}
        />
    );
}
