import React, {useEffect} from 'react';
import {IOrderProduct} from "../../../../interfaces/order";
import {Space, Table} from "antd";
import {ts} from "../../../../translations/helpers";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../store/store";
import {selectCurrencyName} from "../../../../store/reducers/common/selectors";
import {loadProducts} from "../../../../store/reducers/product/actions";
import {selectProductsById} from "../../../../store/reducers/product/selectors";

interface IOrdersInfoProductsProps {
    products?: IOrderProduct[];
    currency?: number;
    amount?: number;
}

export const OrdersInfoProducts: React.FC<IOrdersInfoProductsProps> = ({ products = [], currency: currencyId, amount }) => {
    const dispatch = useAppDispatch();
    const currency = useSelector((state: RootState) => selectCurrencyName(state, currencyId));
    const productsData = useSelector(selectProductsById);
    const columns = [
        {
            title: ts('Назва товару'),
            dataIndex: 'name',
            key: 'name',
            render: (value: number, _: IOrderProduct) => productsData[_.product_id]?.name
        },
        {
            title: ts('Модель'),
            dataIndex: 'model',
            key: 'model',
            render: (value: number, _: IOrderProduct) => productsData[_.product_id]?.model
        },
        {
            title: ts('Кількість'),
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: ts('Ціна'),
            dataIndex: 'price',
            key: 'price',
            render: (value: number, _: IOrderProduct) => `${value} ${currency}`
        },
        {
            title: ts('Сума'),
            dataIndex: 'amount',
            key: 'amount',
            render: (value: number, _: IOrderProduct) => `${_.price * _.count} ${currency}`
        },
    ];

    const lotColumns = [
        {
            title: ts('Назва лоту'),
            dataIndex: 'lot',
            key: 'lot',
        },
        {
            title: ts('Кількість'),
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: ts('Термін придатності'),
            dataIndex: 'date_to',
            key: 'date_to',
        },
    ];

    useEffect(() => {
        if (products) {
            dispatch(loadProducts({ ids: products.map((product: IOrderProduct) => product.product_id) }));
        }
    }, []);

    return (
        <Table dataSource={products}
               columns={columns}
               rowKey={(record) => record.id}
               footer={() => (
                   <div style={{ textAlign: 'right' }}>
                       <span>{ts('Загальна сума')}:</span> <b>{amount} {currency}</b>
                   </div>
               )}
               expandable={{
                   expandedRowRender: record => {
                       return <Table columns={lotColumns} dataSource={record.lot}/>
                   },
                   rowExpandable: record => record?.lot?.length > 0,
               }}
        />
    );
};
