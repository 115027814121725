import React, {useEffect, useState} from 'react';
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useParams} from "react-router-dom";
import {ICategory, ICategoryForm} from "../../../../interfaces/category";
import {ApiCategory} from "../../../../api/endpoints/category";
import {CATEGORY_FORM_FIELDS} from "../../../app-category/constants";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Divider} from "antd";
import {LangEditor} from "../../../components-base/LangEditor/LangEditor";
import {CategoryForm} from "../../../app-category/components/CategoryForm";
import {DirectoriesApi} from "../../../../api/endpoints/directory";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import {DIRECTORIES, ICountry, IManufacturer, ITax} from "../../../../interfaces/main";
import {DirectoryValueForm} from "../DirectoryValueForm";

interface IProps {
    directory: DIRECTORIES;
    handlerUpdate: () => void;
}

export const DirectoryUpdateValue: React.FC<IProps> = ({ directory, handlerUpdate }) => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [item, setItem] = useState<ITax | IManufacturer | ICountry | null>(null);

    useEffect(() => {
        if (id) {
            DirectoriesController.loadDirectory(directory).then((res) => {
                const resultItem = res.find((e: any) => String(e.id) === String(id));
                if (resultItem) setItem(resultItem);
            });
        }
    }, [id]);

    return (
        <div className={'company-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Редагування')}/>
            <PageHeader
                title={`${ts('Редагування елементу довідника ')} ${DirectoriesController.getDirectoryName(directory)}`}
            />
            <DirectoryValueForm directory={directory} handlerUpdate={handlerUpdate} directoryItem={item}/>
        </div>
    );
}