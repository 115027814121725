import {IFilter, ISort} from "./main";

export enum API_RESPONSE_CODE {
    SUCCESS = 1,
    FAILED = 0,
}

export interface IApiResponse<T> {
    message: string;
    code: API_RESPONSE_CODE;
    status: number
    data: T;
}

export interface IApiDataPaginationResponse<T> {
    count: number;
    page: number;
    pageSize: number;
    data: T;
    sort: ISort;
}

export interface IApiRequestData {
    filters?: IFilter[],
    page?: number,
    pageSize?: number
}

export interface IGetPaginatedDataParams {
    filters?: IFilter[],
    page?: number,
    pageSize?: number,
    sort?: ISort,
}

export enum SORT_TYPES {
    ASC = 'ASC',
    DESC = 'DESC',
}

export interface IApiLangValue {
    lang: number,
    value: string,
    name?: string;
    card_name?: string;
    description?: string;
    meta_title?: string;
    meta_keywords?: string;
    meta_description?: string;
}