import { combineReducers } from '@reduxjs/toolkit';
import commonSlice from "./reducers/common/reducer";
import categorySlice from "./reducers/category/reducer";
import userSlice from "./reducers/users/reducer";
import companySlice from "./reducers/company/reducer";
import tagSlice from "./reducers/tag/reducer";
import attributeSlice from "./reducers/attribute/reducer";
import productSlice from "./reducers/product/reducer";
import clientSlice from "./reducers/client/reducer";
import warehouseSlice from "./reducers/warehouse/reducer";
import orderSlice from "./reducers/order/reducer";
import {REDUCERS} from "../constants";

const rootReducer = combineReducers({
    [REDUCERS.COMMON]: commonSlice.reducer,
    [REDUCERS.CATEGORY]: categorySlice.reducer,
    [REDUCERS.USER]: userSlice.reducer,
    [REDUCERS.CLIENT]: clientSlice.reducer,
    [REDUCERS.WAREHOUSE]: warehouseSlice.reducer,
    [REDUCERS.ORDER]: orderSlice.reducer,
    [REDUCERS.TAG]: tagSlice.reducer,
    [REDUCERS.PRODUCT]: productSlice.reducer,
    [REDUCERS.ATTRIBUTE]: attributeSlice.reducer,
    [REDUCERS.COMPANY]: companySlice.reducer,
});

export default rootReducer;
