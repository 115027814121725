import {MAIN_APP_ROUTES} from "../app-main/constants";
import {IOrderForm, IOrderStatus, IStatusOrder} from "../../interfaces/order";

export const getRoute = (route: string): string => `/${MAIN_APP_ROUTES.ORDERS}/${route}`;

export enum ORDERS_ROUTES {
    CREATE = 'create',
    EDIT = ':id',
    SHOW = 'show/:id'
}

export enum ORDER_FORM_FIELDS {
    STATUS = 'status',
    CLIENT_ID = 'client_id',
    PAYMENT = 'payment',
    ADDRESS = 'address',
    CITY = 'city',
    CURRENCY = 'currency_id',
    AMOUNT = 'amount',
}

export enum ORDER_INFO_FORM_FIELDS {
    CUSTOMER_NAME = 'customer_name',
    CUSTOMER_SURNAME = 'customer_surname',
    CUSTOMER_MIDDLE_NAME = 'customer_middle_name',
    CUSTOMER_EMAIL = 'customer_email',
    CUSTOMER_PHONE = 'customer_phone',
    COMPANY_NAME = 'company_name',
    COMPANY_CODE = 'company_code',
    COMPANY_ADDRESS = 'company_address',
    COMPANY_PDV_CODE = 'company_pdv_code',
}

export const INITIAL_ORDER_FORM: IOrderForm = {
    [ORDER_FORM_FIELDS.STATUS]: null,
    [ORDER_FORM_FIELDS.CLIENT_ID]: null,
    [ORDER_FORM_FIELDS.PAYMENT]: null,
    [ORDER_FORM_FIELDS.CITY]: null,
    [ORDER_FORM_FIELDS.ADDRESS]: null,
};

export const ORDERS_STATUSES: IStatusOrder[] = [
    { key: 'NEW', color: 'green' },
    { key: 'AWAITING PAYMENT', color: 'cyan' },
    { key: 'PAID', color: 'blue' },
    { key: 'COMPLETED', color: 'purple' },
    { key: 'IN THE WAY', color: 'magenta'},
    { key: 'FINISHED', color: 'gold'},
    { key: 'CANCELED', color: 'volcano'},
];
