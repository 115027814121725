import {apiRequest} from "../instance";
import {API_METHODS, ENDPOINTS} from "../constants";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {IUser, IUserCompany} from "../../interfaces/main";

interface IGetLoginData {
    username: string;
    password: string;
    rememberMe: boolean
}

interface IAuthResponse extends IUser {
    permission: string[];
    company: IUserCompany;
}

export const getAuth = async (): Promise<IAuthResponse> => {
    const res = await apiRequest(`/${ENDPOINTS.STUFF}/auth`);
    return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data.id
        ? res.data
        : {} as IAuthResponse;
};

export const getLogin = async (data: IGetLoginData): Promise<string> => {
    const res = await apiRequest(`/${ENDPOINTS.AUTH}/login`, data, API_METHODS.POST );
    return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data?.token
        ? res.data.token
        : '';
};

