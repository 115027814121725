import React, {useEffect, useMemo} from "react";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {ts} from "../../../../translations/helpers";
import {Switch} from "../../../components-base/Switch/Switch";
import {formatDate} from "../../../../helpers/formaters";
import {Button, Space} from "antd";
import {useSelector} from "react-redux";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {IPaginationData, ISort} from "../../../../interfaces/main";
import {useAppDispatch} from "../../../../store/store";
import {Link, useNavigate} from "react-router-dom";
import {
    selectProducts,
    selectProductsById,
    selectProductsCount, selectProductsPage, selectProductsPageSize,
    selectProductsSort
} from "../../../../store/reducers/product/selectors";
import { setPagination, setSort } from "../../../../store/reducers/product/reducer";
import {deleteProduct, editProduct, getProducts} from "../../../../store/reducers/product/actions";
import {PRODUCT_FORM_FIELDS, PRODUCTS_ROUTES} from "../../constants";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {getRoute} from "../../constants";
import {Table} from "../../../components-base/Table/Table";
import authManager from "../../../app-main/components/AuthManager/AuthManager";
import {PERMISSIONS} from "../../../app-main/components/AuthManager/permissions";
import {isBoolean} from "util";

const getColumns = (
    handleChangeActive: (value: boolean, id: string) => void,
    handleChangePreorder: (value: boolean, id: string) => void,
    handleChangeIsNew: (value: boolean, id: string) => void,
    handlerDelete: (id: string) => void,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Назва'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: ts('Модель'),
            dataIndex: 'model',
            key: 'model',
            sorter: true,
        },
        // {
        //     title: ts('Аліас'),
        //     dataIndex: 'alias',
        //     key: 'alias',
        // },
        // {
        //     title: ts('Виробник'),
        //     dataIndex: 'producer',
        //     key: 'producer',
        //     sorter: true,
        // },
        {
            title: ts('Ціна'),
            dataIndex: 'price',
            key: 'price',
            sorter: true,
        },
        {
            title: ts('Активність'),
            dataIndex: 'active',
            key: 'active',
            render: (value: any, item: any) => {
                return <Switch checked={Boolean(Number(value))} handlerChange={(value) => handleChangeActive(value, item.id)} name={'active'}/>;
            }
        },
        {
            title: ts('Передзамовлення'),
            dataIndex: 'preorder',
            key: 'preorder',
            render: (value: any, item: any) => {
                return <Switch checked={Boolean(Number(value))} handlerChange={(value) => handleChangePreorder(value, item.id)} name={'preorder'}/>;
            }
        },
        {
            title: ts('Новинка'),
            dataIndex: 'isNew',
            key: 'isNew',
            render: (value: any, item: any) => {
                return <Switch checked={Boolean(Number(value))} handlerChange={(value) => handleChangeIsNew(value, item.id)} name={'isNew'}/>;
            }
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    <Button type={'default'} >
                        <Link to={`${item.id}`}><EditOutlined /></Link>
                    </Button>
                    {/*<Button><SelectOutlined /></Button>*/}
                    <Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>
                </Space>;
            }
        },


    ];
};

export const ProductTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const data = useSelector(selectProducts);
    const dataById = useSelector(selectProductsById);
    const totalData: number = useSelector(selectProductsCount);
    const page = useSelector(selectProductsPage);
    const pageSize = useSelector(selectProductsPageSize);
    const sort = useSelector(selectProductsSort);

    const handlerPagination = (pagination: IPaginationData) => dispatch(setPagination(pagination));
    const handlerSort = (sort: ISort) => dispatch(setSort(sort));
    const handleChangeActive = (value: boolean, id: string) => dispatch(editProduct({ form: { [PRODUCT_FORM_FIELDS.ACTIVE]: Number(value) }, id }));
    const handleChangePreorder = (value: boolean, id: string) => dispatch(editProduct({ form: { [PRODUCT_FORM_FIELDS.PRE_ORDER]: Boolean(value) }, id }));
    const handleChangeIsNew = (value: boolean, id: string) => dispatch(editProduct({ form: { [PRODUCT_FORM_FIELDS.IS_NEW]: Boolean(value) }, id }));
    const handlerDelete = async (id: string) => await dispatch(deleteProduct(id));

    const products = useMemo(() => {
        return data.map((id) => dataById[id]);
    }, [data, dataById]);

    const columns = getColumns(handleChangeActive, handleChangePreorder, handleChangeIsNew, handlerDelete);

    useEffect(() => {
        dispatch(getProducts({}));
    }, [page, pageSize, sort?.column, sort?.type]);

    return (
        <div className={'product-table-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={ts('Продукти')}
                subtitle={ts('Продукти для інтернет-магазину')}
                extra={[
                    authManager.getAccess(PERMISSIONS.CAN_CREATE_PRODUCT)
                     ? <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(PRODUCTS_ROUTES.CREATE))}>
                        <PlusCircleFilled />
                        {ts('Створити')}
                       </Button>
                     : ''
                ]}
            />
            <Table columns={columns}
                   data={products}
                   total={totalData}
                   setPagination={handlerPagination}
                   setSort={handlerSort}
                   page={page}
                   pageSize={pageSize}
                   sort={sort}
                // loading={loading}
            />
        </div>
    );
};