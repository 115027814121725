import {API_BASE_URL, API_METHODS, LOCAL_STORAGE_ACCESS_KEY, VERSION} from "./constants";
import {API_RESPONSE_CODE} from "../interfaces/api";
import {LOCAL_STORAGE_LANGUAGE} from "../components/app-main/constants";

const axios = require('axios').default;

export const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Language': localStorage.getItem(LOCAL_STORAGE_LANGUAGE),
    },
    timeout: 10000,
    withCredentials: true,
});

const getURL = (url: string) => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);
    const prefix = url.indexOf('?') === -1 ? '?' : '&';
    return `${url}${prefix}access-token=${accessToken}`;
}

export const apiRequest = async (url: string, data?: any,  method?: string)  => {
    try {
        const res = await instance({
            method: method ?? API_METHODS.GET,
            url: getURL(url),
            data: data,
        });
        if ( (res?.statusText === 'OK' || res?.statusText === '') && res?.data) {
            return res.data;
        }
        return {
            message: 'Something wrong on server!',
            code: API_RESPONSE_CODE.FAILED,
            status: res?.status ?? 500,
            data: null
        }

    } catch (e) {
        console.log(e);
        return {
            message: 'Something wrong on client!',
            code: API_RESPONSE_CODE.FAILED,
            status: 500,
            data: null
        }
    }

};

export const apiSendFileRequest = async (url: string, data?: any, onUploadProgress?: (event: any) => void)  => {
    try {
        const res = await instance({
            method: API_METHODS.POST,
            url: getURL(url),
            data: data,
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress,
        });
        if ( res?.status === 200 && res?.data) {
            return res.data;
        }
        return {
            message: 'Something wrong on server!',
            code: API_RESPONSE_CODE.FAILED,
            status: res?.status ?? 500,
            data: null
        }

    } catch (e) {
        return {
            message: 'Something wrong on client!',
            code: API_RESPONSE_CODE.FAILED,
            status: 500,
            data: null
        }
    }

};


// {
//     "lang": "ua",
//     "filters": [
//     { "column": "name", "value": "Категорія", "type": "EQUAL" }
// ],
//     "page": 1,
//     "pageSize": 15
// }
