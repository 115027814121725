import {COMPANY_FORM_FIELDS} from "../../components/app-company/constants";
import {ApiBase} from "../ApiBase";
import {ENDPOINTS} from "../constants";
import {IClient, IClientForm} from "../../interfaces/client";

export interface IUpdateClientParams {
    [COMPANY_FORM_FIELDS.NAME]?: string;
    [COMPANY_FORM_FIELDS.ACTIVE]?: boolean;
}

class ApiClient extends ApiBase<IUpdateClientParams, IClientForm, IClient >{
    public endpoint = ENDPOINTS.CLIENT;
}

const apiClient = new ApiClient();

export { apiClient as ApiClient };