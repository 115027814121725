import React, {useState} from "react";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {Route, Routes} from "react-router-dom";
import {SLIDERS_ROUTES} from "./constants";
import {SliderMain} from "./components/SliderMain";
import {Directories} from "../app-main/components/Wrappers/Directories/Directories";

export const SlidersApp: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    return (
            <div className={'sliders-container'}>
                <Directories country>
                    <Loading isLoading={isLoading}>
                        <Routes>
                            <Route path={'/'} element={<SliderMain/>}/>
                        </Routes>
                    </Loading>
                </Directories>
            </div>
    );
};