import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";

export const selectReducer = (state: RootState) => state[REDUCERS.ORDER];
export const selectIsLoading = (state: RootState) => selectReducer(state).isLoading;
export const selectOrders = (state: RootState) => selectReducer(state).ids;
export const selectOrdersById = (state: RootState) => selectReducer(state).idsData;
export const selectOrdersCount = (state: RootState) => selectReducer(state).dataCount;
export const selectOrdersPage = (state: RootState) => selectReducer(state).page;
export const selectOrdersPageSize = (state: RootState) => selectReducer(state).pageSize;
export const selectOrdersSort = (state: RootState) => selectReducer(state).sort;
