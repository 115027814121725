import React, {useEffect, useState} from "react";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {Route, Routes} from "react-router-dom";
import {DIRECTORIES_ROUTES} from "./constants";
import {DirectoryCreteValue} from "./components/DirectoryCreateValue";
import {DirectoryUpdateValue} from "./components/DirectoryUpdateValue";
import {DirectoryTable} from "./components/DirectoryTable";
import {DIRECTORIES, ICountry, IManufacturer, ITax} from "../../interfaces/main";
import {DirectoriesController} from "../app-main/components/DirectoriesController";

interface IProps {
    directory: DIRECTORIES
}

export const DirectoryApp: React.FC<IProps> = ({ directory }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [directoryData, setDirectoryData] = useState<ICountry[] | ITax[] | IManufacturer[]>([]);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(true);

    const handlerUpdate = () => setShouldUpdate(true);

    useEffect(() => {
        DirectoriesController.loadDirectory(directory).then((res) => {
            if (res) {
                setDirectoryData(res);
                setIsLoading(false);
                setShouldUpdate(false);
            }
        });
    }, [directory, shouldUpdate]);

    return (
        <div className={'company-container'}>
            <Loading isLoading={isLoading}>
                <Routes>
                    <Route path={DIRECTORIES_ROUTES.CREATE} element={<DirectoryCreteValue directory={directory} handlerUpdate={handlerUpdate}/>}/>
                    <Route path={DIRECTORIES_ROUTES.EDIT} element={<DirectoryUpdateValue directory={directory} handlerUpdate={handlerUpdate} />}/>
                    <Route path={DIRECTORIES_ROUTES.MAIN} element={<DirectoryTable directory={directory} data={directoryData}/>}/>
                </Routes>
            </Loading>
        </div>
    );
};