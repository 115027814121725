import React, {useState} from "react";
import {Button, Col, Form, notification, Row} from "antd";
import {ts} from "../../../../translations/helpers";
import {ApiSlider} from "../../../../api/endpoints/slider";
import {ICountry} from "../../../../interfaces/main";
import {Input} from "../../../components-base/Input/Input";
import {ApiProduct} from "../../../../api/endpoints/product";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";

interface IProps {
    countryId: number;
    handlerUpdate: (status: boolean) => void;
}

export const SliderRelatedProductsForm: React.FC<IProps> = ({ countryId, handlerUpdate }) => {
    const [sort, setSort] = useState<number>(0);
    const [product_id, setProductId] = useState<number | string | null>(null);

    const handlerSubmit = async () => {
        if( product_id ) {
            await ApiSlider.createProductRelatedSlider(String(product_id), sort, countryId);
            setSort(0);
            setProductId(null);
            handlerUpdate(true);
        }

    };

    return (
        <div className="slider-add-form">
            <Form layout={'horizontal'} onFinish={handlerSubmit}>
                <Row>
                    <Col md={4}>
                        <Autocomplete name={'product_id'}
                                      label={ts('Товар')}
                                      value={product_id}
                                      placeholder={ts('Оберіть товар')}
                                      optionsMapper={(data: ICountry) => ({ value: data.name, id: data.id })}
                                      getData={() => ApiProduct.getAll({ filters: [], page: 1, pageSize: 9999})}
                                      onChange={(value: any) => {
                                          setProductId(value);
                                      }}
                        />
                    </Col>
                    <Col md={1}>

                    </Col>
                    <Col md={6}>
                        <Input value={sort}
                               placeholder={ts('Введіть порядок сортування')}
                               label={ts('Сортування')}
                               handlerChange={setSort}
                        />
                    </Col>
                    <Col md={1}>

                    </Col>
                    <Col md={6}>
                        <Button type={'primary'} htmlType={'submit'} style={{ marginBottom: '23px'}}>{ts('Створити')}</Button>
                    </Col>
                </Row>
            </Form>

        </div>
    );
}