import {IPaginationData, ISort} from "../../../interfaces/main";
import {DEFAULT_PAGE_SIZE, REDUCERS} from "../../../constants";
import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {mapDataById} from "../../../helpers/data";
import {deleteTag, editTag, getTags} from "./actions";
import {ITag} from "../../../interfaces/tag";

interface TagState {
    isLoading: boolean;
    ids: string[];
    idsData: Record<string, ITag>;
    dataCount: number;
    page: number;
    pageSize: number;
    sort: ISort | null;
}

const initialState: TagState = {
    isLoading: false,
    ids: [],
    idsData: {},
    dataCount: 0,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: null,
};

export const tagSlice: Slice<TagState> = createSlice({
    name: REDUCERS.TAG,
    initialState,
    reducers: {
        setPagination: (state, action: PayloadAction<IPaginationData>) => {
            const { page, pageSize } = action.payload;
            state.page = page;
            state.pageSize = pageSize;
        },
        setSort: (state, action: PayloadAction<ISort>) => {
            state.sort = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTags.pending, (state, dispatch) => {
            state.isLoading = true;
        });
        builder.addCase(getTags.fulfilled,
            (state, { payload }) => {
                const { data, count, page, pageSize } = payload;
                state.ids = data.map((item) => String(item.id));
                state.idsData = { ...state.idsData, ...mapDataById(data)};
                state.dataCount = count;
                state.page = page;
                state.pageSize = pageSize;
                state.isLoading = false;
            });
        builder.addCase(editTag.fulfilled,
            (state, { payload }) => {
                if(payload?.id) {
                    state.idsData[payload.id] = payload;
                }
            });
        builder.addCase(deleteTag.fulfilled,
            (state, { payload }) => {
                if(payload) {
                    state.idsData[payload] = { ...state.idsData[payload], enabled: false } ;
                }
            });
    },
});

export const { setPagination, setSort, setLoading  } = tagSlice.actions;

export default tagSlice;