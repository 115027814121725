import {ts} from "../../../../translations/helpers";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {formatDate} from "../../../../helpers/formaters";
import {Button, Space} from "antd";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useMemo} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useSelector} from "react-redux";
import {IPaginationData, ISort} from "../../../../interfaces/main";
import {setPagination, setSort} from "../../../../store/reducers/attribute/reducer";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Table} from "../../../components-base/Table/Table";
import {
    selectAttributes, selectAttributesById, selectAttributesCount,
    selectAttributesPage,
    selectAttributesPageSize, selectAttributesSort
} from "../../../../store/reducers/attribute/selectors";
import {deleteAttribute, editAttribute, getAttributes} from "../../../../store/reducers/attribute/actions";
import {ATTRIBUTES_ROUTES, getRoute} from "../../constants";

const getColumns = (
    handlerDelete: (id: string) => void,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Назва'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: ts('Тип'),
            dataIndex: 'type',
            key: 'type',
            sorter: true,
        },
        {
            title: ts('Порядок сортування'),
            dataIndex: 'sort',
            key: 'sort',
            sorter: true,
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    <Button type={'default'} >
                        <Link to={`${item.id}`}><EditOutlined /></Link>
                    </Button>
                    {/*<Button><SelectOutlined /></Button>*/}
                    <Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>
                </Space>;
            }
        },


    ];
};

export const AttributeTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const data = useSelector(selectAttributes);
    const dataById = useSelector(selectAttributesById);
    const totalData: number = useSelector(selectAttributesCount);
    const page = useSelector(selectAttributesPage);
    const pageSize = useSelector(selectAttributesPageSize);
    const sort = useSelector(selectAttributesSort);

    const handlerPagination = (pagination: IPaginationData) => dispatch(setPagination(pagination));
    const handlerSort = (sort: ISort) => dispatch(setSort(sort));
    const handlerDelete = async (id: string) => await dispatch(deleteAttribute(id));

    const attributes = useMemo(() => {
        return data.map((id) => dataById[id]);
    }, [data, dataById]);

    const columns = getColumns(handlerDelete);

    useEffect(() => {
        dispatch(getAttributes({}));
    }, [page, pageSize, sort?.column, sort?.type]);

    return (
        <div className={'user-table-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={ts('Атрибути')}
                subtitle={ts('Атрибути для товарів')}
                extra={[
                    <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(ATTRIBUTES_ROUTES.CREATE))}>
                        <PlusCircleFilled />
                        {ts('Створити')}
                    </Button>
                ]}
            />
            <Table columns={columns}
                   data={attributes}
                   total={totalData}
                   setPagination={handlerPagination}
                   setSort={handlerSort}
                   page={page}
                   pageSize={pageSize}
                   sort={sort}
                // loading={loading}
            />
        </div>
    );
};