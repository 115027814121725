import React from 'react';
import {UserOutlined, CaretDownOutlined, SettingOutlined, LogoutOutlined} from '@ant-design/icons';
import {Popover} from "antd";
import {LOCAL_STORAGE_ACCESS_KEY} from "../../../../api/constants";
import {IButtonListItem} from "../../../../interfaces/component-base";
import {ButtonList} from "../../../components-base/ButtonList/ButtonList";
import {ts} from "../../../../translations/helpers";
import authManager from "../AuthManager/AuthManager";
import { useNavigate } from 'react-router-dom';
import {COMPANIES_ROUTES, getRoute} from "../../../app-company/constants";

export const UserAccount: React.FC = () => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_KEY);
        window.location.reload();
    };

    const editCompanySettings = () => {
        navigate(getRoute(String(authManager.getCompanyId())));
    };

    const buttons: IButtonListItem[] = [
        { name: ts('Профіль'), icon: <UserOutlined /> },
        // { name: ts('Налаштування'), icon: <SettingOutlined /> },
        { name: ts('Налаштування компанії'), icon: <SettingOutlined />, onClick: editCompanySettings },
        { name: ts('Вийти'), icon: <LogoutOutlined />, onClick: logout },
    ];

    return (
        <div className={'user-account-container'}>
            <UserOutlined />
            <span>{authManager.getFullName()}</span>
            <Popover placement="bottomRight"
                     content={<ButtonList data={buttons}/>}
                     trigger="click"
            >
                <CaretDownOutlined />
            </Popover>
        </div>
    );
};
