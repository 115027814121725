import React, {useState} from 'react';
import {IWarehouseActionProduct, IWarehouseLot} from "../../../../interfaces/warehouse";
import {ts} from "../../../../translations/helpers";
import {ATTRIBUTES_TYPES, ICountry, ISelectItem} from "../../../../interfaces/main";
import {Autocomplete} from "../../../components-base/Autocomplete/Autocomplete";
import {ApiProduct} from "../../../../api/endpoints/product";
import {DeleteOutlined} from '@ant-design/icons'
import {Input} from "../../../components-base/Input/Input";
import {Button, Col, Form, Row} from "antd";
import {INPUT_TYPES} from "../../../../interfaces/form";
import {useSelector} from "react-redux";
import {selectWarehouseProducts} from "../../../../store/reducers/warehouse/selectors";
import {Select} from "../../../components-base/Select/Select";
import {WAREHOUSE_PRODUCT_OPTION_ANOTHER} from "../../constants";

interface IProps {
    product: IWarehouseActionProduct;
    handlerChange: (key: keyof IWarehouseActionProduct, value: any ) => void;
    handlerDelete: (id: number ) => void;
    allowNewLots?: boolean;
}

export const WarehouseActionFormProduct: React.FC<IProps> = ({ product, handlerChange, handlerDelete, allowNewLots }) => {
    const products = useSelector(selectWarehouseProducts);
    const productLots = products.find((item) => String(item.product_id) === String(product.product_id))?.lot as IWarehouseLot[] || [];
    const [validateErrors, setValidateErrors] = useState<Record<string, any>>({} as Record<string, any>);
    const { id, warehouse_products_lot_id, product_id, lot, date_to, count } = product;

    debugger

    const lotOptions = [];

    if(allowNewLots) {
        lotOptions.push({ id: WAREHOUSE_PRODUCT_OPTION_ANOTHER, lot: ts('Новий лот')});
    }

    const validateForm = () => {
        const errors: Record<string, any> = {};

        if (!product_id) errors['product'] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!count) errors['count'] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!warehouse_products_lot_id && warehouse_products_lot_id !== WAREHOUSE_PRODUCT_OPTION_ANOTHER) {
            errors['warehouse_products_lot_id'] = { status: 'error', message: ts('Поле повинне бути заповненим') }
        }
        if(warehouse_products_lot_id === WAREHOUSE_PRODUCT_OPTION_ANOTHER) {
            if (!lot) errors['lot'] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!date_to) errors['date_to'] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        }

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };

    return (
        <Form layout={'horizontal'}>
            <Row gutter={[10, 0]}>
                <Col xs={24} sm={24} md={5} lg={5}>
                    <Autocomplete name={'product_id'}
                                  label={ts('Товар')}
                                  value={product_id}
                                  placeholder={ts('Оберіть товар')}
                                  optionsMapper={(data: ICountry) => ({ value: data.name, id: data.id })}
                                  getData={() => ApiProduct.getAll({ filters: [], page: 1, pageSize: 9999})}
                                  errors={validateErrors?.['product_id']}
                                  onChange={(value: any) => {
                                      handlerChange('product_id', value);
                                      validateForm();
                                  }}
                    />
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                    <Input value={count}
                           placeholder={ts('Введіть кількість')}
                           label={ts('Кількість')}
                           handlerChange={(value: any) =>  handlerChange('count', value)}
                           handlerBlur={() => validateForm()}
                           errors={validateErrors?.['count']}
                           required
                    />
                </Col>
                {product_id && (
                    <Col xs={24} sm={24} md={5} lg={5}>
                        <Autocomplete name={'warehouse_products_lot_id'}
                                      label={ts('Лот')}
                                      value={warehouse_products_lot_id}
                                      placeholder={ts('Оберіть лот')}
                                      optionsMapper={(data: IWarehouseLot) => ({ value: data.lot, id: data.id })}
                                      items={[
                                          ...productLots,
                                          ...lotOptions,
                                      ]}
                                      errors={validateErrors?.['warehouse_products_lot_id']}
                                      onBlur={() => validateForm()}
                                      onChange={(value: any) => handlerChange('warehouse_products_lot_id', value)}
                        />
                    </Col>
                )}
                { warehouse_products_lot_id === WAREHOUSE_PRODUCT_OPTION_ANOTHER && (
                    <>
                        <Col xs={24} sm={24} md={4} lg={4}>
                            <Input value={lot}
                                   placeholder={ts('Введіть назву лоту')}
                                   label={ts('Лот')}
                                   handlerChange={(value: any) => {
                                       handlerChange('lot', value);
                                   }}
                                   handlerBlur={() => validateForm()}
                                   errors={validateErrors?.['lot']}
                                   required
                            />
                        </Col>
                        <Col xs={24} sm={24} md={4} lg={4}>
                            <Input value={date_to}
                                   placeholder={ts('Введіть термін придатності')}
                                   label={ts('Термін придатності')}
                                   type={INPUT_TYPES.DATE}
                                   handlerChange={(value: any) => {
                                       handlerChange('date_to', value);
                                   }}
                                   handlerBlur={() => validateForm()}
                                   errors={validateErrors?.['date_to']}
                                   required
                            />
                        </Col>
                    </>
                )}

                <Col xs={24} sm={24} md={2} lg={2}>
                    <Button type={'primary'} onClick={() => handlerDelete(id)} danger><DeleteOutlined /></Button>
                </Col>
            </Row>
        </Form>
    );
}
