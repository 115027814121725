import {ts} from "../../../../translations/helpers";
import {Switch} from "../../../components-base/Switch/Switch";
import {formatDate} from "../../../../helpers/formaters";
import {Button, Space, Typography} from "antd";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {ICompany} from "../../../../interfaces/company";
import {useAppDispatch} from "../../../../store/store";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useSelector} from "react-redux";
import {ICountry, IPaginationData, ISort} from "../../../../interfaces/main";
import { PlusCircleFilled, EditOutlined, SelectOutlined } from '@ant-design/icons'
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Table} from "../../../components-base/Table/Table";
import {
    selectCompanies,
    selectCompaniesById,
    selectCompaniesCount,
    selectCompaniesPage, selectCompaniesPageSize, selectCompaniesSort
} from "../../../../store/reducers/company/selectors";
import { setPagination, setSort } from "../../../../store/reducers/company/reducer";
import {deleteCompany, editCompany, getCompanies} from "../../../../store/reducers/company/actions";
import {COMPANIES_ROUTES, COMPANY_FORM_FIELDS, getRoute} from "../../constants";
import {CompanyNameCell} from "../CompanyNameCell";
import {EllipsisText} from "../../../components-base/EllipsisText";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import authManager from "../../../app-main/components/AuthManager/AuthManager";
import {PERMISSIONS} from "../../../app-main/components/AuthManager/permissions";

const getColumns = (
    handleChangeActive: (value: boolean, id: string) => void,
    handlerDelete: (id: string) => void,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Назва'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (value: any, item: ICompany) => <CompanyNameCell name={item.name} logo={item.logo} />
        },
        {
            title: ts('Опис'),
            dataIndex: 'description',
            key: 'description',
            sorter: true,
            render: (value: string) => <EllipsisText text={value}/>,
        },
        {
            title: ts('E-mail'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: ts('Країна'),
            dataIndex: 'country_id',
            key: 'country_id',
            render: (value: number) => DirectoriesController.getCountryName(value)
        },
        {
            title: ts('Активність'),
            dataIndex: 'active',
            key: 'active',
            render: (value: any, item: any) => {
                return <Switch checked={Boolean(Number(value))} handlerChange={(value) => handleChangeActive(value, item.id)} name={'active'}/>;
            }
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    {authManager.getAccess(PERMISSIONS.CAN_EDIT_COMPANY) && (
                        <Button type={'default'} >
                            <Link to={`${item.id}`}><EditOutlined /></Link>
                        </Button>
                    )}
                    {/*{authManager.getAccess(PERMISSIONS.CAN_DELETE_COMPANY) && (*/}
                    {/*    <Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>*/}
                    {/*)}*/}
                    <Button type={'default'} >
                        <Link to={getRoute(`view/${item.id}`)}><SelectOutlined /></Link>
                    </Button>
                    {/*<Button></Button>*/}
                </Space>;
            }
        },


    ];
};

export const CompanyTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const data = useSelector(selectCompanies);
    const dataById = useSelector(selectCompaniesById);
    const totalData: number = useSelector(selectCompaniesCount);
    const page = useSelector(selectCompaniesPage);
    const pageSize = useSelector(selectCompaniesPageSize);
    const sort = useSelector(selectCompaniesSort);

    const handlerPagination = (pagination: IPaginationData) => dispatch(setPagination(pagination));
    const handlerSort = (sort: ISort) => dispatch(setSort(sort));
    const handleChangeActive = (value: boolean, id: string) => dispatch(editCompany({ form: { [COMPANY_FORM_FIELDS.ACTIVE]: value }, id }));
    const handlerDelete = async (id: string) => await dispatch(deleteCompany(id));

    const companies = useMemo(() => {
        return data.map((id) => dataById[id]);
    }, [data, dataById]);

    const columns = getColumns(handleChangeActive, handlerDelete);

    useEffect(() => {
        dispatch(getCompanies({}));
    }, [page, pageSize, sort?.column, sort?.type]);

    return (
        <div className={'user-table-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={ts('Компанії')}
                subtitle={ts('Перелік працюючих компаній')}
                extra={[
                    <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(COMPANIES_ROUTES.CREATE))}>
                        <PlusCircleFilled />
                        {ts('Створити')}
                    </Button>
                ]}
            />
            <Table columns={columns}
                   data={companies}
                   total={totalData}
                   setPagination={handlerPagination}
                   setSort={handlerSort}
                   page={page}
                   pageSize={pageSize}
                   sort={sort}
                // loading={loading}
            />
        </div>
    );
};