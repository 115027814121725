import {apiRequest} from "../instance";
import {API_METHODS, ENDPOINTS} from "../constants";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {IUserRole} from "../../interfaces/users";

export const getRoles = async (): Promise<IUserRole[]> => {
    const res = await apiRequest(
        `/${ENDPOINTS.USER}/roles`,
        {},
        API_METHODS.GET
    );

    return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
        ? res.data
        : [] as IUserRole[];
};

export const assignRole = async (user: string, role: number): Promise<boolean> => {
    const res = await apiRequest(
        `/${ENDPOINTS.USER}/roles`,
        { user, role },
        API_METHODS.POST
    );

    return res?.code === API_RESPONSE_CODE.SUCCESS;
};

export const unAssignRole = async (user: string, role: number): Promise<boolean> => {
    const res = await apiRequest(
        `/${ENDPOINTS.USER}/roles/${user}/${role}`,
        {},
        API_METHODS.DELETE
    );

    return res?.code === API_RESPONSE_CODE.SUCCESS;
};