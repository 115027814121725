import {createAsyncThunk} from "@reduxjs/toolkit";
import {ACTION_TYPES} from "./actionTypes";
import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";
import {notification} from "antd";
import {ts} from "../../../translations/helpers";
import {ApiOrder, IUpdateOrderParams} from "../../../api/endpoints/order";

interface IEditFormParams {
    form: IUpdateOrderParams;
    id: string;
}

export const getOrders = createAsyncThunk(
    ACTION_TYPES.GET_ORDERS,
    async ({}: {}, thunkApi) => {
        const state = (thunkApi.getState() as RootState)[REDUCERS.ORDER];
        const { page, pageSize, sort } = state;
        return await ApiOrder.getAll({ page, pageSize, sort });
    }
);

export const editOrder = createAsyncThunk(
    ACTION_TYPES.EDIT_ORDER,
    async ({ form, id }: IEditFormParams, thunkApi) => {
        const res = await ApiOrder.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені'),
            });
            return res;
        }
    }
);

export const deleteOrder = createAsyncThunk(
    ACTION_TYPES.DELETE_ORDER,
    async (id: string, thunkApi) => {
        const res = await ApiOrder.delete(id);
        if (res) {
            notification.success({
                message: ts('Замовлення успішно видалено'),
            });
            return id;
        }
    }
);
