import React from 'react';

interface ICompanyNameCellProps  {
    name: string;
    logo: string | number;
}
export const CompanyNameCell: React.FC<ICompanyNameCellProps> = ({ name, logo }) => {
    const hasLogo = typeof logo === 'string' && Boolean(Number(logo));
    return (
        <div className={'company-table-name-container'}>
            {/*<div className={'company-table-name-image'}>*/}
            {/*    <img src={hasLogo ? logo : 'https://medbuy.online/static/media/logo.fad3cf2b.png'} alt={name}/>*/}
            {/*</div>*/}
            <span>{name}</span>
        </div>
    );
};
