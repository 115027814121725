import React from 'react';
import {ts} from "../../translations/helpers";
import {LineChart} from "./components/Charts/LineChart";
import {Col, Row} from "antd";
import {BarChart} from "./components/Charts/BarChart";
import {DonutChart} from "./components/Charts/DonutChart";
import {ProgressChart} from "./components/Charts/ProgressChart";

export const DashboardApp: React.FC = () => {
    return (
        <div className={'dashboard-container'}>
            <Row>
                <Col span={8}>
                    <BarChart />
                </Col>
                <Col span={8}>
                    <DonutChart />
                </Col>
                <Col span={8}>
                    <ProgressChart />
                </Col>
            </Row>
            <Row style={{ paddingTop: '30px', boxSizing: 'border-box' }}>
                <Col span={24}>
                    <LineChart />
                </Col>
            </Row>
        </div>
    );
};

