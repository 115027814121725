import React, {useState} from 'react';
import {Sidebar} from "./components/Sidebar/Sidebar";
import {Layout} from "antd";
import {setStyles} from "./helpers";
import {Header} from "./components/Header/Header";
import {Content} from "./components/Content/Content";

export const MainApp: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    return (
        <Layout>
            <Sidebar collapsed={collapsed}/>
            <Layout className={setStyles({
                'site-layout': true,
                'collapsed': collapsed
            })}>
                <Header collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Content />
            </Layout>
        </Layout>
    );
};
