import {ts} from "../../../../translations/helpers";
import {Switch} from "../../../components-base/Switch/Switch";
import {formatDate} from "../../../../helpers/formaters";
import {Button, Space} from "antd";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useMemo} from "react";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {useAppDispatch} from "../../../../store/store";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useSelector} from "react-redux";
import {IPaginationData, ISort} from "../../../../interfaces/main";
import {setPagination, setSort} from "../../../../store/reducers/client/reducer";
import {getRoute} from "../../constants";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Table} from "../../../components-base/Table/Table";
import {
    selectClients,
    selectClientsById, selectClientsCount,
    selectClientsPage,
    selectClientsPageSize, selectClientsSort
} from "../../../../store/reducers/client/selectors";
import {CLIENT_FORM_FIELDS, CLIENTS_ROUTES} from "../../constants";
import {deleteClient, editClient, getClients} from "../../../../store/reducers/client/actions";

const getColumns = (
    handleChangeActive: (value: boolean, id: string) => void,
    handlerDelete: (id: string) => void,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('П.І.Б'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (value: any, item: any) => {
                return `${item.surname} ${item.name} ${item.middle_name}`;
            }
        },
        {
            title: ts('Телефон'),
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: ts('E-mail'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: ts('Активність'),
            dataIndex: 'active',
            key: 'active',
            render: (value: any, item: any) => {
                return <Switch checked={Boolean(Number(value))} handlerChange={(value) => handleChangeActive(value, item.id)} name={'active'}/>;
            }
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    <Button type={'default'} >
                        <Link to={`${item.id}`}><EditOutlined /></Link>
                    </Button>
                    {/*<Button><SelectOutlined /></Button>*/}
                    {/*<Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>*/}
                </Space>;
            }
        },


    ];
};

export const ClientTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const data = useSelector(selectClients);
    const dataById = useSelector(selectClientsById);
    const totalData: number = useSelector(selectClientsCount);
    const page = useSelector(selectClientsPage);
    const pageSize = useSelector(selectClientsPageSize);
    const sort = useSelector(selectClientsSort);

    const handlerPagination = (pagination: IPaginationData) => dispatch(setPagination(pagination));
    const handlerSort = (sort: ISort) => dispatch(setSort(sort));
    const handleChangeActive = (value: boolean, id: string) => dispatch(editClient({ form: { [CLIENT_FORM_FIELDS.ACTIVE]: value }, id }));
    const handlerDelete = async (id: string) => await dispatch(deleteClient(id));

    const clients = useMemo(() => {
        return data.map((id) => dataById[id]);
    }, [data, dataById]);

    const columns = getColumns(handleChangeActive, handlerDelete);

    useEffect(() => {
        dispatch(getClients({}));
    }, [page, pageSize, sort?.column, sort?.type]);

    return (
        <div className={'client-table-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={ts('Клієнти')}
                subtitle={ts('Клієнти вашого магазину')}
                extra={[
                    <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(CLIENTS_ROUTES.CREATE))}>
                        <PlusCircleFilled />
                        {ts('Створити')}
                    </Button>
                ]}
            />
            <Table columns={columns}
                   data={clients}
                   total={totalData}
                   setPagination={handlerPagination}
                   setSort={handlerSort}
                   page={page}
                   pageSize={pageSize}
                   sort={sort}
                // loading={loading}
            />
        </div>
    );
};