import {MAIN_APP_ROUTES} from "../app-main/constants";
import {ICategoryForm} from "../../interfaces/category";

export const getRoute = (route: string): string => `/${MAIN_APP_ROUTES.CATEGORIES}/${route}`;

export enum CATEGORIES_ROUTES {
    CREATE = 'create',
    EDIT = ':id',
}

export enum CATEGORY_FORM_FIELDS {
    NAME = 'name',
    PARENT = 'parent',
    ALIAS = 'alias',
    LANG = 'lang',
    ACTIVE = 'active',
}
export const INITIAL_CATEGORY_FORM: ICategoryForm = {
    [CATEGORY_FORM_FIELDS.NAME]: '',
    [CATEGORY_FORM_FIELDS.PARENT]: '',
    [CATEGORY_FORM_FIELDS.ALIAS]: '',
    [CATEGORY_FORM_FIELDS.LANG]: [],
    [CATEGORY_FORM_FIELDS.ACTIVE]: false,
};
