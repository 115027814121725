import React, {ReactElement, ReactNode, useEffect, useState} from 'react';
import {Login} from "../../Login/Login";
import {Loading} from "../Loading/Loading";
import authManager from "../../AuthManager/AuthManager";

interface IAuthProps {
    children?: ReactNode;
}

export const Auth: React.FC<IAuthProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        authManager.getAuth().then((res) => {
            setIsLoading(false);
            setIsAuth(res);
        });
    }, []);

    return (
        <Loading isLoading={isLoading} hide>
            {isAuth ? children as ReactElement : <Login/>}
        </Loading>
    );
};
