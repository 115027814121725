import React, {useEffect, useState} from 'react';
import {Descriptions} from "antd";
import {ts} from "../../../../translations/helpers";
import {formatDate} from "../../../../helpers/formaters";
import {IOrder} from "../../../../interfaces/order";
import {FILTER_TYPES, ICountry} from "../../../../interfaces/main";
import {useSelector} from "react-redux";
import {getClients, loadClients} from "../../../../store/reducers/client/actions";
import {RootState, useAppDispatch} from "../../../../store/store";
import {selectClient, selectClientsById} from "../../../../store/reducers/client/selectors";
import {selectAttribute} from "../../../../store/reducers/attribute/selectors";
import {DirectoriesApi} from "../../../../api/endpoints/directory";
import {selectCurrencyName} from "../../../../store/reducers/common/selectors";
import {Link} from "react-router-dom";
import {MAIN_APP_ROUTES} from "../../../app-main/constants";

interface IOrdersInfoMainProps {
    order: IOrder;
}

const labelStyle = {};

export const OrdersInfoMain: React.FC<IOrdersInfoMainProps> = ({order}) => {
    const dispatch = useAppDispatch();
    const client = useSelector((state: RootState) => selectClient(state, order.client_id));
    const currency = useSelector((state: RootState) => selectCurrencyName(state, order.currency_id));
    const [country, setCountry] = useState<ICountry | null>(null);

    useEffect(() => {
        if (order.client_id) {
            dispatch(loadClients({ ids: [order.client_id] }));
        }
        if (order.country_id) {
            DirectoriesApi.getCountries().then((res) => {
                setCountry(
                    res.find((country) => Number(country.id) === Number(order.country_id)) || null
                );
            })
        }
    }, []);

    return (
        <Descriptions>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Клієнт')}>
                {
                    client
                        && <Link to={`${MAIN_APP_ROUTES.CLIENTS}/${client.id}`}>
                            `${client.surname} ${client.name} ${client.middle_name}`
                           </Link>
                        || ts('Гість (Не авторизований клієнт)')

                }
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Тип замовлення')}>
                {order.type}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Тип оплати')}>
                {order.payment}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Країна')}>
                {country ? country.name : '-'}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Місто доставки')}>
                {`${order.city}`}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Адреса доставки')}>
                {`${order.address}`}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Дата створення')}>
                {formatDate(Number(order.created_at))}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Валюта')}>
                {currency || '-'}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label={ts('Промокод')}>
                {order.promo_code_id || '-'}
            </Descriptions.Item>
        </Descriptions>
    );
}

