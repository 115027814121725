import React, {useState} from 'react';
import {Input} from "../../../components-base/Input/Input";
import {ts} from "../../../../translations/helpers";

interface IProps {
    current: number;
    handler: (form: Record<string, any>) => void;
}

export const SliderSortInput: React.FC<IProps> = ({ current, handler }) => {
    const [sort, setSort] = useState(current);

    const handlerBlur = () => {
        if (sort !== current) {
            handler({ sort: sort });
        }
    };

    return (
        <Input value={sort}
                  handlerChange={setSort}
                  handlerBlur={handlerBlur}
                  placeholder={ts('')}
        />
    );
}
