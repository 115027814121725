import React from 'react';
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {WarehouseActionForm} from "../WarehouseActionForm";
import {IWarehouseActionProduct} from "../../../../interfaces/warehouse";
import {ApiWarehouse} from "../../../../api/endpoints/warehouse";
import {notification} from "antd";
import { useNavigate } from 'react-router-dom';
import {getRoute, WAREHOUSES_ROUTES} from "../../constants";

interface IProps {

}

export const WarehouseReceiving: React.FC<IProps> = () => {
    const navigate = useNavigate();
    const { breadcrumbs } = useActiveRoute();

    const handlerSubmit = (products: IWarehouseActionProduct[], comment: string) => {
        ApiWarehouse.receiving(products, comment).then((res) => {
            if (res) {
                notification.success({
                    message: ts('Надходженння успішно створена!'),
                });
                navigate(getRoute(''));
            } else {

            }
        })
    };

    return (
        <div className={'warehouse-receiving-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Створити надходження товарів')}/>
            <PageHeader
                title={ts('Створити надходження товарів')}
            />
            <WarehouseActionForm handlerSubmit={handlerSubmit} allowNewLots/>
        </div>
    );
}