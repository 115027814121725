import {API_METHODS, ENDPOINTS} from "../constants";
import {ApiBase} from "../ApiBase";
import {ICompany, ICompanyForm, ICompanyInfoForm} from "../../interfaces/company";
import {COMPANY_FORM_FIELDS} from "../../components/app-company/constants";
import {apiRequest} from "../instance";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {notification} from "antd";
import {ts} from "../../translations/helpers";

export interface IUpdateCompanyParams {
    [COMPANY_FORM_FIELDS.NAME]?: string;
    [COMPANY_FORM_FIELDS.EMAIL]?: string;
    [COMPANY_FORM_FIELDS.ACTIVE]?: boolean;
}

class ApiCompany extends ApiBase<IUpdateCompanyParams, ICompanyForm, ICompany >{
    public endpoint = ENDPOINTS.COMPANY;

    public updateInfo = async (form: ICompanyInfoForm, id: string): Promise<ICompany> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/info/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
    };

    public createInfo = async (form: ICompanyInfoForm) => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/create/info`,
            form,
            API_METHODS.POST
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? true
            : notification.error({
                message: ts('Помилка при створенні'),
                description: JSON.stringify(res.message),
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
    };

}

const apiCompany = new ApiCompany();

export { apiCompany as ApiCompany };