import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoading} from "../../store/reducers/company/selectors";
import {Route, Routes} from "react-router-dom";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {CompanyTable} from "./components/CompanyTable";
import {Directories} from "../app-main/components/Wrappers/Directories/Directories";
import {CompanyCreate} from "./components/CompanyCreate";
import {COMPANIES_ROUTES} from "./constants";
import {CompanyUpdate} from "./components/CompanyUpdate";
import {CompanyView} from "./components/CompanyView";

export const CompanyApp: React.FC = () => {
    const isLoading = useSelector(selectIsLoading);

    return (
        <div className={'company-container'}>
            <Directories country>
                <Loading isLoading={isLoading}>
                    <Routes>
                        <Route path={COMPANIES_ROUTES.CREATE} element={<CompanyCreate/>}/>
                        <Route path={COMPANIES_ROUTES.EDIT} element={<CompanyUpdate />}/>
                        <Route path={COMPANIES_ROUTES.VIEW} element={<CompanyView />}/>
                        <Route path={'/'} element={<CompanyTable/>}/>
                    </Routes>
                </Loading>
            </Directories>
        </div>
    );
};
