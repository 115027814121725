import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux'
import { I18nextProvider } from "react-i18next";
import i18n from './translations';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import 'antd/dist/antd.min.css';
import './css/index.scss';
import {LOCAL_STORAGE_LANGUAGE} from "./components/app-main/constants";

if (!localStorage.getItem(LOCAL_STORAGE_LANGUAGE)) {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE, 'ua');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <Router>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </Router>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
