import React, {useEffect, useState} from 'react';
import {ts} from "../../../../translations/helpers";
import {Input} from "../../../components-base/Input/Input";
import {Button, Col, Form, notification, Row} from "antd";
import {IUserForm} from "../../../../interfaces/users";
import {INITIAL_USER_FORM, USER_FORM_FIELDS} from "../../constants";
import {validateEmail} from "../../../../helpers/validators";
import {Switch} from "../../../components-base/Switch/Switch";
import {ApiStuff} from "../../../../api/endpoints/stuff";
import { useNavigate } from 'react-router-dom';
import {MAIN_APP_ROUTES} from "../../../app-main/constants";
import {useAppDispatch} from "../../../../store/store";
import { setLoading } from '../../../../store/reducers/users/reducer';
import {IUser} from "../../../../interfaces/main";

interface IUserFormProps {
    create?: boolean;
    id?: string | undefined;
    user?: IUserForm;
}

export const UserForm: React.FC<IUserFormProps> = ({ create, id, user }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<IUserForm>(INITIAL_USER_FORM);
    const [validateErrors, setValidateErrors] = useState<Record<USER_FORM_FIELDS, any>>({} as Record<USER_FORM_FIELDS, any>)

    const changeForm = (key: keyof IUserForm, value: any) => setForm({...form, [key]: value });

    const validateForm = () => {
        const errors: Record<string, any> = {};
        const emailErrors = validateEmail(form[USER_FORM_FIELDS.EMAIL]);

        if (!form[USER_FORM_FIELDS.NAME]) errors[USER_FORM_FIELDS.NAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
        if (!form[USER_FORM_FIELDS.SURNAME]) errors[USER_FORM_FIELDS.SURNAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };

        if(create) {
            if (!form[USER_FORM_FIELDS.USERNAME]) errors[USER_FORM_FIELDS.USERNAME] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (!form[USER_FORM_FIELDS.PASSWORD]) errors[USER_FORM_FIELDS.PASSWORD] = { status: 'error', message: ts('Поле повинне бути заповненим') };
            if (emailErrors) errors[USER_FORM_FIELDS.EMAIL] = emailErrors;
        }

        setValidateErrors(errors);
        return Object.keys(errors).length === 0;
    };
    
    const onSubmit = async () => {
        if (validateForm()) {
            dispatch(setLoading(true));
            create ? await createUser() : await updateUser();
            dispatch(setLoading(false));
        }
    };
    
    const createUser = async () => {
        const res = await ApiStuff.create(form);
        if (res) {
            notification.success({
                message: ts('Користувач успішно створений!'),
            });
            navigate('/' + MAIN_APP_ROUTES.USERS);
        }
    };
    
    const updateUser = async () => {
        if (!id) return;
        const res = await ApiStuff.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені!'),
            });
        }
    };

    useEffect(() => {
        if (user) setForm(user);
    }, [user]);
    
    return (
        <Form layout={'vertical'} onFinish={onSubmit}>
            <Row>
                <Col md={8} lg={8} xs={24}>
                    <Input value={form[USER_FORM_FIELDS.NAME]}
                           placeholder={ts('Введіть ім\'я')}
                           label={ts('Ім\'я')}
                           handlerChange={(value: any) => changeForm(USER_FORM_FIELDS.NAME, value)}
                           errors={validateErrors?.[USER_FORM_FIELDS.NAME]}
                           required
                    />
                </Col>
                <Col md={8} lg={8} xs={24}>
                    <Input value={form[USER_FORM_FIELDS.SURNAME]}
                           placeholder={ts('Введіть прізвище')}
                           label={ts('Прізвище')}
                           handlerChange={(value: any) => changeForm(USER_FORM_FIELDS.SURNAME, value)}
                           errors={validateErrors?.[USER_FORM_FIELDS.SURNAME]}
                           required
                    />
                </Col>
                <Col md={8} lg={8} xs={24}>
                    <Input value={form[USER_FORM_FIELDS.MIDDLE_NAME]}
                           placeholder={ts('Введіть по-батькові')}
                           label={ts('По-батькові')}
                           handlerChange={(value: any) => changeForm(USER_FORM_FIELDS.MIDDLE_NAME, value)}
                           errors={validateErrors?.[USER_FORM_FIELDS.MIDDLE_NAME]}
                    />
                </Col>
            </Row>
            {create && (
                <Row>
                    <Col md={8} lg={8} xs={24}>
                        <Input value={form[USER_FORM_FIELDS.USERNAME]}
                               placeholder={ts('Введіть логін')}
                               label={ts('Логін')}
                               handlerChange={(value: any) => changeForm(USER_FORM_FIELDS.USERNAME, value)}
                               errors={validateErrors?.[USER_FORM_FIELDS.USERNAME]}
                               required
                        />
                    </Col>
                    <Col md={8} lg={8} xs={24}>
                        <Input value={form[USER_FORM_FIELDS.EMAIL]}
                               placeholder={ts('Введіть e-mail')}
                               label={ts('E-mail')}
                               handlerChange={(value: any) => changeForm(USER_FORM_FIELDS.EMAIL, value)}
                               errors={validateErrors?.[USER_FORM_FIELDS.EMAIL]}
                               required
                        />
                    </Col>
                    <Col md={8} lg={8} xs={24}>
                        <Input value={form[USER_FORM_FIELDS.PASSWORD]}
                               placeholder={ts('Пароль')}
                               label={ts('Введіть пароль')}
                               handlerChange={(value: any) => changeForm(USER_FORM_FIELDS.PASSWORD, value)}
                               errors={validateErrors?.[USER_FORM_FIELDS.PASSWORD]}
                               required
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={3} lg={3} xs={24}>
                    <Switch checked={form[USER_FORM_FIELDS.ACTIVE]}
                            handlerChange={(value: any) => changeForm(USER_FORM_FIELDS.ACTIVE, value)}
                            label={ts('Активний')}
                            name={USER_FORM_FIELDS.ACTIVE}
                    />
                </Col>
                <Col md={3} lg={3} xs={24}>
                    <Switch checked={form[USER_FORM_FIELDS.SIGNATORY]}
                            handlerChange={(value: any) => changeForm(USER_FORM_FIELDS.SIGNATORY, value)}
                            label={ts('Підписант')}
                            name={USER_FORM_FIELDS.SIGNATORY}
                    />
                </Col>
            </Row>
            <Row justify={'end'}>
                <Button type={'primary'} htmlType={'submit'}>{create ? ts('Створити') : ts('Зберегти')}</Button>
            </Row>
        </Form>
    );
};
{/*<Autocomplete name={'parent'}*/}
{/*              label={'Батьківська категорія'}*/}
{/*              value={parent}*/}
{/*              optionsMapper={(data: ICategory) => ({ value: data.name, id: data.id })}*/}
{/*              getData={getCategories}*/}
{/*              onChange={setParent}*/}
{/*/>*/}