import {API_METHODS, ENDPOINTS} from "../constants";
import {ApiBase} from "../ApiBase";
import {COMPANY_FORM_FIELDS} from "../../components/app-company/constants";
import {ICategory, ICategoryForm} from "../../interfaces/category";
import {apiRequest} from "../instance";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {notification} from "antd";
import {ts} from "../../translations/helpers";

export interface IUpdateCategoryParams {
    [COMPANY_FORM_FIELDS.NAME]?: string;
    [COMPANY_FORM_FIELDS.ACTIVE]?: boolean;
}

class ApiCategory extends ApiBase<IUpdateCategoryParams, ICategoryForm, ICategory >{
    public endpoint = ENDPOINTS.CATEGORY;

    public updateLang = async (form: { value: string, lang: number}, id: string): Promise<void> => {
        const res = await apiRequest(
            `/${this.getEndpoint()}/lang/${id}`,
            form,
            API_METHODS.PUT
        );
        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? notification.success({
                message: ts('Зміни успішно збережені!'),
            })
            : notification.error({
                message: ts('Помилка при редагуванні'),
                description: JSON.stringify(res.message),
            });
    };

}

const apiCategory = new ApiCategory();

export { apiCategory as ApiCategory };