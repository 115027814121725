import React, {useState} from 'react';
import {Button, Divider, Form, notification, Space} from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import {IAttributeValue} from "../../../../interfaces/attribute";
import {LangEditor} from "../../../components-base/LangEditor/LangEditor";
import {ApiAttribute} from "../../../../api/endpoints/attributes";
import {ts} from "../../../../translations/helpers";

interface IAttributeValuesProps {
    values: IAttributeValue[];
    id: number | string | undefined;
    handlerUpdate: () => void;
}
export const AttributeValues: React.FC<IAttributeValuesProps> = ({ values, id, handlerUpdate }) => {
    const [form, setForm] = useState<{lang: number; name: string}[]>([]);
    const [showForm, setShowForm] = useState<boolean>(true);
    const handlerChange = async (value: any) => {
        setForm([
            ...form.filter((item) => String(item.lang) !== String(value.lang)),
            { lang: Number(value.lang), name: value.value }
        ]);
    };
    const handlerValueChange = async (valueId: number, value: any) => {
        setForm([
            ...form.filter((item) => String(item.lang) !== String(value.lang)),
            { lang: Number(value.lang), name: value.value }
        ]);
        await ApiAttribute.updateValueLang({ lang: Number(value.lang), value: String(value.value) }, String(valueId));
    };

    const handleValueDelete = async (valueId: number) => {
        await ApiAttribute.deleteValue(String(valueId));
        await handlerUpdate();
    };

    const onSubmit = async () => {
        if (id && form.length > 0) {
            setShowForm(false);
            await ApiAttribute.createValue({ data: form }, String(id));
            await handlerUpdate();
            setForm([]);
            setShowForm(true);
        }
        if (form.length === 0){
            notification.error({
                message: ts('Помилка при створенні'),
                description: ts('Введіть хоча б один переклад'),
            });
        }
    };

    console.log(values);

    return (
        <div className={'attribute-values-container'}>
            <Divider children={ts('Створити нове значення')} orientation="left" orientationMargin="0"/>
            <div className={'attribute-value-create'}>
                <Form layout={'horizontal'} onFinish={onSubmit}>
                    {showForm && <LangEditor handlerChange={handlerChange} translates={[]} />}
                    <Button type={'primary'} htmlType={'submit'}>{ts('Створити')}</Button>
                </Form>
            </div>
            <Divider children={ts('Існуючі значення')} orientation="left" orientationMargin="0"/>
            {values.map((value) => {
                return (
                    <>
                        <div className={'attributes-values-item-container'}>
                            <LangEditor handlerChange={(translate) => handlerValueChange(value.id, translate)} translates={value?.lang?.map((lang) => ({lang: lang.lang, value: lang.value }))}/>
                            <Button type={'primary'} onClick={() => handleValueDelete(value.id)} danger><DeleteOutlined /></Button>
                        </div>

                        <Divider children={''} orientation="left" orientationMargin="0"/>
                    </>
                )
            })}
        </div>
    );
};
