import React, {useState} from 'react';
import {Button, Divider, Form} from "antd";
import {IWarehouseActionProduct} from "../../../../interfaces/warehouse";
import {ts} from "../../../../translations/helpers";
import {TextArea} from "../../../components-base/TextArea/TextArea";
import {WarehouseActionFormProduct} from "../WarehouseActionFormProduct";
import {generateRandomInteger} from "../../../../helpers/data";

const _ = require('lodash');

interface IProps {
    handlerSubmit: (products: IWarehouseActionProduct[], comment: string) => void;
    allowNewLots?: boolean;
}

export const WarehouseActionForm: React.FC<IProps> = ({handlerSubmit, allowNewLots = false}) => {
    const [products, setProducts] = useState<IWarehouseActionProduct[]>([]);
    const [comment, setComment] = useState<string>('');

    const handlerDelete = (id: number) => setProducts(products.filter((product) => product.id !== id));

    const handlerChangeProducts = (id: number, key: keyof IWarehouseActionProduct, value: any) => {
        setProducts(products.map((product) => {
            if (product.id === id) {
                return {...product, [key]: value};
            } else return product;
        }))
    };

    const handlerAddProduct = () => setProducts([...products, {
        id: Number(_.uniqueId()),
        warehouse_products_lot_id: null,
        product_id: null,
        lot: '',
        date_to: '',
        count: null,
    }]);

    const validate = () => {
      let errors = 0;
      products.forEach((product) => {
          if (!product.product_id) errors++;
          if (!product.count) errors++;
          if (!product.warehouse_products_lot_id) {
              if (!product.lot || !product.date_to) {
                  errors++
              }
          }
      });
      if (products.length === 0) errors++;
      return errors === 0;
    };

    const canSubmit = validate();

    return (
        <div className={'warehouse-action-form-container'}>
            <Divider children={
                        <>
                            {ts('Товари')}
                            <Button type={'primary'} onClick={handlerAddProduct} style={{ marginLeft: '10px' }}>{ts('+')}</Button>
                        </>
                     }
                     orientation="left"
                     orientationMargin="0"/>
            {products.map((product) => (
                <WarehouseActionFormProduct product={product}
                                            handlerDelete={handlerDelete}
                                            handlerChange={(key, value) => handlerChangeProducts(product.id, key, value)}
                                            allowNewLots={allowNewLots}
                />))}

            <Divider children={ts('Коментар')} orientation="left" orientationMargin="0"/>
            <TextArea value={comment} handlerChange={setComment}/>
            <Button type={'primary'} onClick={() => canSubmit && handlerSubmit(products, comment)} disabled={!canSubmit}>{ts('Створити')}</Button>
        </div>
    );
};