import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {ITag, ITagForm} from "../../../../interfaces/tag";
import {ApiTag} from "../../../../api/endpoints/tag";
import {TAG_FORM_FIELDS} from "../../constants";
import {TagForm} from "../TagForm";
import {Divider} from "antd";
import {LangEditor} from "../../../components-base/LangEditor/LangEditor";

export const TagUpdate = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [tag, setTag] = useState<ITagForm>({} as ITagForm);
    const handlerNameChanges = async (value: any) => id && await ApiTag.updateLang(value, id);

    useEffect(() => {
        if (id) {
            ApiTag.getOne(id)
                .then((tag: ITag) => {
                    if (tag.id) {
                        setTag({
                            [TAG_FORM_FIELDS.NAME]: tag.name,
                            [TAG_FORM_FIELDS.ALIAS]: tag.alias,
                            [TAG_FORM_FIELDS.ACTIVE]: tag.active,
                            [TAG_FORM_FIELDS.LANG]: tag.lang,
                        });
                    }
                });
        }
    }, [id]);

    return (
        <div className={'tag-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Редагування тега')}/>
            <PageHeader
                title={ts('Редагування тега')}
            />
            <Divider children={ts('Назва')} orientation="left" orientationMargin="0"/>
            <LangEditor handlerChange={handlerNameChanges} translates={tag.lang}/>
            <Divider children={ts('Основна інформація')} orientation="left" orientationMargin="0"/>
            <TagForm tag={tag} id={id}/>
        </div>
    );
}