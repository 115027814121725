import React from 'react';
import LogoPNG from '../../../img/logo.png';
import LogoInversePNG from '../../../img/logo-inverse.png';

interface ILogoProps {
    inverted?: boolean
}

export const Logo: React.FC<ILogoProps> = ({ inverted }) => {
    return (
        <div className="logo" >
            <img src={inverted ? LogoInversePNG : LogoPNG} alt={'Medbuy.Online'}/>
        </div>
    );
}