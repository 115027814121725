import {API_METHODS, ENDPOINTS} from "../constants";
import {apiRequest} from "../instance";
import {API_RESPONSE_CODE} from "../../interfaces/api";
import {IWarehouseActionProduct, IWarehouseLot} from "../../interfaces/warehouse";

class ApiWarehouse {
    public endpoint = ENDPOINTS.WAREHOUSE;

    public getOne = async (companyId: number | null = null): Promise<IWarehouseActionProduct[]> => {
        const res = await apiRequest(
            `/${this.endpoint}`,
            { warehouse_id: companyId },
            API_METHODS.POST
        );

        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : [] as IWarehouseActionProduct[];
    };

    public receiving = async (products: IWarehouseActionProduct[], comment: string): Promise<boolean> => {
        const res = await apiRequest(
            `/${this.endpoint}/product/receiving`,
            { comment, data: products },
            API_METHODS.POST
        );

        return res?.code === API_RESPONSE_CODE.SUCCESS;
    };

    public writeOff = async (products: IWarehouseActionProduct[], comment: string): Promise<boolean> => {
        const res = await apiRequest(
            `/${this.endpoint}/product/write-off`,
            { comment, data: products },
            API_METHODS.POST
        );

        return res?.code === API_RESPONSE_CODE.SUCCESS;
    };

    public getLots = async (productId: number): Promise<IWarehouseLot[]> => {
        const res = await apiRequest(
            `/${this.endpoint}/lot/${productId}`,
            {},
            API_METHODS.POST
        );

        return res?.code === API_RESPONSE_CODE.SUCCESS && res?.data
            ? res.data
            : [] as IWarehouseLot[];
    };
}

const apiWarehouse = new ApiWarehouse();

export { apiWarehouse as ApiWarehouse };
