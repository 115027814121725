import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";

export const selectReducer = (state: RootState) => state[REDUCERS.ATTRIBUTE];
export const selectIsLoading = (state: RootState) => selectReducer(state).isLoading;
export const selectAttributes = (state: RootState) => selectReducer(state).ids;
export const selectAttribute = (state: RootState, id: string) => selectReducer(state).idsData[id];
export const selectAttributesById = (state: RootState) => selectReducer(state).idsData;
export const selectAttributesCount = (state: RootState) => selectReducer(state).dataCount;
export const selectAttributesPage = (state: RootState) => selectReducer(state).page;
export const selectAttributesPageSize = (state: RootState) => selectReducer(state).pageSize;
export const selectAttributesSort = (state: RootState) => selectReducer(state).sort;