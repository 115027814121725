import React, {ReactNode} from 'react';
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";

interface IBreadcrumbsProps {
    items: IBreadcrumbsItem[];
    extra?: string;
}

interface IBreadcrumbsItem {
    path: string,
    breadcrumbName: string
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ items , extra}) => {
    return (
     <Breadcrumb>
         {items.map((item) => (
             <Breadcrumb.Item key={item.path}>
                 <Link to={'/' + item.path}>{item.breadcrumbName}</Link>
             </Breadcrumb.Item>
         ))}
         {extra && (
             <Breadcrumb.Item key={extra}>
                 {extra}
             </Breadcrumb.Item>
         )}
     </Breadcrumb>
    );
}