import {ts} from "../../../../translations/helpers";
import {formatDate} from "../../../../helpers/formaters";
import {Button, Space} from "antd";
import React, {useEffect, useState} from "react";
import {DeleteOutlined} from '@ant-design/icons'
import {Table} from "../../../components-base/Table/Table";
import {IMainSliderItem, IProductSliderItem} from "../../../../interfaces/slider";
import {ApiSlider} from "../../../../api/endpoints/slider";
import {SORT_TYPES} from "../../../../interfaces/api";
import {Input} from "../../../components-base/Input/Input";
import {SliderSortInput} from "../SliderSortInput";
import {getProducts} from "../../../../store/reducers/product/actions";
import {FILTER_TYPES} from "../../../../interfaces/main";
import {useAppDispatch} from "../../../../store/store";
import {useSelector} from "react-redux";
import {selectProductsById} from "../../../../store/reducers/product/selectors";
import {IProduct} from "../../../../interfaces/product";

interface IProps {
    countryId: number;
    shouldUpdate: boolean;
    handlerUpdate: (status: boolean) => void;
}

const getColumns = (
    handlerDelete: (id: string) => void,
    productsData: Record<string, IProduct>,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Продукт'),
            dataIndex: 'product_id',
            key: 'product_id',
            sorter: true,
            render: ((value: any) => {
                const product = productsData[value];
                return <span>{product?.name}</span>
            })
        },
        {
            title: ts('Сортування'),
            dataIndex: 'sort',
            key: 'sort',
            sorter: true,
            render: ((value: any, item: IMainSliderItem) => <SliderSortInput current={value} handler={(form) => ApiSlider.updateRelatedProductsItem(String(item.id), form)}/>)
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    {/*<Button><SelectOutlined /></Button>*/}
                    <Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>
                </Space>;
            }
        },


    ];
};

export const SliderRelatedProductsTable: React.FC<IProps> = ({ countryId, shouldUpdate, handlerUpdate }) => {
    const dispatch = useAppDispatch();
    const [uploaded, setUploaded] = useState<IProductSliderItem[]>([]);
    const products = uploaded.map((item) => item.product_id);
    const productsData = useSelector(selectProductsById);

    useEffect(() => {
        if(products) {
            dispatch(getProducts({ filters: [{ column: 'ids', type: FILTER_TYPES.IN, value: products }] }));
        }
    }, [uploaded]);

    const handlerDelete = async (id: string) => {
        await ApiSlider.deleteRelatedProductsItem(id);
        handlerUpdate(true);
    };

    const columns = getColumns(handlerDelete, productsData);

    useEffect(() => {
        if (shouldUpdate) {
            ApiSlider.getRelatedProducts(countryId).then((res) => {
                setUploaded(res);
                handlerUpdate(false);
            });
        }

    }, [shouldUpdate, countryId]);

    return (
        <div className={'slider-home-page-table-container'}>
            <Table columns={columns}
                   data={uploaded}
                   total={uploaded.length}
                   page={1}
                   pageSize={9999}
                   sort={{column: 'id', type: SORT_TYPES.ASC}}
                // loading={loading}
            />
        </div>
    );
};
