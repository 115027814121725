import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {REDUCERS} from "../../../constants";
import {notification} from "antd";
import {ts} from "../../../translations/helpers";
import {IUpdateTagParams, ApiTag} from "../../../api/endpoints/tag";
import {ACTION_TYPES} from "./actionTypes";

interface IEditFormParams {
    form: IUpdateTagParams;
    id: string;
}

export const getTags = createAsyncThunk(
    ACTION_TYPES.GET_TAGS,
    async ({}: {}, thunkApi) => {
        const state = (thunkApi.getState() as RootState)[REDUCERS.TAG];
        const { page, pageSize, sort } = state;
        return await ApiTag.getAll({ page, pageSize, sort });
    }
);

export const editTag = createAsyncThunk(
    ACTION_TYPES.EDIT_TAG,
    async ({ form, id }: IEditFormParams, thunkApi) => {
        const res = await ApiTag.update(form, id);
        if (res) {
            notification.success({
                message: ts('Зміни успішно збережені'),
            });
            return res;
        }
    }
);

export const deleteTag = createAsyncThunk(
    ACTION_TYPES.DELETE_TAG,
    async (id: string, thunkApi) => {
        const res = await ApiTag.delete(id);
        if (res) {
            notification.success({
                message: ts('Тег успішно видалений'),
            })
            return id;
        }
    }
);