import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import React, {useEffect, useState} from "react";
import {CompanyForm} from "../CompanyForm";
import {CompanyInfoForm} from "../CompanyInfoForm";
import {Col, Divider, Row} from "antd";
import {useParams} from "react-router-dom";
import {ICompany, ICompanyForm, ICompanyInfoForm} from "../../../../interfaces/company";
import {ApiCompany} from "../../../../api/endpoints/company";
import {COMPANY_FORM_FIELDS} from "../../constants";

export const CompanyUpdate = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [company, setCompany] = useState<ICompanyForm>({} as ICompanyForm);
    const [companyInfo, setCompanyInfo] = useState<ICompanyInfoForm>({} as ICompanyInfoForm);

    useEffect(() => {
        if (id) {
            ApiCompany.getOne(id)
                .then((company: ICompany) => {
                    if (company.id) {
                        setCompany({
                            [COMPANY_FORM_FIELDS.NAME]: company.name,
                            [COMPANY_FORM_FIELDS.EMAIL]: company.email,
                            [COMPANY_FORM_FIELDS.DESCRIPTION]: company.description,
                            [COMPANY_FORM_FIELDS.LOGO]: company.logo,
                            [COMPANY_FORM_FIELDS.ACTIVE]: Boolean(Number(company.active)),
                            [COMPANY_FORM_FIELDS.COUNTRY]: String(company.country_id),
                        });
                        setCompanyInfo(company.info)
                    }
                });
        }
    }, [id]);

    return (
        <div className={'company-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Редагування компанії')}/>
            <PageHeader
                title={ts('Редагування компанії')}
            />
            <CompanyForm company={company} id={id}/>
            <Divider children={ts('Юридична інформація')} orientation="left" orientationMargin="0"/>
            <CompanyInfoForm id={id} company={companyInfo}/>
            <Row>
                <Col xs={24} sm={24} md={24} lg={8} >

                </Col>
                <Col xs={24} sm={24} md={24} lg={16} >

                </Col>
            </Row>
        </div>
    );
}