import {ts} from "../../../../translations/helpers";
import {Switch} from "../../../components-base/Switch/Switch";
import {formatDate} from "../../../../helpers/formaters";
import {Button, Space} from "antd";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useMemo} from "react";
import {useAppDispatch} from "../../../../store/store";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useSelector} from "react-redux";
import {IPaginationData, ISort} from "../../../../interfaces/main";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {Table} from "../../../components-base/Table/Table";
import { setPagination, setSort } from "../../../../store/reducers/tag/reducer";
import {
    selectTags,
    selectTagsById,
    selectTagsCount,
    selectTagsPage,
    selectTagsPageSize, selectTagsSort
} from "../../../../store/reducers/tag/selectors";
import {getRoute, TAG_FORM_FIELDS, TAGS_ROUTES} from "../../constants";
import {deleteTag, editTag, getTags} from "../../../../store/reducers/tag/actions";

const getColumns = (
    handleChangeActive: (value: boolean, id: string) => void,
    handlerDelete: (id: string) => void,
) => {
    return [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: ts('Назва'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: ts('Аліас'),
            dataIndex: 'alias',
            key: 'alias',
            sorter: true,
        },
        {
            title: ts('Активність'),
            dataIndex: 'active',
            key: 'active',
            render: (value: any, item: any) => {
                return <Switch checked={Boolean(Number(value))} handlerChange={(value) => handleChangeActive(value, item.id)} name={'active'}/>;
            }
        },
        {
            title: ts('Дата створення'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: any) => formatDate(value),
            sorter: true,
        },
        {
            title: ts('Дата редагування'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value: any) => formatDate(value)
        },
        {
            title: ts('Дії'),
            dataIndex: 'actions',
            key: 'actions',
            render: (value: any, item: any) => {
                return <Space>
                    <Button type={'default'} >
                        <Link to={`${item.id}`}><EditOutlined /></Link>
                    </Button>
                    {/*<Button><SelectOutlined /></Button>*/}
                    <Button type={'primary'} onClick={() => handlerDelete(item.id)} danger><DeleteOutlined /></Button>
                </Space>;
            }
        },


    ];
};

export const TagTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const { breadcrumbs } = useActiveRoute();
    const navigate = useNavigate();
    const data = useSelector(selectTags);
    const dataById = useSelector(selectTagsById);
    const totalData: number = useSelector(selectTagsCount);
    const page = useSelector(selectTagsPage);
    const pageSize = useSelector(selectTagsPageSize);
    const sort = useSelector(selectTagsSort);

    const handlerPagination = (pagination: IPaginationData) => dispatch(setPagination(pagination));
    const handlerSort = (sort: ISort) => dispatch(setSort(sort));
    const handleChangeActive = (value: boolean, id: string) => dispatch(editTag({ form: { [TAG_FORM_FIELDS.ACTIVE]: value }, id }));
    const handlerDelete = async (id: string) => await dispatch(deleteTag(id));

    const tags = useMemo(() => {
        return data.map((id) => dataById[id]);
    }, [data, dataById]);

    const columns = getColumns(handleChangeActive, handlerDelete);

    useEffect(() => {
        dispatch(getTags({}));
    }, [page, pageSize, sort?.column, sort?.type]);

    return (
        <div className={'user-table-container'}>
            <Breadcrumbs items={breadcrumbs}/>
            <PageHeader
                title={ts('Теги')}
                subtitle={ts('Теги для товарів')}
                extra={[
                    <Button key={'add'} type={'primary'} onClick={() => navigate(getRoute(TAGS_ROUTES.CREATE))}>
                        <PlusCircleFilled />
                        {ts('Створити')}
                    </Button>
                ]}
            />
            <Table columns={columns}
                   data={tags}
                   total={totalData}
                   setPagination={handlerPagination}
                   setSort={handlerSort}
                   page={page}
                   pageSize={pageSize}
                   sort={sort}
                // loading={loading}
            />
        </div>
    );
};