import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {ICategory, ICategoryForm} from "../../../../interfaces/category";
import {ApiCategory} from "../../../../api/endpoints/category";
import {CATEGORY_FORM_FIELDS} from "../../constants";
import {CategoryForm} from "../CategoryForm";
import {LangEditor} from "../../../components-base/LangEditor/LangEditor";
import {Divider, notification} from "antd";

export const CategoryUpdate = () => {
    const { breadcrumbs } = useActiveRoute();
    const { id } = useParams();
    const [category, setCategory] = useState<ICategoryForm>({} as ICategoryForm);
    const handlerNameChanges = async (value: any) => id && await ApiCategory.updateLang(value, id);

    useEffect(() => {
        if (id) {
            ApiCategory.getOne(id)
                .then((category: ICategory) => {
                    if (category.id) {
                        setCategory({
                            [CATEGORY_FORM_FIELDS.NAME]: category.name,
                            [CATEGORY_FORM_FIELDS.ALIAS]: category.alias,
                            [CATEGORY_FORM_FIELDS.PARENT]: category.parent,
                            [CATEGORY_FORM_FIELDS.LANG]: category.lang,
                            [CATEGORY_FORM_FIELDS.ACTIVE]: Boolean(category.active),
                        });
                    }
                });
        }
    }, [id]);

    return (
        <div className={'company-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Редагування категорії')}/>
            <PageHeader
                title={ts('Редагування категорії')}
            />
            <Divider children={ts('Назва')} orientation="left" orientationMargin="0"/>
            <LangEditor handlerChange={handlerNameChanges} translates={category.lang}/>
            <Divider children={ts('Основна інформація')} orientation="left" orientationMargin="0"/>
            <CategoryForm category={category} id={id}/>
        </div>
    );
}