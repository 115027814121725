import React, {useEffect} from 'react';
import {IOrderProduct, IOrderStatus} from "../../../../interfaces/order";
import {Table, Tag} from 'antd';
import {ts} from "../../../../translations/helpers";
import {useAppDispatch} from "../../../../store/store";
import {loadStuffs} from "../../../../store/reducers/users/actions";
import {useSelector} from "react-redux";
import {selectUsersById} from "../../../../store/reducers/users/selectors";
import {formatDate} from "../../../../helpers/formaters";

interface IOrdersInfoStatusesProps {
    statuses: IOrderStatus[];
}

export const OrdersInfoStatuses: React.FC<IOrdersInfoStatusesProps> = ({ statuses }) => {
    const dispatch = useAppDispatch();
    const stuffData = useSelector(selectUsersById);

    useEffect(() => {
        if (statuses) {
            dispatch(loadStuffs({ ids: statuses.map((status) => status.stuff_id) }));
        }
    }, []);

    const columns = [
        {
            title: ts('Статус'),
            dataIndex: 'status',
            key: 'status',
            render: (value: string) => <Tag color={'red'}>{value}</Tag>
        },
        {
            title: ts('Ким змінений'),
            dataIndex: 'stuff_id',
            key: 'stuff_id',
            render: (value: string) => `${stuffData[value]?.surname || ''} ${stuffData[value]?.name || ''} ${stuffData[value]?.middle_name || ''}`
        },
        {
            title: ts('Дата зміни'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value: number) => formatDate(value)
        },
        {
            title: ts('Коментар'),
            dataIndex: 'comment',
            key: 'comment',
        },
    ];
    return (
        <Table dataSource={statuses} columns={columns}/>
    );
}
