import React from 'react';
import {Breadcrumbs} from "../../../components-base/Breadcrumbs/Breadcrumbs";
import {ts} from "../../../../translations/helpers";
import {PageHeader} from "../../../components-base/PageHeader/PageHeader";
import {useActiveRoute} from "../../../../hooks/useActiveRoute";
import {DIRECTORIES} from "../../../../interfaces/main";
import {DirectoriesController} from "../../../app-main/components/DirectoriesController";
import {DirectoryValueForm} from "../DirectoryValueForm";

interface IProps {
    directory: DIRECTORIES;
    handlerUpdate: () => void;
}

export const DirectoryCreteValue: React.FC<IProps> = ({ directory, handlerUpdate }) => {
    const { breadcrumbs } = useActiveRoute();
    return (
        <div className={'company-form-container'}>
            <Breadcrumbs items={breadcrumbs} extra={ts('Створення')}/>
            <PageHeader
                title={`${ts('Створення елементу довідника ')} ${DirectoriesController.getDirectoryName(directory)}`}
            />
            <DirectoryValueForm directory={directory} handlerUpdate={handlerUpdate} isCreate/>
        </div>
    );
};