import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoading} from "../../store/reducers/attribute/selectors";
import {Loading} from "../app-main/components/Wrappers/Loading/Loading";
import {Route, Routes} from "react-router-dom";
import {AttributeTable} from "./components/AttributeTable";
import {ATTRIBUTES_ROUTES} from "./constants";
import {AttributeCreate} from "./components/AttributeCreate";
import {AttributeUpdate} from "./components/AttributeUpdate";

export const AttributeApp: React.FC = () => {
    const isLoading = useSelector(selectIsLoading);

    return (
        <div className={'company-container'}>
            <Loading isLoading={isLoading}>
                <Routes>
                    <Route path={ATTRIBUTES_ROUTES.CREATE} element={<AttributeCreate/>}/>
                    <Route path={ATTRIBUTES_ROUTES.EDIT} element={<AttributeUpdate />}/>
                    <Route path={'/'} element={<AttributeTable/>}/>
                </Routes>
            </Loading>
        </div>
    );
};